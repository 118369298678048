import type { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react'
import clsx from 'clsx'
import type { Direction } from '@ui/system'
import s from './SliderButton.module.scss'
import { CwsIcon } from '@components/cws'

export interface SliderButtonProps
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  direction: Direction
  disabled?: boolean
  blockClickWhenDisabled?: boolean
}

const SliderButton: FC<SliderButtonProps> = (props) => {
  const { direction, disabled = false, blockClickWhenDisabled = true, onClick, ...restButtonProps } = props

  const clickHandler = blockClickWhenDisabled ? (disabled ? undefined : onClick) : onClick

  return (
    <button className={clsx(s.button, disabled && s.disabled)} onClick={clickHandler} {...restButtonProps}>
      <CwsIcon icon={`arrow-${direction}`} size="16px" />
    </button>
  )
}

export default SliderButton
