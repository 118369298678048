import { useEffect, useState } from 'react'

const isWindow = (): boolean => typeof window !== 'undefined'

export type TScreen = 360 | 600 | 800 | 1024 | 1288

export const [device360, device600, device800, device1024, device1288]: TScreen[] = [360, 600, 800, 1024, 1288]

const getWindowType = (width: number): TScreen => {
  if (width < device600) return device360
  if (width < device800 && width > device360) return device600
  if (width < device1024 && width > device600) return device800
  if (width < device1288 && width > device800) return device1024
  if (width >= device1288) return device1288
  return device1288
}

export const useResize = () => {
  const [deviceSize, setDeviceSize] = useState<TScreen>(device1288)
  useEffect(() => {
    if (isWindow()) {
      onResize()
    }
    if (isWindow()) {
      window.addEventListener('resize', onResize)
    }
    return () => {
      if (isWindow()) {
        window.removeEventListener('resize', onResize)
      }
    }
  })

  const onResize = () => {
    const current = getWindowType(window.innerWidth)
    if (deviceSize !== current) setDeviceSize(current)
  }

  return { deviceSize }
}
