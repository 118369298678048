import type { FC } from 'react'
import { useState } from 'react'
import { CwsButton, CwsIcon, CwsP } from '@components/cws'
import type { Rating } from '@model/product/ProductNewReview'
import { useRouter } from 'next/router'
import type { Locale } from '@model/locales'
import { KK_LANG_IDS } from '@model/locales'
import type { ReviewAddBody } from '@pages/api/review'
import axios from 'axios'
import { Input } from '@ui/components'
import { useTranslate } from '@hooks/useTranslation'
import type { SubmitHandler } from 'react-hook-form'
import { Controller, useForm } from 'react-hook-form'
import { InputTextArea } from '@ui/components/InputTextArea'
import { useAlert } from '@context/alert'
import { isGloballyPricelist } from '@utils/pricelist-utils/app-properties'
import { useMediaLess } from '@ui/hooks/useMediaLess'
import { EXTRA_SMALL_BP } from '@app/constants'

export const sendAddReview = (review: ReviewAddBody) => axios.post(`/api/review`, review)

interface ProductAddReviewProps {
  sku: string
  productId: number
  handleHideForm(): void
}

interface Fields {
  name: string
  review: string
  orderNumber?: string
  rating: number
}

const emojiIconsMap: Record<number, 'emoji-sad' | 'emoji-smile' | 'emoji-happy'> = {
  1: 'emoji-sad',
  2: 'emoji-smile',
  3: 'emoji-smile',
  4: 'emoji-happy',
  5: 'emoji-happy',
} as const

export const ProductAddReview: FC<ProductAddReviewProps> = ({ sku, productId, handleHideForm }) => {
  const { locale } = useRouter()
  const languageId = KK_LANG_IDS[locale as Locale]
  // const [rating, setRating] = useState<Rating>(5)
  const [isBlocked, setBlocked] = useState<boolean>(false)
  const userFullName = '' // this should be taken from the current user
  const isMobile = useMediaLess(EXTRA_SMALL_BP)

  const { translate } = useTranslate()
  const { showAlert } = useAlert()

  const { control, reset, handleSubmit } = useForm<Fields>({
    defaultValues: { name: userFullName, rating: 5 },
  })
  const [hoverStar, setHoverStar] = useState<number>(0)
  const [currentRating, setCurrentRating] = useState<number>(0)

  const onSubmit: SubmitHandler<Fields> = async (values) => {
    //   event.preventDefault()
    setBlocked(true)
    try {
      const { data: reviewId } = await sendAddReview({
        languageId,
        locale: locale as Locale,
        sku,
        productId,
        reviewText: values.review,
        rating: currentRating as Rating,
        author: values.name,
        ...(values.orderNumber && { orderNumber: values.orderNumber }),
      })
      setBlocked(false)
      handleHideForm()
      showAlert({ error: false, text: translate('reviews.review.added') })
    } catch (e) {
      if (axios.isAxiosError(e) && e.response) {
        const { data } = e.response
        showAlert({ error: true, text: data as string })
      }
      setBlocked(false)
    }
  }

  const ratingStarsNumbers = [1, 2, 3, 4, 5]

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-y-6 w-full pb-4">
      <fieldset className="flex flex-row gap-y-2 gap-x-4 items-center">
        <div className="flex flex-row gap-0" onMouseOut={() => setHoverStar(0)}>
          {ratingStarsNumbers.map((n) => (
            <div key={n} onMouseOver={() => setHoverStar(n)} className="px-0.5">
              <CwsIcon
                icon={hoverStar >= n || currentRating >= n ? 'star-filled' : 'star'}
                size="24px"
                className="cursor-pointer"
                onClick={() => setCurrentRating(n)}
              />
            </div>
          ))}
        </div>
        <CwsIcon icon={emojiIconsMap[hoverStar]} size="32px" />
        {Boolean(hoverStar) && !isMobile && (
          <CwsP size="lead" weight="500" hideSpacing>
            {translate(`w2.emoji.rating.${hoverStar}`)}
          </CwsP>
        )}
      </fieldset>
      <fieldset className="w-full flex flex-col gap-y-4 justify-between">
        <Controller
          control={control}
          name="name"
          rules={{ required: true }}
          render={({ field }) => {
            return (
              <Input
                label={translate('reviews.adding.label.name') ?? ''}
                wrapperClassName="laptop:max-w-[284px]"
                // ref={nameRef}
                // name="customer name"
                type="text"
                placeholder={translate('reviews.adding.label.name') ?? ''}
                spellCheck="false"
                autoComplete="name"
                // value={userFullName}
                required
                {...field}
              />
            )
          }}
        />

        {!isGloballyPricelist() && (
          <Controller
            control={control}
            name="orderNumber"
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <Input
                  label={translate('reviews.adding.label.order.number') ?? ''}
                  wrapperClassName="laptop:max-w-[284px]"
                  type="text"
                  placeholder={translate('reviews.adding.label.order.number') ?? ''}
                  spellCheck="false"
                  // value={userFullName}
                  required={false}
                  {...field}
                />
              )
            }}
          />
        )}

        <Controller
          control={control}
          name="review"
          rules={{ required: true }}
          render={({ field }) => {
            return (
              <InputTextArea
                label={translate('reviews.adding.label.comment') ?? ''}
                className="resize-none h-[240px] laptop:h-[148px]"
                {...field}
              />
            )
          }}
        />
      </fieldset>
      <fieldset className="flex flex-col gap-y-4 justify-between laptop:max-w-[284px]">
        <CwsButton type="medium">{translate('reviews.adding.submit.review')}</CwsButton>
        <CwsButton
          variant="secondary"
          type="reset"
          onClick={() => {
            reset()
            handleHideForm()
          }}
        >
          {translate('select.shop.confirm.cancel')}
        </CwsButton>
      </fieldset>
    </form>
  )
}
