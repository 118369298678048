import { useG11n } from 'next-g11n'
import { useRouter } from 'next/router'
import type { Locale } from '@model/locales'
import { KK_LOCALE_MAP } from '@model/locales'
import useSWRImmutable from 'swr/immutable'
import type { MessageKeyValue } from '@api/messages'
import type { KKDictionary } from '../additional'

export const useTranslate = () => {
  const { locale } = useRouter()
  const urlSearchParams = new URLSearchParams({})

  urlSearchParams.set('locale', KK_LOCALE_MAP[locale as Locale])
  const { data } = useSWRImmutable<MessageKeyValue>(`/api/translations?${urlSearchParams.toString()}`)

  let dictionary: any

  if (typeof window !== 'undefined' && 'envs' in window) {
    if ('dictionary' in window.envs && typeof window.envs.dictionary !== 'undefined') {
      dictionary = window.envs.dictionary
    }
    if (data) {
      dictionary = {
        [locale as Locale]: data,
      }
    }
  }

  const { translate: rootTranslate } = useG11n<KKDictionary>(dictionary, true)

  const translate = (term: string, params?: any) => {
    try {
      // @ts-ignore
      return rootTranslate(term, params) as string
    } catch (e) {
      return term
    }
  }

  return {
    translate,
  }
}
