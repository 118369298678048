import { CwsButton, CwsP } from '@components/cws'
import type { FC } from 'react'
import moduleStyles from './CoLoginBannerLarge.module.scss'
import { clickSSOButton } from '@utils/with-dom'
import { useTranslate } from '@hooks/useTranslation'

export const CoLoginBannerLarge: FC = () => {
  const { translate } = useTranslate()
  return (
    <section className="bg-seafoam flex flex-col px-4 py-6 space-y-lg items-center">
      <CwsP className="w-1/2" textAlign="center">
        {translate('w2.co.banner.message.text')}
      </CwsP>
      <CwsButton
        className={moduleStyles.coButton}
        onClick={clickSSOButton}
        type="button"
        variant="primary-dark"
        width="auto"
        targetBlank
      >
        {translate('w2.singIn.join.club.one')}
      </CwsButton>
    </section>
  )
}

export default CoLoginBannerLarge
