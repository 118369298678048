import type { FC } from 'react'
import { useCartState } from '@components/cart/State/state'
import { useCallback, useEffect, useRef } from 'react'

export const HiddenForm: FC = () => {
  const {
    dispatch,
    state: { bankPaymentResponse },
  } = useCartState()
  const formRef = useRef<HTMLFormElement>(null)

  const clearBankResponse = useCallback(() => dispatch({ type: 'setBankPaymentResponse', payload: null }), [dispatch])

  useEffect(() => {
    if (bankPaymentResponse && formRef?.current) {
      formRef?.current?.submit()
      clearBankResponse()
    }

    return () => {}
  }, [clearBankResponse, bankPaymentResponse, formRef])

  if (!bankPaymentResponse) return null

  const { parameters, url } = bankPaymentResponse

  return (
    <form
      noValidate
      action={url}
      method="POST"
      target="_self"
      ref={formRef}
      className=""
      id="the-hidden-form-for-online-banks-params"
    >
      {Object.keys(parameters).map((key) => (
        <input type="text" key={key} name={key} value={parameters[key]} />
      ))}
      {/*<button type="submit">send</button>*/}
    </form>
  )
}

export default HiddenForm
