import type { IsoCode } from '@model/order/Order'

export const KK_LOCALE_MAP = {
  et: 'et_EE',
  en: 'en_GB',
  ru: 'ru_RU',
  sv: 'sv_SE',
  fi: 'fi_FI',
  lt: 'lt_LT',
  lv: 'lv_LV',
} as const

export const deliveryCountries: Record<Locale, CountryName> = {
  et: 'Estonia',
  en: 'Estonia',
  ru: 'Estonia',
  sv: 'Sweden',
  fi: 'Finland',
  lt: 'Lithuania',
  lv: 'Latvia',
}

export const CC_LOCALE = {
  et: 'et-ee',
  en: 'en-xz',
  ru: 'ru-ee',
  sv: 'sv-se',
  fi: 'fi-fi',
  lt: 'lt-lv',
  lv: 'lv-lv',
} as const

export const KK_LANG_IDS = {
  et: 5,
  en: 1,
  ru: 9,
  sv: 7,
  fi: 6,
  lt: 11,
  lv: 8,
} as const
export const KK_COUNTRIES = {
  5: 'Estonia',
  1: 'Estonia',
  9: 'Estonia',
  7: 'Sweden',
  6: 'Finland',
  11: 'Lithuania',
  8: 'Latvia',
}
export const COUNTRY_CODES = {
  en: 'XZ',
  no: 'NO',
  ru: 'RU',
  et: 'EE',
  lv: 'LV',
  fi: 'FI',
  sv: 'SE',
  lt: 'LT',
  zh: 'ZH',
} as const

export const DELIVER_TO_COUNTRIES = ['Estonia', 'Finland', 'Sweden', 'Latvia', 'Lithuania'] as const
export type CountryName = typeof DELIVER_TO_COUNTRIES[number]

export const COUNTRY_ISO2: Record<CountryName, IsoCode> = {
  Estonia: 'EE',
  Finland: 'FI',
  Sweden: 'SE',
  Latvia: 'LV',
  Lithuania: 'LT',
} as const

export type ValueOf<T> = T[keyof T]

export const locales = Object.keys(KK_LOCALE_MAP)
export type Locale = keyof typeof KK_LOCALE_MAP
export type MessageLocale = ValueOf<typeof KK_LOCALE_MAP>
export type LanguageId = ValueOf<typeof KK_LANG_IDS>
export type CountryCode = ValueOf<typeof COUNTRY_CODES>
export type CCLocale = ValueOf<typeof CC_LOCALE>
