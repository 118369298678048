import type { ChangeEvent, FC, FormEvent } from 'react'
import { useState } from 'react'
import { CwsButton, CwsInput, CwsAlert, CwsForm, CwsP, CwsGridRow, CwsGridCol } from '@components/cws'
import { useTranslate } from '@hooks/useTranslation'
import type { Locale } from '@model/locales'
import { KK_LANG_IDS } from '@model/locales'
import { useRouter } from 'next/router'
import axios from 'axios'
import type { NotificationResult } from '@api/checkEmail'
import { logError } from '@utils/errors-client'
interface OutOfStockProps {
  productId: number
}
export const OutOfStock: FC<OutOfStockProps> = ({ productId }) => {
  const { translate } = useTranslate()
  const [isLoading, setIsLoading] = useState(false)
  const [emailValue, setEmailValue] = useState('')
  const [responseType, setResponseType] = useState<'success' | 'error' | null>(null)
  const { locale } = useRouter()
  const languageId = KK_LANG_IDS[locale as Locale]

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setIsLoading(true)

    try {
      const { data } = await axios<NotificationResult>({
        method: 'POST',
        url: '/api/product/subscribe',
        data: { productId, email: emailValue, languageId },
      })
      if (data.status === 'ok') {
        setResponseType('success')
      } else {
        setResponseType('error')
      }
    } catch (e) {
      setResponseType('error')
      logError(`Error in subscribe`, e)
    } finally {
      setIsLoading(false)
    }
  }
  const handleChangeEmailValue = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailValue(e.target.value)
  }

  const copyright = translate('w2.copyright.message', {
    privacyPolicy: `<a class="cws-a cws-a--theme-white cws-a--size-tiny cws-a--inline cws-a--color-dark-grey" target="_blank" href="https://policies.google.com/privacy?hl=${locale}">${translate(
      'footer.privacy.policy'
    )}</a>`,
    termsOfService: `<a class="cws-a cws-a--theme-white cws-a--size-tiny cws-a--inline cws-a--color-dark-grey" target="_blank" href="https://policies.google.com/terms?hl=${locale}">${translate(
      'w2.terms.of.service'
    )}</a>`,
  })
  return (
    <div className="flex flex-col  justify-center">
      {responseType === 'success' ? (
        <>
          {responseType === 'success' && (
            <CwsAlert variant="inline" className="cws-justify-content-center" type="success">
              <CwsP size="small">{translate('product.detail.email.success')}</CwsP>
            </CwsAlert>
          )}
        </>
      ) : (
        <div className="flex flex-col gap-2">
          {responseType === 'error' && (
            <CwsAlert variant="inline" type="error">
              <CwsP size="small">{translate('product.detail.email.error')}</CwsP>
            </CwsAlert>
          )}
          <CwsForm onSubmit={handleSubmit} className="w-full">
            <CwsInput
              label={translate('product.detail.email.label')}
              placeholder={translate('product.detail.email.placeholder')}
              onChange={handleChangeEmailValue}
              value={emailValue}
            />
            <CwsButton width="fluid" className="cws-mt-xs" disabled={isLoading} type="submit">
              {translate('product.detail.email.send')}
            </CwsButton>
            <CwsGridRow>
              <CwsGridCol>
                <CwsP
                  color="dark-grey"
                  className="cws-py-xs"
                  dangerouslySetInnerHTML={{ __html: copyright }}
                  size="tiny"
                />
              </CwsGridCol>
            </CwsGridRow>
          </CwsForm>
        </div>
      )}
    </div>
  )
}
