import type { FC } from 'react'
import { useTranslate } from '@hooks/useTranslation'
import { useInitialData } from '@hooks/useInitialData'
import { CwsP, CwsH3, CwsGrid, CwsGridRow, CwsGridCol, CwsSection } from '@components/cws'

const ProductViewDeliveryInfo: FC = () => {
  const { translate } = useTranslate()
  const { deliveryInfo } = useInitialData()
  return (
    <CwsSection className="cws-pt-l">
      <CwsGrid width="fluid">
        <CwsGridRow>
          <CwsGridCol>
            <CwsH3 className="leading-7.5">{translate('product.detail.description.delivery')}</CwsH3>
            <CwsP dangerouslySetInnerHTML={{ __html: deliveryInfo }}></CwsP>
          </CwsGridCol>
        </CwsGridRow>
      </CwsGrid>
    </CwsSection>
  )
}

export default ProductViewDeliveryInfo
