import axios from 'axios'

export const logError = (customErrorMessage: string, error: unknown, printStack = false): void => {
  if (axios.isAxiosError(error)) {
    const errorMessage = `Axios: ${error.message}`

    printStack && console.error(error.stack)
    console.error(`${customErrorMessage} *** ${errorMessage} ${error.response?.status}`, {
      data: error.response?.data,
      url: error.request.url,
      code: error.response?.status,
    })
  } else if (error instanceof Error) {
    const { message, stack } = error
    console.error(`${customErrorMessage} *** ${message}`)
    printStack && console.error(stack)
  }
}
