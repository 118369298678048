import * as R from 'ramda'

export const addressParser = R.reduce(
  (acc, val: google.maps.GeocoderAddressComponent) => ({
    ...acc,
    ...(val.types.includes('street_number') && { streetNumber: val['long_name'] }),
    ...(val.types.includes('route') && { street: val['long_name'] }),
    ...(val.types.includes('locality') && { city: val['long_name'] }),
    ...(val.types.includes('postal_town') && { city: val['long_name'] }),
    ...(val.types.includes('country') && { country: val['long_name'], countryCode: val['short_name'] }),
    ...(val.types.includes('postal_code') && { zip: val['long_name'] }),
    ...(val.types.includes('administrative_area_level_1') && { state: val['long_name'] }),
    ...(val.types.includes('sublocality_level_1') && { suburb: val['long_name'] }),
  }),
  {} as Record<string, string>
)
