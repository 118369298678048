import * as fbq from '@lib/fpixel'
import { pageview } from '@lib/gtm'
import type { Product } from '@model/product'
import type { Locale } from '@model/locales'
import debounce from 'lodash.debounce'
import type { Currency } from '@model/product/PriceInfo'
import { compose, join, map, prop } from 'ramda'
import { useInitialData } from '@hooks/useInitialData'
import { getBranchByCategoryId } from '@utils/categories'

const categoryChain = compose(join(' > '), map(prop('name')))

const getBetterProductNameForStats = (name: string) => {
  try {
    return decodeURIComponent(name)
  } catch (e) {
    return name
  }
}

export const useStats = () => {
  const { categories } = useInitialData()

  const addToCart = (product: Product, language: Locale, currency: Currency): void => {
    fbq.addToCart({
      content_name: `${getBetterProductNameForStats(product.name)} ${product.model}`,
      content_ids: [product.sku],
      content_type: 'product',
      value: product.priceInfo?.topPrice || product.priceExTax,
      currency,
      language,
      contents: ['store2', product.sku, 1, product.priceInfo?.topPrice || product.priceExTax],
      eventIdPostfix: product.sku,
    })
  }
  const addToWishlist = (product: Product, language: Locale, currency: Currency): void => {
    try {
      const categoryTree = getBranchByCategoryId(product.categoryId, categories)
      fbq.addToWishList({
        content_name: `${getBetterProductNameForStats(product.name)} ${product.model}`,
        content_category: categoryChain(categoryTree),
        content_ids: [product.sku],
        value: product.priceInfo?.topPrice || product.priceExTax,
        currency,
        language,
        contents: ['store2', product.sku, 1, product.priceInfo?.topPrice || product.priceExTax],
        eventIdPostfix: product.sku,
      })
    } catch (e) {
      console.error(e)
    }
  }
  const removeFromCart = (product: Product, language: Locale, currency: Currency): void => {
    // eslint-disable-next-line no-console
    // console.log(`Product ${product.sku} is now removed from the cart`)
  }
  const userAuthenticated = (customerId: number): void => {
    // eslint-disable-next-line no-console
    // console.log(`user ${customerId} is authenticated`)
  }
  const userReleased = (): void => {}
  const viewProductDetail = (product: Product, language: Locale, currency: Currency): void => {
    try {
      fbq.view({
        value: product.priceInfo.topPrice,
        currency,
        content_name: `${getBetterProductNameForStats(product.name)} ${product.model}`,
        content_type: 'product',
        content_ids: [product.sku],
        language,
        eventIdPostfix: product.sku,
      })
    } catch (e) {}
  }
  const registerPageView = (path: string): void => {
    fbq.pageview()
    pageview(path)
  }

  const search = (term: string): void => {
    fbq.search({
      term,
      eventIdPostfix: term,
    })
  }

  return {
    addToCart,
    addToWishlist,
    removeFromCart,
    userAuthenticated,
    userReleased,
    registerPageView,
    viewProductDetail: debounce(viewProductDetail, 400),
    search,
  } as const
}
