import { CwsA, CwsP, CwsSpan } from '@components/cws'
import Link from 'next/link'
import type { FC } from 'react'
import { Clubone as CluboneSvg } from './assets'
import { clickSSOButton } from '@utils/with-dom'
import clsx from 'clsx'
import { useTranslate } from '@hooks/useTranslation'

interface CoLoginBannerProps {
  onPaymentView?: boolean
}

export const CoLoginBanner: FC<CoLoginBannerProps> = ({ onPaymentView }) => {
  const { translate } = useTranslate()

  const handleSignInClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    window.scrollTo(0, 0)
    window.SSO.open()
  }
  const discountText = translate('w2.discount.text', {
    joinCubOne: `<a class="cws-a cws-a--theme-white cws-a--size-tiny cws-a--inline cws-a--color-dark-grey" rel="noopener noreferrer"  href="https://ee.tallink.com/club-one">${translate(
      'w2.join.club.one'
    )}</a>`,
  })

  return (
    <section className={clsx(!onPaymentView && 'bg-group-grey', 'flex flex-row px-4 py-4 space-x-lg')}>
      <div>
        <CluboneSvg width="52px" height="52px" />
      </div>
      <CwsP size="small">
        <CwsA
          class="cws-a cws-a--theme-white cws-a--size-tiny cws-a--inline cws-a--color-dark-grey"
          rel="noopener noreferrer"
          href="/"
          onClick={handleSignInClick}
        >
          {translate('w2.sign.in')}
        </CwsA>{' '}
        <CwsSpan dangerouslySetInnerHTML={{ __html: discountText }} />
      </CwsP>
    </section>
  )
}

export default CoLoginBanner
