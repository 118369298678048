import type { AxiosRequestConfig } from 'axios'
import { KK_POST_ENDPOINT } from '@api/apiConfig'
import type { NextApiRequest } from 'next'
import type { IncomingMessage } from 'http'

export const GUEST_CUSTOMER_HEADER = 'guest-customer-id'

export const makeAxiosConfig = (bodyData: any): AxiosRequestConfig => {
  const config: AxiosRequestConfig = {
    method: 'post',
    timeout: 30000,
    url: KK_POST_ENDPOINT,
    headers: {
      'Content-Type': 'application/json; charset=utf-8;',
    },
    data: bodyData,
  }

  return config
}

export const getGuestCustomerId = (req: NextApiRequest | IncomingMessage): string | null => {
  return req.headers[GUEST_CUSTOMER_HEADER] as string
}
