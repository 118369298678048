export const findPopularKeyForLabel = (currentKey: string) => {
  const translateKeys: { [keys: string]: string } = {
    '2=3': 'promotion.name.2=3',
    '3=4': 'promotion.name.3=4',
    GWP: 'promotion.name.GWP',
    B2SP: 'promotion.name.B2SP',
    B3SP: 'promotion.name.B3SP',
    _40_: 'promotion.name.40',
    _30_: 'promotion.name.30',
    _20_: 'promotion.name.20',
    _10_: 'promotion.name.10',
  }

  const key = Object.keys(translateKeys).find((key) => {
    const regex = new RegExp(`${key}`)
    return regex.test(currentKey)
  })
  if (key) return translateKeys[key]
  return currentKey
}
