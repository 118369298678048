import getConfig from 'next/config'

const SITE_KEY = getConfig().publicRuntimeConfig.reCaptchaSiteKey

export const useGoogleRecaptcha = () => {
  const executeGoogleReCaptcha = async (): Promise<string> => {
    return new Promise((resolve) => {
      window.grecaptcha.ready(function () {
        window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(resolve)
      })
    })
  }

  return { executeGoogleReCaptcha }
}
