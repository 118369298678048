import { DetailedHTMLProps, FC, HTMLAttributes } from 'react'
import clsx from 'clsx'
import styles from './Button.module.scss'

export type ButtonVariant = 'outline' | 'fill' | 'text'
export type ButtonColor = 'primary' | 'secondary'
export type ButtonSize = 'sm' | 'md' | 'custom'

export interface ButtonProps
  extends Omit<
    DetailedHTMLProps<HTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
    'color' | 'size' | 'className'
  > {
  /**
   * Button's varaint
   * @defaultValue `ButtonVariant.outline`
   */
  variant?: ButtonVariant

  /**
   * Button's color variant
   * @defaultValue `ButtonColor
   */
  color?: ButtonColor

  /**
   * Button's size
   * @defaultValue `ButtonSize.md`
   */
  size?: ButtonSize

  /**
   * Indicates if uppercase text transform should be disabled
   * @defaultValue false
   */
  disableUppercaseTransform?: boolean

  /**
   * Custom css class names
   */
  className?: string
}

/**
 * UI Button
 */
const Button: FC<ButtonProps> = (props) => {
  const {
    variant = 'outline',
    color = 'primary',
    size = 'md',
    disableUppercaseTransform = false,
    className,
    children,
    ...restProps
  } = props
  return (
    <button
      {...restProps}
      className={clsx(
        styles['button-base'],
        styles[`${variant}-${color}`],
        size !== 'custom' && styles[`size-${size}`],
        disableUppercaseTransform && styles['no-uppercase'],
        className
      )}
    >
      {children}
    </button>
  )
}

export default Button
