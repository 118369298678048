import * as R from 'ramda'
import { Promotion } from '@model/misc/Promotion'
import { PromotionResult } from '@model/product/Product'

export const getPromotionNameById =
  (promotions: Promotion[]) =>
  (id: number): string => {
    const promo = promotions.find(R.propEq('id', id))
    return promo ? promo.name : ''
  }

export const getPromotionsTag =
  (allPromotions: Promotion[]) =>
  (promotionsResults: PromotionResult[]): PromotionResult => {
    const promoTransform = R.map<PromotionResult, PromotionResult>((tag) => ({
      ...tag,
      promotionName: getPromotionNameById(allPromotions)(tag.promotionId),
    }))

    // @ts-ignore
    return R.compose(
      R.defaultTo({ promotionName: '' }),
      R.last,
      promoTransform,
      R.sortBy(R.compose(parseInt, R.prop<string, string>('promotionId'))),
      R.defaultTo([])
    )(promotionsResults)
  }
