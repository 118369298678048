import { compose, concat, equals, flatten, identity, isNil, lensProp, map, reduce, set } from 'ramda'
import type { CategoryTip } from '@model/category/CategoryTip'

type CategoriesMap = {
  [key: string]: CategoryTip
}

const toIdMapObject = reduce(
  (acc: CategoriesMap, category: CategoryTip) => set(lensProp(category.id), category, acc),
  Object.create(null)
)

const innerReducer = (acc: CategoryTip[], category: CategoryTip): CategoryTip[] => {
  // @ts-ignore
  return concat(acc, [category, reduce(innerReducer, [], category.children || [])])
}

// @ts-ignore
const flattenCategories: CategoryTip[] = compose(flatten, reduce(innerReducer, [] as CategoryTip[]))

// @ts-ignore
export const flatCategoriesBuilder = compose(toIdMapObject, map(identity), flattenCategories)

const parentFinder = function (parentId: number, idMap: CategoriesMap): CategoryTip[] {
  if (equals(0, parentId)) return []

  const current = idMap[parentId]
  return concat(parentFinder(current.parentId, idMap), [current])
}

export function getBranchByCategoryId(categoryId: number, categories: CategoryTip[]) {
  const flatCategories: CategoriesMap = flatCategoriesBuilder(categories)
  const tip = flatCategories[categoryId]
  if (isNil(tip)) return []

  return concat(parentFinder(tip.parentId, flatCategories), [tip])
}
