//@ts-nocheck

import {
  always,
  anyPass,
  compose,
  defaultTo,
  filter,
  find,
  head,
  ifElse,
  isEmpty,
  isNil,
  map,
  not,
  pick,
  pipe,
  prop,
  propEq,
  propOr,
  propSatisfies,
  sort,
  uniqBy,
} from 'ramda'
import { Product } from '@model/product'
import { Promotion } from '@model/misc/Promotion'
import { TagGroup } from '@model/misc/TagGroup'

export function isClubOnePrice(product): boolean {
  return pipe(
    prop('customAttrArray'),
    defaultTo([]),
    filter(propEq('templateName', 'Campaign Label')),
    head,
    defaultTo({}),
    propEq('value', 'facet.label.club.one')
  )(product)
}

export const getCoRateFromPromotions = (promotions: Promotion[]): number =>
  pipe(
    defaultTo([]),
    find(propEq('name', 'UsePoints')),
    defaultTo({ custom5: '900' }),
    prop('custom5'),
    parseFloat
  )(promotions)

export function minimumValue(array): number {
  return pipe(
    filter(function (a) {
      return a
    }),
    sort(function (a, b) {
      return a - b
    }),
    head,
    defaultTo(0)
  )(array)
}

export function getCampaignLabelForProduct(product: Product) {
  return pipe(filter(propEq('templateName', 'Campaign Label')), map(prop('value')))(product.customAttrArray || [])
}

type FreeProductInfo = { value: number; description: string; promotionName: string; promotionId: number }
export const getFreeProductInfo: (product: Product) => FreeProductInfo = compose(
  pick(['description', 'value', 'promotionName', 'promotionId']),
  defaultTo({}),
  head,
  filter(propEq('orderTotalCode', 'ot_free_product')),
  propOr([], 'promotionResults')
)

export const emptyOrNil = anyPass([isNil, isEmpty])

const withFacetConstraints = propSatisfies(compose(not, emptyOrNil), 'facetConstraints')
const withFacetConstraint = propSatisfies(compose(not, emptyOrNil), 'facetConstraint')
const hasConstraints = find(anyPass([withFacetConstraints, withFacetConstraint]))
const filterConstraints = filter(anyPass([withFacetConstraints, withFacetConstraint]))
const constraintsOnly = filter(anyPass([withFacetConstraints, withFacetConstraint]))

const uniqGroups = uniqBy(({ facetConstraints, facetNumber }) => `${facetNumber}:${JSON.stringify(facetConstraints)}`)
export const getNumbersWithConstraints = compose(map(prop('facetNumber')), filterConstraints)
export const getTagGroupsForSearch = (allGroups: TagGroup[]) =>
  ifElse(hasConstraints, compose(uniqGroups, constraintsOnly), always(allGroups))
