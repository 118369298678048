import { FC } from 'react'
import { CwsIcon, CwsSpan, CwsGridRow, CwsGrid, CwsGridCol } from '@components/cws'
import clsx from 'clsx'

interface FormAlertProps {
  type: 'error' | 'info'
  value: string
  className?: string
}

const FormAlert: FC<FormAlertProps> = ({ type, value, className }) => {
  const icon = type === 'error' ? 'notice-error' : 'notice-important'
  const color = type === 'error' ? 'error' : 'notice'
  return (
    <CwsGrid width="fluid" className={clsx('cws-pt-xs', className)}>
      <CwsGridRow noGutter verticalAlign="center">
        <CwsGridCol col="auto">
          <CwsIcon color={color} icon={icon} size="20px" />
        </CwsGridCol>
        <CwsGridCol col="auto" className="cws-pl-4">
          <CwsSpan>{value}</CwsSpan>
        </CwsGridCol>
      </CwsGridRow>
    </CwsGrid>
  )
}

export default FormAlert
