import type { PaymentMethod } from '@api/checkout/models'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { RadioButton } from '@ui/components'
import { Controller, useForm } from 'react-hook-form'
import { useCartState } from '@components/cart/State/state'
import { useTranslate } from '@hooks/useTranslation'

interface OnlineBanksListProps {
  banks: PaymentMethod[]
}
export const OnlineBanksList: FC<OnlineBanksListProps> = ({ banks = [] }) => {
  const { control } = useForm({})
  const { dispatch } = useCartState()
  const [selectedBank, setSelectedBank] = useState<PaymentMethod | null>()
  const { translate } = useTranslate()

  useEffect(() => {
    if (banks.length > 0) {
      setSelectedBank(banks[0])
      dispatch({
        type: 'setPaymentTypeDetails',
        payload: banks[0].id,
      })
      dispatch({ type: 'setPaymentMethod', payload: 'banks' })
    }
  }, [banks, setSelectedBank, dispatch])

  return (
    <div className="flex flex-col gap-y-6 lg:gap-y-4 w-full">
      <Controller
        control={control}
        name="online-banks-list"
        render={({ field: { value, onChange, ...field } }) => {
          return (
            <>
              {banks.map((bank) => {
                return (
                  <RadioButton
                    key={bank.id}
                    label={bank.name}
                    checked={selectedBank?.name === bank.name}
                    value={value}
                    onClick={() => {
                      setSelectedBank(bank)
                      dispatch({ type: 'setPaymentMethod', payload: 'banks' })
                      dispatch({
                        type: 'setPaymentTypeDetails',
                        payload: bank.id,
                      })
                    }}
                    labelClassName="w-full"
                    {...field}
                  >
                    {translate(`bank.${bank.name}.lbl`)}
                  </RadioButton>
                )
              })}
            </>
          )
        }}
      />
    </div>
  )
}
