import type { FC } from 'react'
import { CwsA, CwsAlert, CwsGrid, CwsGridCol, CwsGridRow, CwsP, CwsSection } from '@components/cws'
import { useTranslate } from '@hooks/useTranslation'

interface CampaignPeriodProps {
  info: string
  variant: 'success' | 'info'
}

export const CampaignPeriod: FC<CampaignPeriodProps> = ({ info, variant }) => {
  const { translate } = useTranslate()
  return (
    <CwsSection className="max-w-full cws-mb-xs cws-pt-m lg:mx-[32px]">
      <CwsAlert id="campaign-period-bar" border type={variant}>
        <CwsGrid width="fluid">
          <CwsGridRow noGutter verticalAlign="center">
            <CwsGridCol className="cws-br cws-pr-12 cws-b-style-dashed cws-b-color-midnight cws-b-opacity-25">
              <CwsGridRow noGutter verticalAlign="center">
                <CwsGridCol className="cws-pb-0-sm cws-pr-24-sm">
                  <CwsP dangerouslySetInnerHTML={{ __html: info }} hideSpacing size="small" />
                </CwsGridCol>
              </CwsGridRow>
            </CwsGridCol>
            <CwsGridCol className="cws-ml-12-sm cws-pl-12" col="auto">
              <CwsA data-alert-id="campaign-period-bar" hideArrow inline role="button" size="small">
                {translate('w2.toast.message.dismiss')}
              </CwsA>
            </CwsGridCol>
          </CwsGridRow>
        </CwsGrid>
      </CwsAlert>
    </CwsSection>
  )
}
