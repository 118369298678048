import type { FC } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { CwsA, CwsGridCol, CwsGridRow, CwsIcon, CwsP, CwsSelect, CwsSelectOption, CwsSpan } from '@components/cws'
import { useTranslate } from '@hooks/useTranslation'
import useSWRImmutable from 'swr/immutable'
import type { AdyenPaymentMethod } from '@api/checkout/adyen'
import axios from 'axios'
import { useCartState } from '@components/cart/State/state'
import type { PaymentMethodsProps } from '@components/cart/payment/PaymentMethods/PaymentMethods'
import { useAlert } from '@context/alert'

const emptyAdyenSelection: AdyenPaymentMethod = {
  id: '0',
  lastFour: '',
  name: 'w2.cart.adyen.empty.item',
  paymentProvider: 'ADYEN',
}

export const AdyenPayment: FC<PaymentMethodsProps> = ({ ssoToken }) => {
  const { translate } = useTranslate()
  const { dispatch } = useCartState()
  const { showAlert } = useAlert()

  const { data: storedPaymentMethods = [], mutate } = useSWRImmutable<AdyenPaymentMethod[]>(
    ssoToken && `/api/payment/stored-methods?ssoToken=${ssoToken}`
  )

  const [storedPayment, setStoredPayment] = useState<AdyenPaymentMethod | null>(null)

  const deleteStoredPayment = useCallback(async () => {
    if (storedPayment && ssoToken) {
      try {
        await axios.delete(`/api/payment/stored-methods?ssoToken=${ssoToken}`, {
          data: { storedMethodIdToDelete: storedPayment.id },
        })
        await mutate(storedPaymentMethods.filter(({ id }) => id !== storedPayment.id))
      } catch (e) {
        console.error(e)
        showAlert({ error: true, text: translate('w2.adyen.remove.method.error') })
      }
    }
  }, [translate, storedPayment, ssoToken, storedPaymentMethods, mutate, showAlert])

  useEffect(() => {
    // setting to the empty options every time storedPaymentMethods is reloaded
    if (storedPaymentMethods.length) {
      setStoredPayment(storedPaymentMethods[0])
    } else {
      setStoredPayment(emptyAdyenSelection)
    }
  }, [storedPaymentMethods, setStoredPayment])

  useEffect(() => {
    if (storedPayment && storedPayment.id.length > 3) dispatch({ type: 'setStoredAdyenMethod', payload: storedPayment })
    else dispatch({ type: 'setStoredAdyenMethod', payload: null })
  }, [dispatch, storedPayment])

  useEffect(() => {
    dispatch({ type: 'setPaymentMethod', payload: 'dibs' })
    dispatch({
      type: 'setPaymentTypeDetails',
      payload: '',
    })

    return () => {
      dispatch({ type: 'setPaymentMethod', payload: 'banks' })
    }
  }, [dispatch])

  if (!ssoToken) return <CwsP>{translate('cart.payment.dibs.help')}</CwsP>

  return (
    <CwsGridRow noGutter className="w-full">
      <CwsGridCol col="12" sm="12" md="12" lg="12">
        <CwsGridRow verticalAlign="bottom">
          <CwsGridCol col="12">
            <CwsP>{translate('cart.payment.dibs.help')}</CwsP>
          </CwsGridCol>
          <CwsGridCol col="6" xs="12" md="6" lg="6">
            {Boolean(storedPaymentMethods.length) && (
              <CwsSelect
                value={storedPayment}
                onChange={setStoredPayment}
                id="payment-cards"
                label={translate('cart.payment.title')}
              >
                <CwsSelectOption
                  key={emptyAdyenSelection.id}
                  value={emptyAdyenSelection}
                  title={translate(emptyAdyenSelection.name)}
                />
                {storedPaymentMethods.map((paymentMethod) => (
                  <CwsSelectOption
                    key={paymentMethod.id}
                    value={paymentMethod}
                    title={`**** **** **** ${paymentMethod.lastFour}`}
                  />
                ))}
              </CwsSelect>
            )}
          </CwsGridCol>
          {storedPayment && storedPayment.id.length > 3 && (
            <CwsGridCol col="2" xs="12" md="2" lg="2" className="cws-mb-32-lg">
              <CwsA textAlign="left" color="error" hideArrow onClick={deleteStoredPayment} withIcon size="small">
                <CwsIcon className="cws-mr-6" icon="bin" size="24px" color="error" />
                <CwsSpan weight="500" color="error" className="whitespace-nowrap">
                  {translate('w2.cart.remove.stored.card')}
                </CwsSpan>
              </CwsA>
            </CwsGridCol>
          )}
        </CwsGridRow>
      </CwsGridCol>
    </CwsGridRow>
  )
}

export default AdyenPayment
