import * as R from 'ramda'
import type { ShippingQuote } from '@model/order/ShippingQuote'
import type { Locale } from '@model/locales'

export const getLowestCostQuote = R.compose(R.head, R.sortBy(R.prop('cost')))
// @ts-ignore
const app = R.compose(R.defaultTo(0), R.prop('cost'), getLowestCostQuote)

export const getCheapestDelivery: (quotes: ShippingQuote[]) => number = (quotes) => app(quotes) as number

export const getCheckZipUrlForLocale = (locale: Locale): string => {
  const map: Record<Locale, string> = {
    et: 'https://www.omniva.ee/era/sihtnumbrite_otsing',
    ru: 'https://worldpostalcode.com/lookup',
    en: 'https://worldpostalcode.com/lookup',
    lv: 'https://worldpostalcode.com/lookup',
    lt: 'https://worldpostalcode.com/lookup',
    fi: 'https://www.posti.fi/fi/postinumerohaku',
    sv: 'https://www.postnummerservice.se//adressoekning',
  }

  return map[locale]
}
