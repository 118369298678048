import type { FC } from 'react'
import React, { useMemo } from 'react'
import type { ClassName, OrderTotal } from '@model/order/OrderTotal'
import { useTranslate } from '@hooks/useTranslation'
import { PriceLine } from '@components/product'
import type { GiftCouponDataForRender } from '@components/cart/OrderDetails/OrderDetails'
import { useCartState } from '@components/cart/State/state'
import type { Action, CouponsData } from '@components/cart/State/reducer'
import { getPromotionsCouponCode } from '@utils/order'
import { Order } from '@model/order/Order'
import { CwsGrid } from '@components/cws'
type OtGetterList = (...className: ClassName[]) => OrderTotal[]
type OtGetter = (className: ClassName) => OrderTotal | undefined

interface PromoAndDiscountCodesProps {
  otGetter: OtGetter
  otGetterAll: OtGetterList
  order: Order | null
}

const clapProductDiscounts = (orderTotals: OrderTotal[]): CouponsData[] => {
  const map = orderTotals.reduce((acc, val) => {
    const couponFromPromotionCode = getPromotionsCouponCode(val) || val.custom3
    return {
      ...acc,
      [val.className]: {
        coupon: couponFromPromotionCode,
        balance: acc[val.className] ? acc[val.className].balance + val.value : val.value,
      },
    }
  }, {} as Record<ClassName, CouponsData>)

  return Object.values(map)
}

export const discountPromoCodesFactory = (otGetter: OtGetterList, dispatch: React.Dispatch<Action>) => () => {
  const discountOrderTotals = otGetter(
    'ot_df_global_product_discount',
    'ot_df_product_discount',
    'ot_df_total_discount',
    'ot_df_shipping_discount'
  )

  return clapProductDiscounts(discountOrderTotals).map((coupon) => ({
    ...coupon,
    remove: () => dispatch({ type: 'remDiscountCoupon', payload: coupon.coupon }),
  }))
}

export const giftPromotionsFactory =
  (orderTotals: OrderTotal[], coupons: CouponsData[], dispatch: React.Dispatch<Action>) => () => {
    if (!orderTotals.length) return null
    const giftCards = orderTotals?.filter(({ className }) => className === 'ot_gift_coupons')

    if (giftCards.length) {
      return giftCards.map(({ title, value }) => {
        const giftCardsTitle = title
        const re = new RegExp(/=\s(\S+):$/)
        const [, code] = giftCardsTitle?.match(re) || []

        const couponData = coupons.find(({ coupon }) => code === coupon)
        if (couponData) {
          return {
            ...{ ...couponData, balance: value ?? couponData?.balance },
            remove: () => dispatch({ type: 'remCoupon', payload: couponData.coupon }),
          }
        }
        return { coupon: code, balance: 0, remove: () => ({}) }
      })
    }
    return null
  }

export const PromoAndDiscountCodes: FC<PromoAndDiscountCodesProps> = ({ otGetter, otGetterAll, order }) => {
  const { translate } = useTranslate()
  const {
    state: { discountCoupons, coupons },
    dispatch,
  } = useCartState()

  const discountPromoCodes: GiftCouponDataForRender[] = useMemo(discountPromoCodesFactory(otGetterAll, dispatch), [
    otGetter,
    dispatch,
  ])

  const giftCouponCodes: GiftCouponDataForRender[] | null = useMemo(
    giftPromotionsFactory(order?.orderTotals ?? [], coupons, dispatch),
    [order?.orderTotals, coupons, dispatch]
  )

  const orderTotalsDiscounted = useMemo(
    () =>
      otGetterAll(
        'ot_df_global_product_discount',
        'ot_df_product_discount',
        'ot_df_total_discount',
        'ot_df_shipping_discount'
      ),
    [otGetterAll]
  )

  return (
    <>
      {otGetter('ot_gift_coupons') && <hr className="text-medium-grey border-dashed mb-4 mt-6" />}
      {otGetter('ot_gift_coupons') && giftCouponCodes && order && (
        <CwsGrid className="cws-py-xs" width="fluid">
          {giftCouponCodes.map(({ coupon, balance, remove }) => (
            <PriceLine
              key={coupon}
              label={`${translate('cart.summary.gift.cards.title')}: `}
              coupon={coupon}
              value={balance}
              size="sm"
              labelClassName="text-sm"
              valueColor="midnight"
              valueClassName="flex flex-row"
              withRemoveIcon
              removeHandler={remove}
            />
          ))}
        </CwsGrid>
      )}

      {Boolean(orderTotalsDiscounted.length) && Boolean(discountCoupons.length) && (
        <>
          <hr className="text-medium-grey border-dashed my-4" />
          {discountPromoCodes.map(({ coupon, balance, remove }, index) => {
            if (!discountCoupons.includes(coupon)) return null

            return (
              <CwsGrid key={index} className="cws-py-xs" width="fluid">
                <PriceLine
                  key={index}
                  label={`${translate('cart.promo.input.title')}: `}
                  coupon={coupon}
                  value={balance ? -1 * balance : balance}
                  size="sm"
                  labelClassName="text-sm"
                  valueColor="midnight"
                  valueClassName="flex flex-row"
                  withRemoveIcon
                  removeHandler={remove}
                />
              </CwsGrid>
            )
          })}
        </>
      )}
    </>
  )
}

export default PromoAndDiscountCodes
