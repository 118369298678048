import { CwsAlert, CwsButton, CwsGridCol, CwsGridRow, CwsH4, CwsInput, CwsP } from '@components/cws'
import moduleStyles from '@components/cart/Cart.module.scss'
import type { ChangeEvent, FC } from 'react'
import { useCallback, useState } from 'react'
import { useTranslate } from '@hooks/useTranslation'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import { useCouponCheck } from '@components/cart/GiftOrPromoCode/useCouponCheck'

interface GiftOrPromoCodeProps {
  hideHeading?: boolean
}

export const GiftOrPromoCode: FC<GiftOrPromoCodeProps> = ({ hideHeading = false }) => {
  const { translate } = useTranslate()
  const { locale } = useRouter()
  const [promoValue, setPromoValue] = useState<string>('')
  const { isChecking, couponCheckError, doCheckCoupon } = useCouponCheck(promoValue, setPromoValue)

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setPromoValue(e.target.value.toUpperCase())
    },
    [setPromoValue]
  )

  return (
    <>
      {!hideHeading && <CwsH4 textAlign="left">{translate('w2.cart.discounts.header')}</CwsH4>}
      {hideHeading && (
        <CwsGridRow noGutter verticalAlign="bottom" justifyContent="space-between">
          <CwsGridCol col="12" className={clsx(moduleStyles.promoInput, 'cws-py-8')}>
            <CwsP>{translate('w2.gift.promo.info')}</CwsP>
          </CwsGridCol>
        </CwsGridRow>
      )}
      <section className={clsx(!hideHeading && moduleStyles.section, !hideHeading && 'bg-group-grey')}>
        <CwsGridRow
          noGutter={!hideHeading}
          verticalAlign="bottom"
          justifyContent={hideHeading ? 'start' : 'space-between'}
        >
          <CwsGridCol col="12">
            <CwsP size="small">{translate('w2.cart.discounts.and.promo.label')}</CwsP>
          </CwsGridCol>

          <CwsGridCol col={hideHeading ? 4 : 7} className={moduleStyles.promoInput}>
            <CwsInput
              locale={locale}
              value={promoValue}
              onChange={handleChange}
              id="promo-gift-code"
              disabled={isChecking}
              invalid={couponCheckError && promoValue.length > 0}
            />
          </CwsGridCol>
          <CwsGridCol col={hideHeading ? 2 : 4}>
            <CwsButton
              size="small"
              width="fluid"
              variant="secondary"
              disabled={isChecking}
              type="submit"
              onClick={doCheckCoupon}
            >
              {translate('w2.cart.discounts.and.promo.action')}
            </CwsButton>
          </CwsGridCol>
        </CwsGridRow>
        {couponCheckError && (
          <CwsAlert variant="inline" type="error">
            <CwsP size="small">{couponCheckError}</CwsP>
          </CwsAlert>
        )}
      </section>
    </>
  )
}

export default GiftOrPromoCode
