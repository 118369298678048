import { CwsP } from '@components/cws'
import type { ChangeEvent, FC } from 'react'
import { useEffect, useRef, useState } from 'react'
import { useTranslate } from '@hooks/useTranslation'
import moduleStyles from './ClubOneSlider.module.scss'
import { useCartState } from '@components/cart/State/state'
import { useDebouncedValue } from '@hooks/useDebounce'

export const ClubOneSlider: FC<{ availablePoints: number }> = ({ availablePoints = 0 }) => {
  const { translate } = useTranslate()

  const {
    dispatch,
    state: { redeemPoints },
  } = useCartState()

  const [localPoints, setLocalPoints] = useState(redeemPoints)
  const sliderRef = useRef<HTMLInputElement>(null)
  const slowPoints = useDebouncedValue(localPoints, 250)

  useEffect(() => {
    dispatch({ type: 'setRedeemPoints', payload: slowPoints })
  }, [slowPoints, dispatch])

  useEffect(() => {
    setLocalPoints(parseFloat(sliderRef.current?.value ?? localPoints.toString()))
  }, [availablePoints])

  const handleSlide = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setLocalPoints(parseFloat(value))
  }

  const leftBoundary = 0
  const [rightBoundary, setRightBoundary] = useState(100)

  useEffect(() => {
    const percent = (localPoints * 100) / availablePoints
    setRightBoundary(Math.ceil(percent - 2) < 100 ? Math.ceil(percent - 2) : 100)
  }, [localPoints, setRightBoundary, availablePoints])

  const style = {
    background: `linear-gradient(to right, var(--group-grey) ${leftBoundary}%, var(--midnight) ${leftBoundary}%, var(--midnight) ${rightBoundary}%, var(--group-grey) ${rightBoundary}%)`,
  }

  if (!availablePoints && availablePoints !== 0) return null

  return (
    <>
      <div className={moduleStyles.rangeContainer}>
        <div className={moduleStyles.sliderTrack} style={style} />
        <input
          ref={sliderRef}
          type="range"
          min={0}
          max={availablePoints}
          id="slider-co-points"
          value={localPoints}
          onChange={handleSlide}
        />
      </div>
      <div className="flex flex-row justify-between">
        <CwsP color="dark-grey" size="small">
          0
        </CwsP>
        <CwsP color="dark-grey" size="small">
          {availablePoints}
        </CwsP>
      </div>
    </>
  )
}

export default ClubOneSlider
