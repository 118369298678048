import type { AxiosPromise } from 'axios'
import axios from 'axios'
import type { KKError } from '@model/konakart/KKError'
import type { LanguageId } from '@model/locales'
import type { AddToWishlistItem, WishListData, WishlistItemsResponse } from '@model/wishlist/WishlistItem'
import { makeAxiosConfig } from '@utils/axios-utils'
import { KK_STORE } from '@api/apiConfig'
import type { Catalog } from '@model/pricelist/Catalog'

type WishlistResult = { r: number } & KKError
type GetWishListResult = { r: WishlistItemsResponse } & KKError
type SearchForWishListsResult = { r: { totalNumWishLists: number; wishListArray: WishListData[] } } & KKError

export const createWishlist = async (
  languageId: LanguageId,
  catalogId: Catalog,
  sessionId: string | undefined,
  customerId: string | null
): Promise<number> => {
  const searchData = {
    f: 'createWishListWithOptions',
    s: KK_STORE,
    languageId,
    wishList: {
      publicWishList: true,
      listType: 0,
    },
    sessionId: !sessionId ? null : sessionId,
    options: {
      customerId: customerId,
      catalogId,
    },
  }

  const config = makeAxiosConfig(searchData)

  const { data } = await (axios(config) as AxiosPromise<WishlistResult>)

  if (data.r) return data.r
  else throw new Error(data.m)
}

export const searchForWishlists = async (
  languageId: LanguageId,
  sessionId: string | undefined,
  guestCustomerId: string | null
): Promise<WishListData[]> => {
  const searchData = {
    f: 'searchForWishLists',
    s: KK_STORE,
    languageId,
    sessionId: !sessionId ? null : sessionId,
    ...(guestCustomerId &&
      !sessionId && {
        customerSearch: {
          tmpId: guestCustomerId,
        },
      }),
  }

  const config = makeAxiosConfig(searchData)

  const { data } = await (axios(config) as AxiosPromise<SearchForWishListsResult>)

  if (data.r) {
    return data.r.wishListArray
  } else throw new Error(data.m)
}

export const getWishlistItems = async (
  languageId: LanguageId,
  catalogId: Catalog,
  sessionId: string | undefined,
  wishListId: number,
  customerId?: number
): Promise<WishlistItemsResponse> => {
  const body = {
    f: 'getWishListWithItemsWithOptions',
    s: KK_STORE,
    languageId,
    sessionId,
    wishListId,
    options: { useExternalPrice: true, catalogId, ...(customerId && { customerId }) },
  }
  const config = makeAxiosConfig(body)

  const { data } = await (axios(config) as AxiosPromise<GetWishListResult>)

  if (data.r) return data.r
  else throw new Error(data.m)
}
export const removeWishListItem = async (
  languageId: LanguageId,
  catalogId: Catalog,
  sessionId: string | undefined,
  wishListItemId: number,
  customerId?: number
): Promise<void> => {
  const dataToSend = {
    f: 'removeFromWishListWithOptions',
    s: KK_STORE,
    languageId,
    sessionId,
    options: { catalogId, ...(customerId && { customerId }) },
    wishListItemId: wishListItemId,
  }

  const config = makeAxiosConfig(dataToSend)

  const { data } = await (axios(config) as AxiosPromise<WishlistResult>)

  if (data.m) {
    throw new Error(data.m)
  }
}
export const addToWishlist = async (
  item: AddToWishlistItem,
  languageId: LanguageId,
  catalogId: Catalog,
  sessionId: string | undefined,
  customerId?: number
): Promise<number> => {
  const dataToSend = {
    f: 'addToWishListWithOptions',
    s: KK_STORE,
    languageId,
    sessionId,
    options: { ...(customerId && { customerId }), catalogId },
    wishListItem: { ...item, wishListId: item.wishListId },
  }

  const config = makeAxiosConfig(dataToSend)

  const { data } = await (axios(config) as AxiosPromise<WishlistResult>)

  // could be axios Error

  if (data.r) return data.r
  else throw new Error(data.m)
}

export const wishlistItemsFetcher = (languageId: LanguageId, catalogId: Catalog) => async () => {
  const { data } = await axios.post(`/api/wishlist/items`, {
    languageId,
    catalogId,
  })
  return data
}

export const wishlistItemsAdder = (languageId: LanguageId, catalogId: Catalog, item: AddToWishlistItem) => async () => {
  const { data } = await axios.post(`/api/wishlist/add`, {
    languageId,
    catalogId,
    item,
  })
  return data
}

export const wishlistItemsRemover =
  (languageId: LanguageId, catalogId: Catalog, wishListItemId: number) => async () => {
    await axios.post(`/api/wishlist/remove`, {
      languageId,
      catalogId,
      wishListItemId,
    })
  }
