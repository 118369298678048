import type { FC } from 'react'
import type { ChevronProps } from './Chevron'
import Chevron from './Chevron'
import { CwsIcon } from '@components/cws'

export interface SpoilerChevronProps extends Omit<ChevronProps, 'direction'> {
  open?: boolean
}

export const SpoilerChevron: FC<SpoilerChevronProps> = (props) => {
  const { open = false, ...restProps } = props

  return <>{open ? <CwsIcon icon="arrow-up" size="24" /> : <CwsIcon icon="arrow-down" size="24" />}</> //<Chevron {...restProps} direction={open ? 'up' : 'down'} />
}

export default SpoilerChevron
