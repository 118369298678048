import { Product } from '@model/product'
import { Variant, VariantInfo } from '@model/product/VariantsInfo.js'
import { always, anyPass, compose, defaultTo, has, identity, ifElse, propOr, tryCatch } from 'ramda'

export const getParsedCustom8OrEmptyObject = (product: Product) => {
  const emptyVariant = {}

  const app: (p: Product) => VariantInfo = compose(
    ifElse(anyPass([has('id'), has('min')]), identity, always(emptyVariant)),
    tryCatch((json: string) => JSON.parse(json), always(emptyVariant)),
    defaultTo('') as () => string,
    propOr('', 'custom8'),
  ) as (p: Product) => VariantInfo

  const parsedCustom8 = app(product)

  parsedCustom8.variants?.forEach((x: Variant) => {
    if (x.size) x.size = x.size.trim()
  })

  if (parsedCustom8 && parsedCustom8.type) {
    parsedCustom8.hideColorSelection =
      parsedCustom8.type &&
      (parsedCustom8.type.toLowerCase() === 'size-all' || parsedCustom8.type.toLowerCase() === 'size-parent')
  }

  return parsedCustom8
}

export const disabledProductsPredicate = (product: Product) => {
  const isOutOfStock = !product.quantity || product.quantity < 1
  const isDisabled = product.status === 0
  const shouldHide = isOutOfStock || isDisabled

  return !shouldHide
}
