import type { Product } from '@model/product'
import { useMemo } from 'react'

export function useSpecialPrice(product: Product): number | null {
  const { specialExpiryDate, specialStartDate, specialPriceExTax, specialStatus } = product

  const specialPrice = useMemo(() => {
    const now = Date.now()
    if (!specialStatus || !specialPriceExTax || now < specialStartDate || now > specialExpiryDate) {
      return null
    }

    return specialPriceExTax
  }, [specialExpiryDate, specialStartDate, specialPriceExTax, specialStatus])

  return specialPrice
}
