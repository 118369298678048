import type { Currency } from '@model/product/PriceInfo'
import { useRouter } from 'next/dist/client/router'
import type { LanguageId } from '@model/locales'
import { isGloballyPricelist } from '@utils/pricelist-utils/app-properties'

export const CURRENCY_MAP: Record<string, Currency> = {
  et: 'EUR',
  en: 'EUR',
  ru: 'EUR',
  sv: 'SEK',
  fi: 'EUR',
  lt: 'EUR',
  lv: 'EUR',
} as const

export type CurrenciesInfo = {
  code: Currency
  symbolLeft: string
  symbolRight: 'kr' | '€'
  thousandsPoint: string
  title: string
  value: number
}

export type CurrencyOpt = { rate: number; currency: Currency }

interface UseCurrency {
  currency: Currency
}

export const getCurrencyOptByLanguageId = (languageId: LanguageId, currencies: CurrenciesInfo[]): CurrencyOpt => {
  const cCode: Currency = languageId === 7 ? 'SEK' : 'EUR'

  const currency = currencies.find(({ code }) => code === cCode) || {
    value: 1,
    code: 'EUR',
  }

  return {
    rate: currency.value,
    currency: currency.code,
  }
}

export const useCurrency = (): UseCurrency => {
  const { locale = 'en' } = useRouter()
  const currency: Currency = isGloballyPricelist() ? 'EUR' : CURRENCY_MAP[locale]

  return {
    currency,
  }
}
