import type { FC, ReactNode } from 'react'
import { Fragment, useMemo } from 'react'
import { Badge } from '@components/product'
import { useTranslate } from '@hooks/useTranslation'
import { useInitialData } from '@hooks/useInitialData'
import type { PromotionResult } from '@model/product/Product'
import { getPromotionNameById, getPromotionsTag } from '@components/product/ProductCard/ProductTagsRow/tags-utils'
import { findPopularKeyForLabel } from '@utils/findPopularKeyForLabel'

const removeDuplicateBadges = (tags: TagData[]): TagData[] => {
  const seenBadges: { [badge: string]: boolean } = {}
  return tags.filter((tag) => {
    if (seenBadges[tag.badge as string]) {
      return false
    } else {
      seenBadges[tag.badge as string] = true
      return true
    }
  })
}
interface ProductTagsRowProps {
  campaign?: string[]
  label?: string
  bundleLabel?: string
  bundleType?: string
  giftPromotionId?: number
  promotionResults: PromotionResult[]
  orderProductRow?: boolean
  promoLabel?: string
}

type TagData = {
  className?: string
  type?: 'regular'
  badge: ReactNode
  key: string
}

const GENERAL_WRAPPER_CLASS = 'justify-self-start shrink-0 mr-2 mt-1 max-w-[210px]'

export const ProductTagsRow: FC<ProductTagsRowProps> = ({
  bundleLabel,
  campaign,
  label,
  promotionResults,
  giftPromotionId,
  orderProductRow,
  promoLabel,
  bundleType,
}) => {
  const { translate } = useTranslate()
  const { allPromotions } = useInitialData()

  const { promotionName: mainPromotion } = useMemo(
    () => getPromotionsTag(allPromotions)(promotionResults),
    [allPromotions, promotionResults]
  )

  const campaignWithTranslate = campaign?.map((item) => translate(findPopularKeyForLabel(item)))

  const giftPromotionName = useMemo(() => {
    const raw = giftPromotionId ? getPromotionNameById(allPromotions)(giftPromotionId) : ''
    return Boolean(raw) ? translate(findPopularKeyForLabel(raw)) : ''
  }, [allPromotions, giftPromotionId, translate])

  const tags = useMemo(() => {
    let tags: TagData[] = []
    if (orderProductRow) tags = []

    if (campaign && !campaignWithTranslate?.includes(translate(findPopularKeyForLabel(mainPromotion ?? '')))) {
      campaignWithTranslate?.forEach((campaign) => {
        tags.push({
          className: '',
          badge: translate(findPopularKeyForLabel(campaign)),
          key: campaign,
        })
      })
    }

    if (mainPromotion) {
      tags.push({
        className: '',
        badge: translate(findPopularKeyForLabel(mainPromotion)),
        key: mainPromotion,
      })
    }

    if (giftPromotionName && giftPromotionName !== translate(findPopularKeyForLabel(mainPromotion ?? ''))) {
      tags.push({
        className: '',
        badge: giftPromotionName,
        key: `${giftPromotionId}`,
      })
    }

    if (promoLabel) {
      tags.push({
        className: '',
        badge: translate(findPopularKeyForLabel(promoLabel)),
        key: promoLabel,
      })
    }

    if (
      bundleLabel &&
      !campaignWithTranslate?.includes(translate(findPopularKeyForLabel(bundleLabel))) &&
      translate(findPopularKeyForLabel(bundleLabel)) !== translate(findPopularKeyForLabel(mainPromotion ?? ''))
    ) {
      tags.push({
        className: '',
        badge: translate(findPopularKeyForLabel(bundleLabel)),
        key: bundleLabel,
      })
    } else if (
      bundleType &&
      !campaignWithTranslate?.includes(translate(findPopularKeyForLabel(bundleType))) &&
      translate(findPopularKeyForLabel(bundleType)) !== translate(findPopularKeyForLabel(mainPromotion ?? ''))
    ) {
      tags.push({
        className: '',
        badge: translate(findPopularKeyForLabel(bundleType)),
        key: bundleType,
      })
    } else if (
      label &&
      !campaignWithTranslate?.includes(translate(findPopularKeyForLabel(label))) &&
      translate(findPopularKeyForLabel(label)) !== translate(findPopularKeyForLabel(mainPromotion ?? ''))
    ) {
      tags.push({
        className: '',
        type: 'regular',
        badge: translate(findPopularKeyForLabel(label as any)),
        key: label,
      })
    }

    return tags.length > 1 ? tags.filter((t) => t.className !== 'invisible') : tags
  }, [mainPromotion, giftPromotionName, label, campaign, giftPromotionId, bundleLabel, translate, orderProductRow])

  const uniqueTags = removeDuplicateBadges(tags)

  return (
    <>
      {uniqueTags.map(({ badge, type, key }) => (
        <Fragment key={key}>
          <Badge type={type}>{badge}</Badge>
        </Fragment>
      ))}
    </>
  )
}
