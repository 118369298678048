import type { Customer } from '@model/customer/Customer'
import type { Dispatch } from 'react'
import type { CartItem } from '@model/cart/CartItem'

export interface AppState {
  customer: Customer | null
  searchTerm: string | null
  cart: CartItem[]
  activeCategoryPage: number
  topOffset: number
  productsListId?: string
}

export enum AppStateTypes {
  setCustomer,
  setSearchTerm,
  setBasketRedirectHost,
  setActiveCategoryPage,
  setTopOffset,
  setListId,
}

export interface SetCustomerAction {
  type: AppStateTypes.setCustomer
  payload: Customer | null
}

export interface SetSearchTermAction {
  type: AppStateTypes.setSearchTerm
  payload: string
}

export interface SetActiveCategoryAction {
  type: AppStateTypes.setActiveCategoryPage
  payload: number
}

export interface SetTopOffsetAction {
  type: AppStateTypes.setTopOffset
  payload: number
}

export interface SetListIdAction {
  type: AppStateTypes.setListId
  payload: string
}

export type AppAction =
  | SetCustomerAction
  | SetSearchTermAction
  | SetActiveCategoryAction
  | SetTopOffsetAction
  | SetListIdAction

export interface AppStateContextProps {
  state: AppState
  dispatch: Dispatch<AppAction>
}

const neverError = (message: string, token: never): void => {
  throw new Error(`${message} : ${token} should not exist`)
}

export const appStateReducer = (state: AppState, action: AppAction): AppState => {
  switch (action.type) {
    case AppStateTypes.setCustomer: {
      const customer = action.payload
      return { ...state, customer }
    }
    case AppStateTypes.setSearchTerm: {
      const searchTerm = action.payload
      return { ...state, searchTerm }
    }
    case AppStateTypes.setActiveCategoryPage: {
      return { ...state, activeCategoryPage: action.payload }
    }
    case AppStateTypes.setTopOffset: {
      return { ...state, topOffset: action.payload }
    }
    case AppStateTypes.setListId: {
      const { productsListId, topOffset } = state
      return {
        ...state,
        productsListId: action.payload,
        topOffset: productsListId !== action.payload ? 0 : topOffset,
      }
    }
    default:
      neverError('Irrelevant type', action)
      return { ...state }
  }
}
