import * as R from 'ramda'
import type { CartItem } from '@model/cart/CartItem'

export const TALLINK_WAREHOUSE_ADAPTER_CODE = 'tallink'

export const includesAlcohol = (cartItems: CartItem[]) => {
  const alcoholFinder = R.find(R.propEq('custom2', 'alcohol'))
  const app = R.compose(R.not, R.isNil, alcoholFinder)

  return app(cartItems)
}

const adapterCodePath = R.pathOr('', ['product', 'customd4'])
export const groupByAdapterCode = R.groupBy(adapterCodePath)

export const uniqueCodes = R.compose(R.uniq, R.map(adapterCodePath))

export const sortByKey = (key: string) => {
  switch (key) {
    case 'tallink':
      return 1
    default:
      return 2
  }
}
