import type { FC, SVGProps } from 'react'
import type { Color, Direction } from '@ui/system'
import clsx from 'clsx'
import s from './Chevron.module.scss'

export interface ChevronProps extends Omit<SVGProps<SVGSVGElement>, 'color'> {
  color?: Color
  direction?: Direction
}

export const Chevron: FC<ChevronProps> = (props) => {
  const { color = '#004152', direction = 'right', className, ...restProps } = props

  return (
    <svg
      width="14"
      height="14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(s[direction as keyof typeof s], className)}
      {...restProps}
    >
      <path
        d="M4.511 13.767a.556.556 0 0 1-.785 0l-.786-.786a.556.556 0 0 1 0-.785l4.931-4.932-4.93-4.931a.556.556 0 0 1 0-.786l.785-.785a.556.556 0 0 1 .785 0L10.62 6.87a.553.553 0 0 1 .163.395.554.554 0 0 1-.163.393l-6.109 6.109z"
        fill="currentColor"
        className={`text-${color}`}
      />
    </svg>
  )
}

export default Chevron
