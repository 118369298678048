import type { FC, PropsWithChildren } from 'react'
import { useEffect } from 'react'
import { useMediaLess } from '@ui/hooks/useMediaLess'
import FooterComponent from '../Footer/Footer'
import { useSso } from '@hooks/useSso'
import { IntegratedHeader } from '@components/common/Header/IntegratedHeader'
import { useRouter } from 'next/router'
import { useAppState } from '@context/state'
import { AppStateTypes } from '@context/reducer'
import { nicerLog } from '@utils/console-utils'
import { device600 } from '@ui/hooks/useResize'
import { Subscription } from '@components/common/Subscription'
import { isGloballyPricelist } from '@utils/pricelist-utils/app-properties'
import BreadCrumbs from '@components/BreadCrumbs'
import { localStorageHelper } from '@utils/localStorageHelper'
import { CART_STATE_KEY } from '@components/cart/State/state'
import BreadCrumbsProvider from '@components/BreadCrumbs/State/state'
import { CwsGrid, CwsSection, CwsGridRow, CwsGridCol } from '@components/cws'
export const Layout: FC<PropsWithChildren> = (props) => {
  const { children } = props
  const { events, pathname } = useRouter()

  const {
    dispatch,
    state: { topOffset, productsListId },
  } = useAppState()

  const isMobile = useMediaLess(device600)

  useSso()

  const {
    state: { customer },
  } = useAppState()

  useEffect(() => {
    if (pathname === '/cart') return
    localStorageHelper.remove(CART_STATE_KEY)
  }, [])
  useEffect(() => {
    const handle: (p: string) => void = (fullPath) => {
      if (fullPath.includes('/collection/') || fullPath.includes('/products/') || fullPath.includes('/category/')) {
        dispatch({ type: AppStateTypes.setListId, payload: fullPath })

        if (topOffset > 0 && productsListId === fullPath) {
          nicerLog(`Scrolling to top offset ${topOffset}`)
          setTimeout(() => window.scrollTo({ top: topOffset, behavior: 'auto' }), 200)
        }
      }
    }

    events.on('routeChangeComplete', handle)
    return () => {
      events.off('routeChangeComplete', handle)
    }
  }, [dispatch, events, topOffset, productsListId])

  if (isMobile === null) {
    return null
  }
  const { newsletter = '0' } = customer ?? { newsletter: '0' }

  const isIndexPage = pathname === '/'
  const is404Page = pathname === '/404'
  const isShoppingList = pathname.endsWith('/shopping-list')
  const visibleHeaderAndFooter = !pathname.endsWith('/wishlist-print')

  const bgColor = isGloballyPricelist() && (isIndexPage || is404Page || isShoppingList) ? 'bg-group-grey' : 'bg-white'
  return (
    <CwsSection backgroundColor="white">
      <BreadCrumbsProvider>
        {!isMobile && visibleHeaderAndFooter && <IntegratedHeader header={true} menu={true} />}

        <CwsSection variant="div" className="mt-[85px] cws-pt-xs device600:mt-0">
          <CwsGrid>
            <CwsGridRow>
              <CwsGridCol col="12">
                {!isIndexPage && visibleHeaderAndFooter && <BreadCrumbs />}
                {children}
                {newsletter !== '1' && visibleHeaderAndFooter && <Subscription />}
              </CwsGridCol>
            </CwsGridRow>
          </CwsGrid>
        </CwsSection>
      </BreadCrumbsProvider>
      {visibleHeaderAndFooter && <FooterComponent />}
      {isMobile && visibleHeaderAndFooter && <IntegratedHeader header={true} menu={true} />}
    </CwsSection>
  )
}

export default Layout
