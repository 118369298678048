import * as R from 'ramda'
import { format, differenceInCalendarYears, parse, isAfter, isBefore } from 'date-fns'

type AgeGroup = 1 | 2 | 3 | 4 | 5 | 6 | 7

export interface BirthData {
  ageGroup: AgeGroup
  birthMonthDay: string
}

const getAgeFromBirthDate = (birthDate: Date) => differenceInCalendarYears(new Date(), birthDate)

const getAgeGroup = R.cond([
  [R.gte(19), R.always(1)],
  [R.gte(29), R.always(2)],
  [R.gte(39), R.always(3)],
  [R.gte(49), R.always(4)],
  [R.gte(59), R.always(5)],
  [R.gte(69), R.always(6)],
  [R.T, R.always(7)],
])

export const getBirthMonthDayAndAgeGroup = (birthDate: Date): BirthData => {
  const ageGroupApp = R.compose(getAgeGroup, getAgeFromBirthDate)

  return {
    ageGroup: ageGroupApp(birthDate) as AgeGroup,
    birthMonthDay: format(birthDate, 'MM-dd'),
  }
}

export const isInDateRange = (startDateString: string, endDateString: string): boolean => {
  const now = new Date()
  const dateFrom = parse(startDateString, 'dd.MM.yyyy', now)
  const dateTo = parse(endDateString, 'dd.MM.yyyy', now)

  return isAfter(now, dateFrom) && isBefore(now, dateTo)
}
