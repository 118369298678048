import type { FC, PropsWithChildren, Ref } from 'react'
import { forwardRef } from 'react'

const ProductList: FC<PropsWithChildren> = forwardRef((props, ref: Ref<HTMLDivElement>) => {
  const { children } = props
  return (
    <div className="flex flex-col flex-product-list space-y-lg" ref={ref}>
      {children}
    </div>
  )
})
ProductList.displayName = 'ProductList'

export default ProductList
