import { FC, MouseEventHandler } from 'react'
import clsx from 'clsx'
import { Typography } from '@ui/components'
import s from './SizeRadio.module.scss'

export type SizeRadioSize = 'sm' | 'md' | 'lg'

type SizeRadioValueMap = {
  [key in SizeRadioSize]: string
}

export interface SizeRadioProps {
  checked: boolean
  label: string
  size?: SizeRadioSize
  onClick?: MouseEventHandler<HTMLDivElement>
}

const sizeValueMap: SizeRadioValueMap = {
  sm: '8',
  md: '10',
  lg: '12',
}

const SizeRadio: FC<SizeRadioProps> = (props) => {
  const { label, checked, size = 'sm', onClick } = props

  const sizeValue = sizeValueMap[size]

  return (
    <div className="flex flex-col justify-items-center cursor-pointer" onClick={onClick}>
      <div
        className={clsx(
          `flex items-center justify-center bg-group-grey mb-2`,
          `w-${sizeValue} h-${sizeValue} rounded-full`,
          checked && s.checked
        )}
      >
        {checked && <CheckIcon />}
      </div>
      <Typography variant="body-mobile" className={clsx(checked && s['checked-label'])}>
        {label}
      </Typography>
    </div>
  )
}

export default SizeRadio

const CheckIcon = () => {
  return (
    <svg width="16" height="17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m15.803 4.258-9.899 9.9a.667.667 0 0 1-.943 0l-.943-.944L.195 9.391a.667.667 0 0 1 0-.943l.943-.942c.26-.26.683-.26.943 0l3.352 3.351 8.485-8.485c.26-.26.682-.26.943 0l.942.943c.26.26.26.682 0 .943z"
        fill="#004152"
      />
    </svg>
  )
}
