import type { FC } from 'react'
import { Badge } from '@components/product'
import RatingStars from '../Rating/RatingStars'
import { useTranslate } from '@hooks/useTranslation'
import { CwsH1, CwsSpan, CwsP } from '@components/cws'
import { isGloballyPricelist } from '@utils/pricelist-utils/app-properties'
import { findPopularKeyForLabel } from '@utils/findPopularKeyForLabel'
export interface ProductViewHeadingProps {
  manufacturerName: string
  name: string
  productCode: number | string
  rating: number
  hasReviews?: boolean
  campaign?: string[]
  giftPromoName?: string
  bundleLabel?: string
  webLabel?: string
  bundleType?: string
  mainPromotion?: string
}

const ProductViewHeading: FC<ProductViewHeadingProps> = (props) => {
  const isPricelist = isGloballyPricelist()
  const {
    rating,
    manufacturerName,
    name,
    productCode,
    hasReviews,
    campaign,
    giftPromoName,
    bundleLabel,
    webLabel,
    bundleType,
    mainPromotion,
  } = props

  const { translate } = useTranslate()
  const bundle = isPricelist ? bundleLabel : bundleType
  const giftPromoTranslated = giftPromoName ? translate(findPopularKeyForLabel(giftPromoName)) : ''
  const campaignWithTranslate = campaign?.map((item) => translate(findPopularKeyForLabel(item)))

  return (
    <div className="flex flex-col justify-start justify-items-start space-y-sm laptop:py-xs">
      {/*{todo: replace it with ProductTagsRow}*/}
      <div className="flex flex-wrap">
        {!!campaignWithTranslate?.length &&
          campaignWithTranslate?.map((campaign) => (
            <div key={campaign} className="mr-2 mt-1">
              <Badge>{campaign}</Badge>
            </div>
          ))}
        {Boolean(mainPromotion) && (
          <div className="mr-2 mt-1">
            <Badge>{translate(findPopularKeyForLabel(mainPromotion ?? ''))}</Badge>
          </div>
        )}
        {Boolean(giftPromoName) && campaignWithTranslate?.includes(giftPromoTranslated) && (
          <div className="mr-2 mt-1">
            <Badge>{giftPromoTranslated}</Badge>
          </div>
        )}
        {Boolean(bundle) &&
          !campaignWithTranslate?.includes(translate(bundle ?? '')) &&
          translate(findPopularKeyForLabel(bundle ?? '')) !==
            translate(findPopularKeyForLabel(mainPromotion ?? '')) && (
            <div className="mr-2 mt-1">
              <Badge>{translate(findPopularKeyForLabel(bundle ?? ''))}</Badge>
            </div>
          )}
        {Boolean(webLabel) && (
          <div className="mr-2 mt-1">
            <Badge type="regular">{webLabel && translate(findPopularKeyForLabel(webLabel))}</Badge>
          </div>
        )}
      </div>
      <CwsH1 className="flex flex-col">
        <CwsSpan>{manufacturerName}</CwsSpan>
        <CwsSpan weight="500">{name}</CwsSpan>
      </CwsH1>
      <CwsP size="small" color="dark-grey">{`${translate('product.detail.sku')}: ${productCode}`}</CwsP>
      {hasReviews && <RatingStars filledCount={rating} count={5} />}
    </div>
  )
}

export default ProductViewHeading
