import type { Catalog } from '@model/pricelist/Catalog'
import type { Promotion } from '@model/misc/Promotion'

export interface PromoDescription {
  description: string
  route: string
}

export const isPromotionValidForCatalog =
  (catalogId: Catalog | '') =>
  (promo: Promotion): boolean => {
    if (!catalogId) return true

    const { description } = promo

    try {
      const { route = '' } = JSON.parse(description) as PromoDescription
      return route
        .split(',')
        .map((s) => s.trim())
        .includes(catalogId)
    } catch (e) {
      console.warn('Unable to parse promotion description')
    }

    return true
  }
