import type { Dispatch, FC, SetStateAction } from 'react'
import { useEffect, useRef } from 'react'
import { CwsSelect, CwsSelection, CwsSelectionItem, CwsSelectOption } from '@components/cws'
import { device360, useResize } from '@ui/hooks/useResize'

interface PerPageProps {
  itemsPerPageValue: string
  setItemsPerPageValue: Dispatch<SetStateAction<string>>
}

const pagingItems = ['30', '60', '120'] as const

export const PerPage: FC<PerPageProps> = ({ itemsPerPageValue, setItemsPerPageValue }) => {
  const prevPerPage = useRef<string>(itemsPerPageValue)
  useEffect(() => {
    prevPerPage.current = itemsPerPageValue
  }, [itemsPerPageValue])
  const { deviceSize } = useResize()
  return (
    <>
      {deviceSize > device360 ? (
        <>
          <CwsSelection onChange={(value: string) => setItemsPerPageValue(value)} width="fluid">
            {pagingItems.map((item) => {
              const active = item === itemsPerPageValue
              return <CwsSelectionItem key={item} label={item} value={item} checked={active} />
            })}
          </CwsSelection>
        </>
      ) : (
        <>
          <CwsSelect value={itemsPerPageValue} onChange={setItemsPerPageValue}>
            {pagingItems.map((item) => (
              <CwsSelectOption key={item} value={item} title={item} />
            ))}
          </CwsSelect>
        </>
      )}
    </>
  )
}
