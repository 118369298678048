import type { FC } from 'react'
import { useCallback } from 'react'
import { CwsIcon, CwsGridCol } from '@components/cws'

export interface RatingStarsProps {
  filledCount: number
  count: number
}

const RatingStars: FC<RatingStarsProps> = (props) => {
  const { filledCount = 0, count = 5 } = props

  const renderStar = useCallback((filled: boolean, index: number) => {
    return <CwsIcon className="cws-mr-2" icon={filled ? 'star-filled' : 'star'} size="10px" />
  }, [])

  if (filledCount === 0 && count === 0) return null

  return (
    <CwsGridCol className="cws-d-flex" col="auto">
      {!!filledCount && new Array(Math.ceil(filledCount)).fill(true).map(renderStar)}
      {new Array(Math.ceil(count - filledCount)).fill(false).map(renderStar)}
    </CwsGridCol>
  )
}

export default RatingStars
