import { useEffect, useRef } from 'react'
import type { AxiosPromise, AxiosRequestConfig } from 'axios'
import axios from 'axios'
import type { Customer } from '@model/customer/Customer'
import { useAppState } from '@context/state'
import { useRouter } from 'next/dist/client/router'
import type { Locale } from '@model/locales'
import { KK_LANG_IDS } from '@model/locales'
import { AppStateTypes } from '@context/reducer'
import { useMediaLess } from '@ui/hooks/useMediaLess'
import { useStats } from '@hooks/useStats'
import { useAlert } from '@context/alert'
import * as R from 'ramda'
import { logBeacon } from '@utils/beacon'
import { localStorageHelper } from '@utils/localStorageHelper'
import { isGloballyPricelist } from '@utils/pricelist-utils/app-properties'
import { device600 } from '@ui/hooks/useResize'

const languages = {
  en: 'en-US',
  fi: 'fi-FI',
  et: 'et-EE',
  ru: 'ru-RU',
  lv: 'lv-LV',
  lt: 'lt-LT',
  sv: 'sv-SE',
} as const

export const getWsCookies = (): { sessionId?: unknown } => {
  const cookiesList = document.cookie.split('; ')

  const subApp = R.compose(R.last, R.split('='), R.defaultTo(''))

  const sessionId = R.compose(
    subApp,
    R.find((c: string) => c.startsWith('wsSessionId'))
  )(cookiesList)

  return { sessionId }
}

export const useSso = () => {
  const { dispatch } = useAppState()
  const { userAuthenticated, userReleased } = useStats()
  const { locale, push, pathname, query } = useRouter()

  const isMobile = useMediaLess(device600)
  const { showAlert } = useAlert()

  const { catalogId } = query

  const isPrinting = pathname.endsWith('/wishlist-print')

  const ssoInitiated = useRef<boolean>(false)
  const ssoRetried = useRef<number>(0)

  useEffect(() => {
    if (isPrinting) return () => {}

    const t = setInterval(() => {
      ssoRetried.current += 1
      // eslint-disable-next-line no-console
      console.log('Trying to initiate SSO ... ', ssoRetried.current)
      if (ssoInitiated.current) {
        clearInterval(t)
        // eslint-disable-next-line no-console
        return
      }
      if ('SSO' in window) {
        window.SSO.render({
          container: '#sso-container',
          isMobile: isMobile,
          showSignedInMobile: true,
          mobileBackground: true,
          styles: {},
          onError: () => {
            // console.log('sso error')
          },
          onRender: () => {
            // console.log('sso Render')
          },
          onSignIn: async (token: string, userId: string) => {
            const config: AxiosRequestConfig = {
              method: 'post',
              url: '/api/auth',
              data: {
                token,
                userId,
                languageId: KK_LANG_IDS[locale as Locale],
              },
            }
            try {
              // is this multiple merge for SHOP-3429 ?
              logBeacon(`SSO signIn ${getWsCookies().sessionId}`)
              localStorageHelper.set('ssoToken', token)

              const { data: customer } = await (axios(config) as AxiosPromise<Customer>)
              await (axios({
                method: 'post',
                url: '/api/cart/merge',
                data: {},
              }) as AxiosPromise<void>)
              dispatch({ type: AppStateTypes.setCustomer, payload: { ...customer, ssoToken: token } })
              userAuthenticated(customer.id)
            } catch (error) {
              if (axios.isAxiosError(error)) {
                const errorMessage = R.pathOr('Auth Error', ['response', 'data'])(error)
                console.warn(errorMessage)
                showAlert({ error: true, text: 'SSO Login Failed', hideDelay: 4000 })
                dispatch({ type: AppStateTypes.setCustomer, payload: null })
                userReleased()
                window.SSO.signOut()
                push('/').catch(console.error)
              }
            }
          },
          onSignOut: async () => {
            const config: AxiosRequestConfig = {
              method: 'post',
              url: '/api/release-auth',
              data: {},
            }

            logBeacon('SSO onSignOut')
            localStorageHelper.remove('ssoToken')
            await axios(config)
            dispatch({ type: AppStateTypes.setCustomer, payload: null })
            push('/').catch(console.error)
          },
          links: [],
          linkToSettings: isGloballyPricelist() && catalogId ? `/${catalogId}/profile` : '/profile',
          pathToServer: window.envs.ssoServer,
          language: languages[locale as Locale],
          ga: null,
          onAuthFailure: () => [],
        })
        ssoInitiated.current = true
      }
    }, 400)

    return () => clearInterval(t)
  }, [dispatch, locale, isMobile, userAuthenticated, userReleased, push, showAlert, isPrinting])
}
