import clsx from 'clsx'
import type { FC, MouseEventHandler, PropsWithChildren } from 'react'
import s from './PageButton.module.scss'

export interface PageButtonProps {
  active?: boolean
  onClick?: MouseEventHandler
}

const PageButton: FC<PropsWithChildren<PageButtonProps>> = (props) => {
  const { active = false, onClick } = props
  return (
    <div
      className={clsx(
        'flex h-full w-full place-items-center',
        'place-content-center cursor-pointer',
        'rounded-full text-sm',
        s.transition,
        active && 'bg-midnight text-white'
      )}
      onClick={onClick}
    >
      {props.children}
    </div>
  )
}

export default PageButton
