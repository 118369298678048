import type { Product, ProductReview as Review } from '@model/product'
import {
  CwsButton,
  CwsDivider,
  CwsGrid,
  CwsGridCol,
  CwsGridRow,
  CwsH3,
  CwsP,
  CwsSection,
  CwsSelect,
  CwsSelectOption,
} from '@components/cws'
import type { FC } from 'react'
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { ProductReview } from '@components/product'
import { useTranslate } from '@hooks/useTranslation'
import { RoundPagination } from '@components/common/RoundPagination'
import type { ProductReviewResult } from '@model/product/ProductReviewResult'
import type { KeyLabelOption } from '@ui/components/Selector/Selector'
import { ProductAddReview } from '@components/product/ProductViewReviews/ProductAddReview'
import axios from 'axios'
import { Loader } from '@ui/components/Loader'
import { useRouter } from 'next/router'
import type { Locale } from '@model/locales'
import { KK_LANG_IDS } from '@model/locales'
import type { ProductReview as ReviewItem } from '@model/product/ProductReview'
import { OrderKey } from '@model/product/ProductSearchResult'

export interface ProductViewReviewsProps {
  // reviews: Review[]
  product: Product
  sku: string
  reviewsResult: ProductReviewResult
  setAverageRating: React.Dispatch<React.SetStateAction<number>>
  setReviewsTotal: React.Dispatch<React.SetStateAction<number>>
  reviewsTotal: number
  inTab?: boolean
}

enum ReviewsSorting {
  ByDateAddedAscending = 'ORDER_BY_DATE_ADDED_ASCENDING',
  ByDateAddedDescending = 'ORDER_BY_DATE_ADDED_DESCENDING',
  ByRatingAscending = 'ORDER_BY_RATING_ASCENDING',
  ByRatingDescending = 'ORDER_BY_RATING_DESCENDING',
}

const ReviewsSortingTranslation = {
  [ReviewsSorting.ByDateAddedAscending]: 'search.page.orderby.',
  [ReviewsSorting.ByDateAddedDescending]: 'search.page.orderby.pricedesc',
  [ReviewsSorting.ByRatingAscending]: '',
  [ReviewsSorting.ByRatingDescending]: '',
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}

const ProductViewReviews: FC<ProductViewReviewsProps> = (props) => {
  const { reviewsResult, sku, product, setAverageRating, reviewsTotal, setReviewsTotal, inTab } = props

  const { reviewArray: initialReviews } = reviewsResult ?? { reviewArray: [], totalNumReviews: 0 }

  const [reviews, setReviews] = useState<ReviewItem[]>(initialReviews || [])

  const { translate } = useTranslate()
  const { locale } = useRouter()
  const languageId = KK_LANG_IDS[locale as Locale]

  const [isFormVisible, setVisible] = useState(false)

  const renderReviewItem = useCallback((review: Review) => {
    return (
      <Fragment key={review.id}>
        <ProductReview review={review} />
        <CwsDivider spacing="xs" />
      </Fragment>
    )
  }, [])

  const [page, setPage] = useState(1)

  const totalPages = useMemo(() => {
    return Math.ceil(reviewsTotal / 3)
  }, [reviewsTotal])

  const sortingOptions: KeyLabelOption[] = [
    {
      key: 'none',
      label: '--',
      translationKey: 'search.page.orderby.none',
    },
    ...Object.values(ReviewsSorting).map((sortingValue) => ({
      key: sortingValue,
      name: sortingValue,
      label: sortingValue,
      translationKey: `reviews.list.order.${sortingValue}`,
    })),
  ]
  const [sortingOption, setSortingOption] = useState(ReviewsSorting.ByRatingAscending)

  const [isLoading, setIsLoading] = useState(false)

  const fetchReviews = (orderBy?: ReviewsSorting) => {
    if (isLoading) {
      return
    }

    setIsLoading(true)
    setPage(1)

    axios
      .post('/api/product/reviews', {
        languageId,
        sku,
        orderBy: orderBy || sortingOption,
        limit: 200,
      })
      .then((resp) => {
        const { totalNumReviews, reviewArray, averageRating } = resp.data ?? {}
        setReviewsTotal(totalNumReviews)
        setReviews(reviewArray)
        setAverageRating(averageRating || 0)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    fetchReviews(sortingOption)
  }, [sortingOption, languageId, sku]) // not good

  const { width: windowWidth } = useWindowDimensions()

  const maxPagesInView = useMemo(() => {
    return windowWidth > 568 ? 5 : Math.floor((windowWidth - 32 - 64 - 64) / 36)
  }, [windowWidth])

  const initialValue = (sortingOptions.length && sortingOptions[0].key) || 'none'
  const [value, setValue] = useState<OrderKey>(initialValue as OrderKey)

  return (
    <CwsSection className="cws-pt-l">
      <CwsGrid width="fluid">
        <CwsGridRow>
          <CwsGridCol>
            {!inTab && <CwsH3>{translate('product.detail.description.reviews')}</CwsH3>}
            {reviews.length ? (
              <CwsSection className="w-full cws-mb-m">
                <CwsGrid width="fluid">
                  <CwsGridRow verticalAlign="center" justifyContent="left">
                    <CwsGridCol lg="5" col="5" xs="12">
                      <CwsSelect
                        value={value}
                        onChange={(val: OrderKey) => {
                          setValue(val)
                          setSortingOption(val as ReviewsSorting)
                        }}
                        id="category-items-sort"
                        label={`${translate('search.page.orderby.label')}: `}
                      >
                        {sortingOptions.map(({ label, translationKey, key }) => (
                          <CwsSelectOption
                            key={key}
                            value={key}
                            title={translationKey ? translate(translationKey) : label}
                          />
                        ))}
                      </CwsSelect>
                    </CwsGridCol>
                  </CwsGridRow>
                </CwsGrid>
              </CwsSection>
            ) : (
              <CwsP className="cws-mb-s">{translate('reviews.s1.default.review.message')}</CwsP>
            )}

            {isLoading ? (
              <>
                <div className="flex flex-col w-full items-center justify-center py-4">
                  <div className="animate-spin">
                    <Loader />
                  </div>
                  <CwsP weight="500">{translate('w2.spinner.message')}</CwsP>
                </div>
                {/* <div>loading</div> */}
              </>
            ) : (
              <>
                {reviews.slice(page * 3 - 3, page * 3).map(renderReviewItem)}
                {/* {reviews.length > 10 && <OldPagination current={1} total={10} onPageClick={() => {}} />} */}
                <div className="flex flex-1 w-full justify-center laptop:justify-start">
                  {totalPages > 1 && (
                    <RoundPagination
                      totalPages={totalPages}
                      page={page}
                      onPageClick={setPage}
                      maxPagesInView={maxPagesInView}
                    />
                  )}
                </div>
              </>
            )}

            {Boolean(reviews.length) && <div className="mt-4 device360:mt-6" />}

            {!isFormVisible && (
              <CwsButton
                width="fluid-mobile"
                variant="secondary"
                size="medium"
                onClick={() => setVisible(!isFormVisible)}
              >
                <span className="text-base font-medium leading-4">{translate('reviews.label.add.review')}</span>
              </CwsButton>
            )}
            {isFormVisible && (
              <ProductAddReview productId={product.id} sku={product.sku} handleHideForm={() => setVisible(false)} />
            )}
          </CwsGridCol>
        </CwsGridRow>
      </CwsGrid>
    </CwsSection>
  )
}

export default ProductViewReviews
