import type { FC } from 'react'
import { useTranslate } from '@hooks/useTranslation'
import { InfoRow } from '@components/shared/InfoRow'

export const AppInfo: FC = () => {
  const { translate } = useTranslate()
  const secondaryInfo = translate('value.ribbon.text2').trim()
  const primaryInfo = translate('value.ribbon.text1').trim()

  if (primaryInfo === 'value.ribbon.text1' || !primaryInfo) {
    return null
  }

  return <InfoRow variant="info" primaryInfo={primaryInfo} secondaryInfo={secondaryInfo} />
}
