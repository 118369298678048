import React from 'react'
import { CwsSpan, CwsP, CwsIcon, CwsA, CwsGrid, CwsGridRow, CwsGridCol, CwsSection, CwsTag } from '@components/cws'
import type { FC, HTMLAttributes } from 'react'
import s from './PriceLine.module.scss'
import type { Color } from '@ui/system'
import { useCurrency } from '@hooks/useCurrency'
import { getFormattedPrice } from '@utils/price'
import { useTranslate } from '@hooks/useTranslation'

type StyleKey = keyof typeof s
export interface PriceLineProps extends HTMLAttributes<HTMLDivElement> {
  label: string
  value: number
  dotted?: boolean
  size?: 'sm' | 'md'
  labelClassName?: string
  labelColor?: string
  valueClassName?: string
  valueColor?: Color
  withRemoveIcon?: boolean
  removeHandler?: () => void
  coPoints?: boolean
  coupon?: string
  discountValue?: string
}

export const PriceLine: FC<PriceLineProps> = (props) => {
  const {
    label,
    value,
    dotted = false,
    size = 'md',
    labelClassName,
    valueClassName,
    className,
    valueColor = 'midnight',
    withRemoveIcon = false,
    removeHandler,
    labelColor = 'midnight',
    coPoints,
    coupon,
    discountValue,
  } = props

  const { currency } = useCurrency()
  const { translate } = useTranslate()
  return (
    <CwsGridRow noGutter justifyContent="space-between">
      <CwsGridCol col="auto">
        <CwsP hideSpacing size="small">
          {label}
          {coupon && <CwsSpan className="border-dotted border-midnight border-b-2">{coupon}</CwsSpan>}
        </CwsP>
      </CwsGridCol>

      <CwsGridCol col="auto">
        <CwsGridRow noGutter>
          <CwsGridCol col="auto">
            <CwsP hideSpacing weight="500" color={valueColor} className="flex items-center">
              <span data-testid="product-price">
                {coPoints
                  ? `${value} ${translate('profile.details.clubone.info.pts')}`
                  : getFormattedPrice(value, currency)}
              </span>
              {withRemoveIcon && (
                <CwsA textAlign="right" color="error" hideArrow onClick={removeHandler} withIcon size="small">
                  <CwsIcon className="cws-ml-6" icon="bin" size="16px" color="error" />
                </CwsA>
              )}
            </CwsP>
          </CwsGridCol>
          {discountValue && (
            <CwsGridCol col="auto" className="cws-pl-12">
              <CwsTag type="campaign" className="cws-mb-xs">
                {discountValue}
              </CwsTag>
              {/*<Badge>{discountValue}</Badge>*/}
            </CwsGridCol>
          )}
        </CwsGridRow>
      </CwsGridCol>
    </CwsGridRow>
  )
}

export default PriceLine
