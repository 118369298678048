import type { Currency } from '@model/product/PriceInfo'

export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID

// https://github.com/vercel/next.js/tree/canary/examples/with-facebook-pixel

export const pageview = () => {
  if ('fbq' in window) {
    // @ts-ignore
    window.fbq('track', 'PageView')
  }
}

type EventName = 'ViewContent' | 'AddToWishlist' | 'AddToCart' | 'Search'
interface TrackItem {
  value: number
  currency: Currency
  content_name?: string
  content_type?: string
  content_ids?: any[]
  content_category?: string
  language: string
  eventIdPostfix: string
  contents?: any[]
}

interface SearchData {
  term: string
  eventIdPostfix: string
}

const trackWithEventAndItem = (name: EventName) => (item: TrackItem | SearchData) => {
  if ('fbq' in window) {
    window.fbq('track', name, item, { eventID: `${name}.${item.eventIdPostfix}` })
  }
}

export const view = trackWithEventAndItem('ViewContent')
export const addToWishList = trackWithEventAndItem('AddToWishlist')
export const addToCart = trackWithEventAndItem('AddToCart')
export const search = trackWithEventAndItem('Search')

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name: string, options = {}) => {
  if ('fbq' in window) {
    // @ts-ignore
    window.fbq('track', name, options)
  }
}
