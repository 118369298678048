import type { RefObject } from 'react'
import { useEffect, useRef } from 'react'

type NativeEvent = MouseEvent | TouchEvent

export type ClickAwayHandler = (event: NativeEvent) => any

export function useClickAway(ref: RefObject<HTMLElement | null>, onClickAway: ClickAwayHandler) {
  const callback = useRef(onClickAway)

  useEffect(() => {
    callback.current = onClickAway
  }, [onClickAway])

  useEffect(() => {
    const handler = (event: NativeEvent) => {
      const el = ref?.current

      if (el && (event.target as Node) && !el.contains(event.target as Node)) {
        callback.current?.(event)
      }
    }

    document.addEventListener('mousedown', handler)
    document.addEventListener('touchstart', handler)

    return () => {
      document.removeEventListener('mousedown', handler)
      document.removeEventListener('touchstart', handler)
    }
  }, [ref])
}
