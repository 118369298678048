import winston from 'winston'
import { logFormat } from '@utils/date'

winston.addColors({
  silly: 'blue',
  debug: 'blue',
  error: 'crimson',
})

const consoleTransport = new winston.transports.Console()

export const logger = winston.createLogger({
  format: winston.format.json({ maximumDepth: 5 }),
  defaultMeta: { service: 'W2', time: logFormat.format(Date.now()) },
  level: process.env.LOG_LEVEL || 'info',
  transports: [consoleTransport],
})

export default logger

export const cliLogger = winston.createLogger({
  format: winston.format.cli(),
  level: process.env.LOG_LEVEL || 'info',
  transports: [consoleTransport],
})

export const logKKBody = (body: Record<string, any>) => {
  logger.debug('KK REQUEST --------------')
  logger.debug('body', { body })
  logger.debug('KK REQUEST END --------------')
}
