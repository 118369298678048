import { FC } from 'react'

export const Search: FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.077 6.86c0-1.186-.421-2.2-1.264-3.043S7.955 2.552 6.769 2.552s-2.2.422-3.043 1.265S2.462 5.674 2.462 6.86c0 1.186.421 2.2 1.264 3.043s1.857 1.265 3.043 1.265c1.186 0 2.2-.422 3.044-1.265.842-.843 1.264-1.857 1.264-3.043zm4.923 8c0 .333-.122.622-.365.865a1.183 1.183 0 0 1-.866.366c-.346 0-.634-.122-.865-.366l-3.298-3.288a6.585 6.585 0 0 1-3.837 1.192 6.651 6.651 0 0 1-2.63-.533 6.767 6.767 0 0 1-2.163-1.443A6.768 6.768 0 0 1 .534 9.49 6.652 6.652 0 0 1 0 6.86c0-.917.178-1.793.534-2.63a6.768 6.768 0 0 1 1.442-2.163A6.768 6.768 0 0 1 4.139.624 6.652 6.652 0 0 1 6.77.091c.917 0 1.793.178 2.63.533a6.768 6.768 0 0 1 2.164 1.443 6.768 6.768 0 0 1 1.442 2.163c.356.837.534 1.713.534 2.63 0 1.41-.398 2.69-1.193 3.837l3.298 3.298c.237.237.356.525.356.865z"
        fill="#004152"
      />
    </svg>
  )
}

export default Search
