import type { ChangeEvent, FC } from 'react'
import React, { useCallback, useEffect, useMemo } from 'react'
import { CwsA, CwsAlert, CwsGridCol, CwsGridRow, CwsInput, CwsP } from '@components/cws'
import { useTranslate } from '@hooks/useTranslation'
import * as R from 'ramda'
import { CART_STATE_KEY, useCartState } from '@components/cart/State/state'
import { useAppState } from '@context/state'
import clsx from 'clsx'
import { getCheckZipUrlForLocale } from '@utils/delivery'
import { useRouter } from 'next/router'
import type { Locale } from '@model/locales'
import { zipValidators } from '@utils/order'
import { CartState } from '@components/cart/State/reducer'
import { localStorageHelper } from '@utils/localStorageHelper'
import useSWR from 'swr'
import axios, { AxiosResponse } from 'axios'
import { PickupPoint, PickupPointRequest } from '@api/ecw/models/PickupPoint'
import { COUNTRY_ISO2 } from '@model/locales'

export interface HomeDeliveryData {
  streetAddress: string
  city: string
  zip: string
  allFilled: boolean
}

export const emptyHomeDeliveryData: HomeDeliveryData = {
  streetAddress: '',
  city: '',
  zip: '',
  allFilled: false,
}

interface HomeDeliveryProps {
  withoutZip?: boolean
  fromReturn?: boolean
  zipListTallinkTakso?: string[]
}

export const HomeDelivery: FC<HomeDeliveryProps> = ({
  withoutZip = false,
  fromReturn = false,
  zipListTallinkTakso,
}) => {
  const { translate } = useTranslate()
  const {
    state: { customer },
  } = useAppState()

  const { locale } = useRouter()

  const {
    dispatch,
    state: { order, homeDeliveryAddress, deliveryCountry, deliveryMethodsByCountry },
  } = useCartState()
  const hasAlcohol = useMemo(() => !!order?.orderProducts.find((item) => item.custom2), [order])

  const setData = useCallback(
    (val: HomeDeliveryData) => dispatch({ type: 'setHomeDeliveryData', payload: val }),
    [dispatch]
  )
  const carrierCode = useMemo(
    () => (deliveryCountry === 'Finland' ? deliveryMethodsByCountry['Finland']?.konakartCarrierCode : null),
    [deliveryCountry, deliveryMethodsByCountry]
  )

  const zipPattern = useMemo(() => {
    return zipValidators[deliveryCountry]
  }, [deliveryCountry])

  const validator = R.allPass([
    R.propSatisfies((s = '') => s.length > 3, 'streetAddress'),
    R.propSatisfies((s = '') => s.length > 3, 'city'),
    R.propSatisfies((zip = '') => zip.match(zipPattern), 'zip'),
  ])

  const zipCodeInvalid = useMemo(() => {
    if (zipListTallinkTakso) {
      return (
        !Boolean(homeDeliveryAddress?.zip?.match(zipPattern)) ||
        !zipListTallinkTakso.find((zip) => homeDeliveryAddress?.zip === zip)
      )
    }
    return !Boolean(homeDeliveryAddress?.zip?.match(zipPattern))
  }, [homeDeliveryAddress?.zip, zipPattern, deliveryCountry])

  const setField = useCallback(
    (name: keyof HomeDeliveryData) => (e: ChangeEvent<HTMLInputElement>) => {
      const result = {
        ...(homeDeliveryAddress || {}),
        [name]: e.target.value || '',
      }
      setData({
        ...result,
        allFilled: !zipCodeInvalid ? validator(result) : false,
      } as HomeDeliveryData)
    },
    [setData, validator, homeDeliveryAddress]
  )

  const cartSateHistory: CartState = localStorageHelper.get(CART_STATE_KEY)

  useEffect(() => {
    if (cartSateHistory) return
    if (
      customer &&
      customer.defaultAddrId &&
      order &&
      order.customerAddrId === customer.defaultAddrId &&
      !homeDeliveryAddress
    ) {
      setData({
        streetAddress: order.deliveryStreetAddress,
        city: order.deliveryCity,
        zip: order.deliveryPostcode,
        allFilled: true,
      })
    } else {
      setData({
        streetAddress: '',
        city: '',
        zip: '',
        allFilled: false,
      })
    }
  }, [customer, order, setData])
  useEffect(() => {
    if (zipCodeInvalid && deliveryCountry === 'Finland') {
      setData({ ...(homeDeliveryAddress ?? ({} as HomeDeliveryData)), allFilled: false })
    } else {
      setData({
        ...(homeDeliveryAddress ?? ({} as HomeDeliveryData)),
        allFilled: !zipCodeInvalid ? validator(homeDeliveryAddress) : false,
      })
    }
  }, [zipCodeInvalid, deliveryCountry])
  useEffect(() => {
    return () => {
      if (fromReturn) {
        setData({
          streetAddress: '',
          city: '',
          zip: '',
          allFilled: false,
        })
      }
    }
  }, [])
  return (
    <CwsGridRow>
      <CwsGridCol col="12" className={clsx(withoutZip && 'mt-4')}>
        <CwsGridRow noGutter>
          <CwsGridCol col="6" className="cws-pb-24-lg">
            <CwsInput
              id="home-delivery-address"
              label={translate('cart.confirmation.address')}
              onChange={setField('streetAddress')}
              value={homeDeliveryAddress?.streetAddress}
            />
            <CwsAlert variant="inline" type="important">
              <CwsP size="small">{translate('basket.shipping.address.street.hint')}</CwsP>
            </CwsAlert>
          </CwsGridCol>
        </CwsGridRow>
        <CwsGridRow verticalAlign="center">
          <CwsGridCol col={withoutZip ? 6 : 4}>
            <CwsInput
              id="home-delivery-city"
              label={translate('basket.shipping.address.city')}
              onChange={setField('city')}
              value={homeDeliveryAddress?.city}
            />
          </CwsGridCol>
          {!withoutZip && (
            <>
              <CwsGridCol col="fluid">
                <CwsInput
                  id="home-delivery-zip"
                  name="zip"
                  label={translate('basket.shipping.address.zip')}
                  onChange={setField('zip')}
                  value={homeDeliveryAddress?.zip}
                  invalid={zipCodeInvalid}
                />
              </CwsGridCol>
              <CwsGridCol col="4" className="cws-mb-8-lg">
                <CwsA
                  color="buoy-link"
                  href={getCheckZipUrlForLocale(locale as Locale)}
                  onClick={() => ({})}
                  target="_blank"
                  className=""
                >
                  {translate('basket.shipping.find.zip.code.link')}
                </CwsA>
              </CwsGridCol>
            </>
          )}
        </CwsGridRow>
        <div className="py-2"></div>
      </CwsGridCol>
    </CwsGridRow>
  )
}

export default HomeDelivery
