import { CwsAlert, CwsGridCol, CwsGridRow, CwsInput, CwsP } from '@components/cws'
import { useTranslate } from '@hooks/useTranslation'
import type { ChangeEvent, FC } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import * as R from 'ramda'
import { CART_STATE_KEY, deliveryContactDataFromCustomer, useCartState } from '@components/cart/State/state'
import type { Customer } from '@model/customer/Customer'
import type { SetContactData, SetGiftRecipientContactData } from '@components/cart/State/reducer'
import { validations } from '@utils/order'
import type { CountryName } from '@model/locales'
import { getTelephoneSchema } from '@utils/validations/contactData'
import { CartState } from '@components/cart/State/reducer'
import { localStorageHelper } from '@utils/localStorageHelper'
import { useRouter } from 'next/router'
import { FormAlert } from '@ui/components/FormAlert'

export interface DeliveryContactData {
  firstName: string
  lastName: string
  email: string
  telephone: string
  allFilled: boolean
  emailValid?: boolean
  telephoneValid?: boolean
}

export const emptyData: DeliveryContactData = {
  firstName: '',
  lastName: '',
  email: '',
  telephone: '',
  allFilled: false,
}

const telPrefixes: Record<CountryName, string> = {
  Estonia: '+372',
  Finland: '+358',
  Sweden: '+46',
  Latvia: '+371',
  Lithuania: '+370',
}

const telPrefixesLocale: Record<string, string> = {
  et: '+372',
  fi: '+358',
  sv: '+46',
  lv: '+371',
  lt: '+370',
  ru: '+372',
  en: '+372',
}

interface ContactDataProps {
  actionType: SetContactData['type'] | SetGiftRecipientContactData['type']
  customer: Customer | null
}

export const ContactData: FC<ContactDataProps> = ({ customer, actionType }) => {
  const { translate } = useTranslate()
  const {
    dispatch,
    state: { giftRecipientContactData, deliveryContactData, deliveryCountry, deliveryMethodsByCountry },
  } = useCartState()
  const { locale } = useRouter()

  const [isTelephoneInvalid, setIsTelephoneInvalid] = useState<boolean>(false)
  const selectedDeliveryMethodCode = useMemo(
    () => deliveryMethodsByCountry[deliveryCountry]?.konakartCarrierCode,
    [deliveryMethodsByCountry, deliveryCountry]
  )
  const cartSateHistory: CartState = localStorageHelper.get(CART_STATE_KEY)

  const shouldValidatePhoneNrEtLv = useMemo(
    () => selectedDeliveryMethodCode && ['OMNIVASP', 'OMNIVASPLV'].includes(selectedDeliveryMethodCode),
    [selectedDeliveryMethodCode]
  )

  const [data, setData] = useState<DeliveryContactData>(emptyData)

  // const currentTelPrefix = useMemo(() => telPrefixes[deliveryCountry], [deliveryCountry])
  const currentTelPrefix = useMemo(() => telPrefixesLocale[locale ?? 'et'], [locale])

  const validator = R.allPass([
    R.propSatisfies((s) => s.length > 1, 'firstName'),
    R.propSatisfies((s) => s.length > 1, 'lastName'),
    R.propSatisfies((email) => email.match(validations.email), 'email'),
    R.propSatisfies((tel) => {
      if (shouldValidatePhoneNrEtLv && selectedDeliveryMethodCode === 'OMNIVASPLV') {
        return tel.match(validations.lvPhone)
      } else if (shouldValidatePhoneNrEtLv && selectedDeliveryMethodCode === 'OMNIVASP') {
        return tel.match(validations.etPhone)
      } else {
        return tel.match(validations.telephone)
      }
    }, 'telephone'),
  ])

  const checkPhoneValidation = async (pattern: RegExp, value?: string) => {
    const valid = await getTelephoneSchema(pattern).isValid(data.telephone)
    setIsTelephoneInvalid(!!value && !valid)
    // setData((prev) => ({ ...prev, telephoneValid: !!value && !valid }))
  }

  useEffect(() => {
    let pattern = validations.telephone
    if (shouldValidatePhoneNrEtLv && selectedDeliveryMethodCode === 'OMNIVASPLV') {
      pattern = validations.lvPhone
    }

    if (shouldValidatePhoneNrEtLv && selectedDeliveryMethodCode === 'OMNIVASP') {
      pattern = validations.etPhone
    }
    checkPhoneValidation(pattern, data.telephone).then()
  }, [data, shouldValidatePhoneNrEtLv, selectedDeliveryMethodCode])

  const setField = useCallback(
    (name: keyof DeliveryContactData) => (e: ChangeEvent<HTMLInputElement>) => {
      const { value = '' } = e.target
      return setData((prev) => {
        const result = {
          ...prev,
          [name]: value,
        }
        return { ...result, allFilled: validator(result) }
      })
    },
    [setData, validator]
  )
  useEffect(() => {
    if (actionType === 'fillContactData') {
      if (cartSateHistory?.deliveryContactData.allFilled) {
        setData({
          ...cartSateHistory.deliveryContactData,
          lastName: deliveryContactDataFromCustomer(customer).lastName || cartSateHistory.deliveryContactData.lastName,
          firstName:
            deliveryContactDataFromCustomer(customer).firstName || cartSateHistory.deliveryContactData.firstName,
        })
      } else {
        setData(deliveryContactDataFromCustomer(customer))
      }
    }
    if (actionType === 'fillGiftRecipientData') {
      if (cartSateHistory.giftRecipientContactData.allFilled) {
        setData(cartSateHistory.giftRecipientContactData)
      } else {
        setData(emptyData)
      }
    }
  }, [customer])
  const invalidEmail = !data.email.match(validations.email) || !data.email
  useEffect(() => {
    dispatch({ type: actionType, payload: { ...data, telephoneValid: !isTelephoneInvalid, emailValid: !invalidEmail } })
    // if (data.allFilled) {
    //   dispatch({ type: actionType, payload: data })
    // } else {
    //   dispatch({ type: actionType, payload: emptyData })
    // }
  }, [data, dispatch, actionType, isTelephoneInvalid])
  const isChanged = [data.firstName, data.lastName, data.telephone, data.email].some(Boolean)
  return (
    <>
      <CwsGridRow>
        {actionType === 'fillGiftRecipientData' && (
          <>
            <CwsGridCol col="12">
              <CwsP>{translate('cart.send.gift.recipient.tooltip')}</CwsP>
            </CwsGridCol>
            {!data.allFilled && isChanged && (
              <CwsGridCol col="12">
                <FormAlert className="cws-pb-4 cws-pt-0" value={translate('w2.send.all.filled')} type="error" />
              </CwsGridCol>
            )}
          </>
        )}

        <CwsGridCol col="12" sm="12" md="12" lg="5">
          <CwsInput
            id={`firstName-${actionType}`}
            label={translate('profile.details.firstname')}
            onChange={setField('firstName')}
            value={data.firstName}
            disabled={customer}
            invalid={!data.firstName && isChanged}
          />
          <div className="py-2"></div>
          <CwsInput
            id={`lastName-${actionType}`}
            label={translate('profile.details.lastname')}
            onChange={setField('lastName')}
            value={data.lastName}
            disabled={customer}
            invalid={!data.lastName && isChanged}
          />
          <div className="py-2"></div>
          <CwsInput
            id={`email-${actionType}`}
            type="email"
            label={translate('template.orderconfpaymentsuccess.email')}
            onChange={setField('email')}
            invalid={invalidEmail && isChanged}
            value={data.email}
          />
          {(!data.email.match(validations.email) || !data.email) && isChanged && (
            <FormAlert value={translate('w2.send.email.error')} type="error" />
          )}

          <div className="py-2"></div>
          <CwsInput
            id={`phone-${actionType}`}
            type="tel"
            label={translate('w2.person.contact.data.telephone')}
            onChange={setField('telephone')}
            value={data.telephone}
            invalid={(isTelephoneInvalid || !data.telephone) && isChanged}
            placeholder={currentTelPrefix}
          />
          {isTelephoneInvalid && (
            <CwsP className={'cws-text--color-error'} color="color-error">
              {translate('w2.delivery.validation.phone', { phone: data.telephone })}
            </CwsP>
          )}
          {shouldValidatePhoneNrEtLv && (
            <CwsAlert variant="inline" type={isTelephoneInvalid ? 'error' : 'info'}>
              <CwsP size="small">{translate('message.field.phone.required.omniva')}</CwsP>
            </CwsAlert>
          )}
        </CwsGridCol>
        <CwsGridCol col="6" sm="fluid" md="12" lg="6"></CwsGridCol>
      </CwsGridRow>
    </>
  )
}
