import type { AppIdRoute } from './types'

export interface IAppRoutes {
  productDetails: AppIdRoute
}

export const AppRoutes: IAppRoutes = {
  productDetails: { name: '/product', get: (slugWithSku) => `/product/${slugWithSku}` },
}

export type AppRouteName = keyof typeof AppRoutes
