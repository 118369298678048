import { useEffect, useState, VFC } from 'react'
import {
  CwsHeaderLanguageSelector,
  CwsHeaderLanguageSelectorItem,
  CwsHeaderLocaleSelector,
  CwsHeaderLocale,
  CwsSelect,
  CwsSelectOption,
  CwsHeaderLocaleSelectorItem,
  CwsP,
  CwsA,
} from '@components/cws'
import { Locale } from '@model/locales'
import { useTranslate } from '@hooks/useTranslation'
import { useRouter } from 'next/router'

interface LanguagePickerProps {
  selectedLanguageCode: string
  onSelectLanguage: (locale: Locale) => void
}

export const LanguageSelector: VFC<LanguagePickerProps> = ({ selectedLanguageCode, onSelectLanguage }) => {
  const { translate } = useTranslate()
  const { locale } = useRouter()

  const [selectorOpen, setSelectorOpen] = useState(false)

  useEffect(() => {
    setSelectorOpen(false)
  }, [locale])

  return (
    <CwsHeaderLocale>
      <CwsHeaderLocaleSelector mode="language" label={`${translate('nav.btn.language')}:`}>
        <CwsHeaderLocaleSelectorItem active={selectedLanguageCode === 'et'}>
          <CwsA onClick={() => onSelectLanguage('et')} hideArrow href="#">
            Eesti keel
          </CwsA>
        </CwsHeaderLocaleSelectorItem>
        <CwsHeaderLocaleSelectorItem active={selectedLanguageCode === 'fi'}>
          <CwsA onClick={() => onSelectLanguage('fi')} hideArrow href="#">
            Suomi
          </CwsA>
        </CwsHeaderLocaleSelectorItem>
        <CwsHeaderLocaleSelectorItem active={selectedLanguageCode === 'sv'}>
          <CwsA onClick={() => onSelectLanguage('sv')} hideArrow href="#">
            Svenska
          </CwsA>
        </CwsHeaderLocaleSelectorItem>
        <CwsHeaderLocaleSelectorItem active={selectedLanguageCode === 'ru'}>
          <CwsA onClick={() => onSelectLanguage('ru')} hideArrow href="#">
            Русский
          </CwsA>
        </CwsHeaderLocaleSelectorItem>
        <CwsHeaderLocaleSelectorItem active={selectedLanguageCode === 'en'}>
          <CwsA onClick={() => onSelectLanguage('en')} hideArrow href="#">
            English
          </CwsA>
        </CwsHeaderLocaleSelectorItem>
        <CwsHeaderLocaleSelectorItem active={selectedLanguageCode === 'lv'}>
          <CwsA onClick={() => onSelectLanguage('lv')} hideArrow href="#">
            Latvijas
          </CwsA>
        </CwsHeaderLocaleSelectorItem>
        <CwsHeaderLocaleSelectorItem active={selectedLanguageCode === 'lt'}>
          <CwsA onClick={() => onSelectLanguage('lt')} hideArrow href="#">
            Lietuvių
          </CwsA>
        </CwsHeaderLocaleSelectorItem>
      </CwsHeaderLocaleSelector>
    </CwsHeaderLocale>
  )
}
