import { Button } from '@ui/components'
import {
  CwsP,
  CwsH3,
  CwsAccordion,
  CwsAccordionItem,
  CwsAccordionItemHeader,
  CwsAccordionItemBody,
} from '@components/cws'
import { ButtonArrow } from '@ui/icons/button'
import * as R from 'ramda'
import { safeHtml } from '@utils/html'
import clsx from 'clsx'
import type { FC } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import s from './ProductViewDescription.module.scss'
import { useTranslate } from '@hooks/useTranslation'
import { always, tryCatch } from 'ramda'
import { useRouter } from 'next/router'

export interface ProductViewDescriptionProps {
  text: string
  html?: string
  inTab?: boolean
}

interface ProductDescriptionObject {
  description: string
  ingredients: string
  nutrition: string
}

const ProductViewDescription: FC<ProductViewDescriptionProps> = (props) => {
  const { html, inTab } = props

  let descriptionObject: ProductDescriptionObject | null = null
  const { locale, query } = useRouter()

  if (html?.startsWith('{')) {
    descriptionObject = tryCatch((json: string) => JSON.parse(json), always(null))(html)
  }

  const { translate } = useTranslate()
  const [isExpanded, setIsExpanded] = useState<boolean | null>(null)
  const textBodyRef = useRef<HTMLDivElement>(null)

  useEffect(() => setIsExpanded(null), [html, setIsExpanded])

  const checkCollapse = () => {
    if (!textBodyRef?.current) {
      return
    }

    if (textBodyRef.current.offsetHeight <= textBodyRef.current.scrollHeight && typeof isExpanded !== 'boolean') {
      setIsExpanded(false)
    }

    if (textBodyRef.current.scrollHeight <= 96) {
      setIsExpanded(null)
    }
  }
  useEffect(() => {
    if (!textBodyRef?.current) {
      return
    }
    if (textBodyRef.current.offsetHeight <= textBodyRef.current.scrollHeight && typeof isExpanded !== 'boolean') {
      setIsExpanded(false)
    }
  }, [textBodyRef?.current, query])

  useEffect(() => {
    window.addEventListener('resize', checkCollapse)

    return () => {
      window.removeEventListener('resize', checkCollapse)
    }
  }, [])
  const safeHtmlStr = html
    ? {
        __html: safeHtml(
          R.type(descriptionObject) !== 'Object' || R.isEmpty(descriptionObject)
            ? html
            : descriptionObject?.description || ''
        ),
      }
    : ''
  useEffect(() => {
    if (!textBodyRef?.current) {
      return
    }

    if (textBodyRef.current.offsetHeight <= textBodyRef.current.scrollHeight && typeof isExpanded === 'boolean') {
      setIsExpanded(false)
    }

    if (textBodyRef.current.scrollHeight <= 96) {
      setIsExpanded(null)
    }
  }, [html, query, locale, textBodyRef?.current])

  const handleButtonClick = useCallback(() => {
    setIsExpanded((prevState) => !prevState)
  }, [])

  if (descriptionObject && R.isEmpty(descriptionObject)) return null

  return (
    <div className="flex flex-col space-y-lg px-lg py-xl device360:px-0">
      {!inTab && <CwsH3>{translate('product.detail.description.detail')}</CwsH3>}
      <div
        ref={textBodyRef}
        className={clsx(s['text-container'], !isExpanded && 'max-h-24', isExpanded && 'max-h-fit')}
      >
        <CwsP size="p" className="text-base leading-5.5" dangerouslySetInnerHTML={safeHtmlStr} />
        {isExpanded === false && <div className={s['edge-shadow']}></div>}
      </div>
      {isExpanded !== null && (
        <Button variant="text" size="sm" className={s.button} onClick={handleButtonClick}>
          <span>{isExpanded ? translate('filters.brandname.show.less') : translate('filters.brandname.show.all')}</span>
          {<ButtonArrow className={clsx(s.arrow, isExpanded && s['arrow-up'])} />}
        </Button>
      )}

      {descriptionObject && !inTab && (
        <>
          <CwsAccordion size="medium">
            <CwsAccordionItem id="product-ingredients" className={clsx(!descriptionObject?.ingredients && 'hidden')}>
              <CwsAccordionItemHeader label={translate('w2.product.details.ingredients.heading')} />
              <CwsAccordionItemBody>
                <CwsP dangerouslySetInnerHTML={{ __html: descriptionObject?.ingredients }} hideSpacing />
              </CwsAccordionItemBody>
            </CwsAccordionItem>
            <CwsAccordionItem id="product-nutrition" className={clsx(!descriptionObject?.nutrition && 'hidden')}>
              <CwsAccordionItemHeader label={translate('w2.product.details.nutrition.heading')} />
              <CwsAccordionItemBody>
                <CwsP dangerouslySetInnerHTML={{ __html: descriptionObject?.nutrition }} hideSpacing />
              </CwsAccordionItemBody>
            </CwsAccordionItem>
          </CwsAccordion>
        </>
      )}
    </div>
  )
}

export default ProductViewDescription
