import type { ChangeEvent, FC } from 'react'
import { useCallback } from 'react'
import s from './Selector.module.scss'
import { useTranslate } from '@hooks/useTranslation'

export type KeyLabelOption = {
  key: string
  label: string
  translationKey?: string
}

export interface SelectorProps {
  options: KeyLabelOption[]
  handleSelect(a: string): void
}

const Selector: FC<SelectorProps> = ({ options, handleSelect }) => {
  const { translate } = useTranslate()

  const renderOption = useCallback(
    (option: KeyLabelOption) => {
      const { key, label, translationKey } = option
      const translatedLabel = translationKey ? translate(translationKey) : label
      return (
        <option value={key} key={key}>
          {translatedLabel}
        </option>
      )
    },
    [translate]
  )

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => handleSelect(event.target.value),
    [handleSelect]
  )

  return (
    <select className={s.box} placeholder="Select style" onChange={handleChange}>
      {options.map(renderOption)}
    </select>
  )
}

export default Selector
