import { FC } from 'react'

export const Filter: FC = () => {
  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.63925 11.6712V8.45108C8.63925 7.95643 8.8162 7.47809 9.13812 7.10252L13.9672 1.46859H2.03281L6.86159 7.10218C7.1835 7.47775 7.36045 7.95608 7.36045 8.45073V12.4828L8.63925 11.6712ZM9.97259 8.45108C9.97259 8.27471 10.0357 8.10415 10.1505 7.97024L15.8207 1.35494C16.2315 0.875665 15.891 0.135254 15.2597 0.135254H0.740275C0.109038 0.135254 -0.231503 0.875666 0.179299 1.35494L5.84924 7.9699C5.96402 8.10381 6.02711 8.27436 6.02711 8.45073V13.5642C6.02711 14.1468 6.66995 14.5002 7.16186 14.188L9.62964 12.6219C9.8432 12.4864 9.97259 12.251 9.97259 11.9981V8.45108Z"
            fill="#004152"
        />
    </svg>
  )
}

export default Filter
