import type { CustomFacetValue } from './CustomFacetValue'

export enum CustomFacetNumber {
  ProductLine = 8,
  Campaign = 9,
  OriginCountry = 10,
  Specification = 11,
  Label = 16,
  Gender = 34,
  Allergy = 15,
  Features = 17,
  Color = 18,
  Spf = 37,
}

export interface CustomFacet {
  name: string
  number: CustomFacetNumber | number
  values: CustomFacetValue[]
}

export function getFacetTitle(facetNumber: CustomFacetNumber | number): string {
  switch (facetNumber) {
    case CustomFacetNumber.Campaign:
      return 'Campaign'
    case CustomFacetNumber.Gender:
      return 'Gender'
    case CustomFacetNumber.Label:
      return 'Label'
    case CustomFacetNumber.OriginCountry:
      return 'Origin Country'
    case CustomFacetNumber.ProductLine:
      return 'Product Line'
    case CustomFacetNumber.Specification:
      return 'Specification'
    case CustomFacetNumber.Allergy:
      return 'Allergy'
    case CustomFacetNumber.Features:
      return 'Features'
    case CustomFacetNumber.Color:
      return 'Color'
    case CustomFacetNumber.Spf:
      return 'Spf'
    default:
      return 'Size'
  }
}
