import type { Product } from '@model/product'
import { KONAKART_HOST } from '@api/apiConfig'

export function extractTitle(product: Product): string {
  const { name, manufacturerName, custom3 } = product

  const base = name.replace(manufacturerName, '')
  const alcoholVol = custom3 ? ` ${custom3}% vol` : ''

  return `${base}${alcoholVol}`
}

export function getFullImageUrl(dest: string, forMeta?: boolean): string {
  let imageHost = ''
  // http://wwtll018d.fleet.zone:28152/images/prod/4/6/5/2/4652DA8E-7C8C-4A5C-8280-137E0B79F8C8_1_big.png

  if (window.envs && window.envs.engineHost) {
    imageHost = window.envs.engineHost
  }
  if (forMeta) {
    imageHost = KONAKART_HOST || window.envs.konakartServer
  }
  const imagesBase = `${imageHost}/images`
  return `${imagesBase}${dest}`
}
