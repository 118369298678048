import type { Product } from '@model/product'
import useSWRImmutable from 'swr/immutable'
import { useRouter } from 'next/router'
import type { LanguageId, Locale } from '@model/locales'
import { KK_LANG_IDS } from '@model/locales'
import axios from 'axios'

export const productByIdFetcher = (languageId: LanguageId, productId: number) => async () => {
  if (!productId) return null

  const { data } = await axios.post(`/api/product/${productId}`, {
    languageId,
  })
  return data
}

export const useProduct = (productId: number): Product | null => {
  const { locale } = useRouter()
  const languageId = KK_LANG_IDS[locale as Locale]
  const key = `product by id ${productId} and lang ${languageId}`
  const { data: product } = useSWRImmutable(key, productByIdFetcher(languageId, productId))

  return product
}
