import { CwsA, CwsHeaderNav, CwsHeaderNavItem } from '@components/cws'
import React, { useEffect, useRef, useState } from 'react'
import { keys } from 'ramda'
import { useRouter } from 'next/router'

export const UpperNavigation: React.FC<{ items: Record<string, string> }> = ({ items }) => {
  const { locale } = useRouter()
  const [navItems, setNavItems] = useState<string[]>([])
  const prevLocale = useRef<string | undefined>()

  useEffect(() => {
    setNavItems(keys(items))
    prevLocale.current = locale
  }, [locale, items])

  if (!navItems.length) return null
  return (
    <>
      {prevLocale.current === locale && navItems.length ? (
        <CwsHeaderNav>
          {navItems.map((key, index, array) => (
            <CwsHeaderNavItem
              divider={index === array.length - 1}
              current={items[key] === '/'}
              key={key}
              href={items[key]}
              label={key}
            >
              <CwsA target="_blank" hideArrow size="small" locale={locale} href={items[key]}>
                {key}
              </CwsA>
            </CwsHeaderNavItem>
          ))}
        </CwsHeaderNav>
      ) : null}
    </>
  )
}
