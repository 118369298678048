import { CwsDivider, CwsH4, CwsTab, CwsTabContent, CwsTabItem, CwsTabContentItem } from '@components/cws'
import type { FC } from 'react'
import useSWR from 'swr'
import { useTranslate } from '@hooks/useTranslation'
import { useRouter } from 'next/router'
import type { Locale } from '@model/locales'
import { COUNTRY_CODES } from '@model/locales'
import type { PaymentMethodsResult } from '@api/checkout/models'
import { OnlineBanksList } from '@components/cart/payment/PaymentMethods/OnlineBanksList'
import HiddenForm from '@components/cart/payment/PaymentMethods/HiddenForm'
import EstoOptions from '@components/cart/payment/PaymentMethods/EstoOptions'
import SwishKlarna from '@components/cart/payment/PaymentMethods/SwishKlarna'
import AdyenPayment from '@components/cart/payment/PaymentMethods/AdyenPayment'
import { isEstonianMarket } from '@utils/order'

export interface PaymentMethodsProps {
  ssoToken?: string
}

export const PaymentMethods: FC<PaymentMethodsProps> = ({ ssoToken }) => {
  const { translate } = useTranslate()
  const { locale } = useRouter()

  let zone = COUNTRY_CODES[locale as Locale]
  if (locale === 'ru' || locale === 'en') zone = COUNTRY_CODES['et']
  if (locale === 'lt') zone = COUNTRY_CODES['lv']

  const { data: methods } = useSWR<PaymentMethodsResult>(`/api/payment/methods?locale=${locale}&zone=${zone}`)
  const localeKey = isEstonianMarket(locale as Locale) ? 'et' : locale

  const nodesByLocale: Record<string, JSX.Element> = {
    et: (
      <>
        <CwsTab activeTabNavItemId="banks-payment" mode="fit-content">
          <CwsTabItem id="banks-payment" label={translate('cart.payment.online.label')} />
          <CwsTabItem id="adyen-payment" label={translate('cart.payment.creditcard.label')} />
          <CwsTabItem id="esto-payment" label={translate('cart.confirmation.payment.esto')} />
        </CwsTab>
        <CwsTabContent>
          <CwsTabContentItem id="banks-payment">
            <OnlineBanksList banks={methods?.banks || []} />
            <HiddenForm />
          </CwsTabContentItem>
          <CwsTabContentItem id="adyen-payment">
            <AdyenPayment ssoToken={ssoToken} />
          </CwsTabContentItem>
          <CwsTabContentItem id="esto-payment">
            <EstoOptions />
          </CwsTabContentItem>
        </CwsTabContent>
      </>
    ),
    sv: (
      <>
        <CwsTab activeTabNavItemId="adyen-payment" mode="fit-content">
          <CwsTabItem id="adyen-payment" label={translate('cart.payment.creditcard.label')} />
          <CwsTabItem id="klarna-payment" label={translate('cart.payment.klarna.label')} />
        </CwsTab>
        <CwsTabContent>
          <CwsTabContentItem id="adyen-payment">
            <AdyenPayment ssoToken={ssoToken} />
          </CwsTabContentItem>
          <CwsTabContentItem id="klarna-payment">
            <SwishKlarna />
          </CwsTabContentItem>
        </CwsTabContent>
      </>
    ),
    fi: (
      <>
        <CwsDivider />
        <AdyenPayment ssoToken={ssoToken} />
      </>
    ),
    lv: (
      <>
        <CwsDivider />
        <AdyenPayment ssoToken={ssoToken} />
      </>
    ),
    lt: (
      <>
        <CwsDivider />
        <AdyenPayment ssoToken={ssoToken} />
      </>
    ),
  }

  return (
    <>
      <CwsH4 textAlign="left">{translate('cart.payment.title')}</CwsH4>
      {localeKey && nodesByLocale[localeKey]}
    </>
  )
}
