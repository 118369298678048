import type { Product } from '@model/product'
import { CwsCarousel, CwsCarouselItem, CwsSection } from '@components/cws'
import type { FC } from 'react'
import { useCallback } from 'react'
import { ProductCard } from '@components/product'
import { useMediaLess } from '@ui/hooks/useMediaLess'
import s from './ProductSlider.module.scss'
import clsx from 'clsx'
import { splitEvery } from 'ramda'
import { MOBILE_BP } from '@app/constants'

export interface ProductSliderProps {
  products: Product[]
  mainPageCollection?: boolean
  listName: string
}

const ProductSlider: FC<ProductSliderProps> = (props) => {
  const { products, listName } = props
  const isMobile = useMediaLess(MOBILE_BP)
  const productsToRender = splitEvery(isMobile ? 1 : 4, products)
  const renderProducts = useCallback((products: Product[]) => {
    const sku = products.map(({ sku }) => sku).join(',')
    return (
      <li key={`recommended-product-${sku}`} className={clsx(s.productGroup, 'snap-center')}>
        {products.map((innerProduct, idx) => {
          const { sku } = innerProduct
          return (
            <div className={s.productCardHolder} key={sku}>
              <ProductCard listName="recommended-product" product={innerProduct} className="w-full" index={idx + 1} />
            </div>
          )
        })}
      </li>
    )
  }, [])

  const sliderSize: { [keys: string]: string } = {
    1: s.carousel_for_one,
    2: s.carousel_for_twe,
    3: s.carousel_for_free,
  }

  return (
    <CwsCarousel mode={'card'} className={clsx(sliderSize[`${products.length}`])}>
      {products.map((innerProduct, idx) => {
        const sku = products.map(({ sku }) => sku).join(',')

        return (
          !Array.isArray(innerProduct) && (
            <CwsCarouselItem key={sku}>
              <ProductCard listName={listName} product={innerProduct} className={s.sliderCard} index={idx + 1} />
            </CwsCarouselItem>
          )
        )
      })}
    </CwsCarousel>
  )
}

export default ProductSlider
