import type { FC } from 'react'
import { CwsGridCol, CwsGridRow, CwsP } from '@components/cws'
import KlarnaIcon from '@components/cart/payment/PaymentMethods/assets/klarna.svg'
import SwishIcon from '@components/cart/payment/PaymentMethods/assets/swish.svg'
import { useTranslate } from '@hooks/useTranslation'
import { useCartState } from '@components/cart/State/state'
import { useEffect } from 'react'

export const SwishKlarna: FC = () => {
  const { translate } = useTranslate()
  const { dispatch } = useCartState()

  useEffect(() => {
    dispatch({ type: 'setPaymentMethod', payload: 'klarna' })

    return () => dispatch({ type: 'setPaymentMethod', payload: 'dibs' })
  }, [dispatch])

  return (
    <>
      <CwsGridRow noGutter verticalAlign="center">
        <CwsGridCol col="2">
          <KlarnaIcon height="60px" />
        </CwsGridCol>
        <CwsGridCol col="2">
          <SwishIcon height="30px" width="100px" />
        </CwsGridCol>
      </CwsGridRow>

      <CwsP dangerouslySetInnerHTML={{ __html: translate('cart.payment.klarna.help') }}></CwsP>
    </>
  )
}

export default SwishKlarna
