import { FC, SVGProps } from 'react'
import clsx from 'clsx'
import ChevronSvg from './assets/Chevron.svg'

export type ChevronDirection = 'up' | 'down' | 'left' | 'right'

export interface ChevronProps extends SVGProps<SVGSVGElement> {
  direction: ChevronDirection
}

export const Chevron: FC<ChevronProps> = (props) => {
  const { direction, className, ...svgProps } = props

  return (
    <ChevronSvg
      {...svgProps}
      className={clsx(
        direction === 'down' && 'transform rotate-180',
        className,
        direction === 'right' && 'transform rotate-90',
        direction === 'left' && 'transform -rotate-90'
      )}
    />
  )
}

export default Chevron
