import { useRouter } from 'next/router'
import { useCurrency } from '@hooks/useCurrency'
import type { Dispatch, SetStateAction } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import type { Locale } from '@model/locales'
import { KK_LANG_IDS } from '@model/locales'
import type { AxiosResponse } from 'axios'
import axios from 'axios'
import type { CouponCheckInput } from '@pages/api/check-gift-card'
import type { ErrorCouponCheckResult, SuccessCouponCheckResult } from '@api/misc'
import * as R from 'ramda'
import { useCartState } from '@components/cart/State/state'
import { getAllTotalsByClassName, getPromotionsCouponCode } from '@utils/order'
import { useTranslate } from '@hooks/useTranslation'

export const useCouponCheck = (promoValue: string, setPromoValue: Dispatch<SetStateAction<string>>) => {
  const { locale } = useRouter()
  const languageId = KK_LANG_IDS[locale as Locale]
  const { currency } = useCurrency()
  const { translate } = useTranslate()

  const [isChecking, setIsChecking] = useState(false)
  const [couponCheckError, setCouponCheckError] = useState<string | null>()
  const {
    dispatch,
    state: { order, discountCoupons },
  } = useCartState()

  // const otGetter = useMemo(() => getOrderTotalByClassName(order?.orderTotals || []), [order])
  const otGetterAll = useMemo(() => getAllTotalsByClassName(order?.orderTotals || []), [order?.orderTotals])

  useEffect(() => {
    const currentOrderTotals = otGetterAll(
      'ot_df_global_product_discount',
      'ot_df_product_discount',
      'ot_df_total_discount',
      'ot_df_shipping_discount'
    )

    if (!promoValue) return

    const simplePromoValues = currentOrderTotals.map((ot) => getPromotionsCouponCode(ot) || ot?.custom3)

    if (simplePromoValues.includes(promoValue)) {
      setCouponCheckError(null)
      setPromoValue('')
    }
  }, [otGetterAll, setCouponCheckError, promoValue, dispatch, setPromoValue])

  const doCheckCoupon = useCallback(() => {
    setIsChecking(true)
    setCouponCheckError(null)
    axios
      .post<CouponCheckInput, AxiosResponse<ErrorCouponCheckResult | SuccessCouponCheckResult>>(
        '/api/check-gift-card',
        {
          code: promoValue,
          currency,
          languageId,
        }
      )
      .then(({ data }) => {
        if (data.status === 'OK' && 'balance' in data) {
          dispatch({ type: 'addCoupon', payload: { coupon: data.coupon, balance: data.balance } })
          setPromoValue('')
        }
      })
      .catch((error) => {
        if (error && axios.isAxiosError(error)) {
          const errorResponse = R.path(['response', 'data'])(error) as ErrorCouponCheckResult
          // setCouponCheckError(errorResponse?.errorMessage)
          if (errorResponse.errorCode === 'NOT_FOUND') {
            const currentOrderTotals = otGetterAll(
              'ot_df_global_product_discount',
              'ot_df_product_discount',
              'ot_df_total_discount',
              'ot_df_shipping_discount'
            )

            const simplePromoValues = R.uniq(currentOrderTotals.map((ot) => getPromotionsCouponCode(ot) || ot?.custom3))

            if (simplePromoValues.length >= 1) {
              setCouponCheckError(translate('cart.promo.code.error.maxreached'))
            } else {
              dispatch({ type: 'addDiscountCoupon', payload: promoValue })
            }
            return
          }
          setCouponCheckError(translate('cart.promo.code.error.invalid'))
        }
      })
      .finally(() => setIsChecking(false))
  }, [promoValue, currency, languageId, dispatch, setPromoValue, translate, otGetterAll])

  return {
    isChecking,
    doCheckCoupon,
    couponCheckError,
  }
}
