import type { Dispatch, FC, SetStateAction } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { CwsIcon, CwsP, CwsTab, CwsTabContent, CwsTabContentItem, CwsTabItem } from '@components/cws'
import s from '@components/product/ProductViewParameters/ProductViewParameters.module.scss'
import clsx from 'clsx'
import type { SelectedProductValue } from '@components/product/BundleOptions/BundleOptions'
import { BundleOptions } from '@components/product/BundleOptions/BundleOptions'
import { useTranslate } from '@hooks/useTranslation'
import type { Product } from '@model/product'
import type { ItemsQuantityState } from '@hooks/useMultipleItemsQuantity'
import { isGloballyPricelist } from '@utils/pricelist-utils/app-properties'
import moduleStyles from './BundleAndBulk.module.scss'
import SingleTabContent from '@components/product/ProductViewParameters/BundleAndBulk/SingleTabContent'
import type { BundleTypeTab } from '@components/product/ProductViewParameters/ProductViewParameters'
import { localStorageHelper } from '@utils/localStorageHelper'
import { range } from 'ramda'
import { useRouter } from 'next/router'
const SELECTED_MAP_HISTORY = 'SELECTED_MAP_HISTORY'
interface BundleAndBulkProps {
  product: Product
  itemsQuantityState: ItemsQuantityState
  handleQuantityUpdate: (value: number) => void
  itemQuantityCallback(sku: string, value: number): void
  productQuantity: number
  bundleTypeTab: BundleTypeTab
  setTab: Dispatch<SetStateAction<BundleTypeTab>>
  reset: () => void
}

export const BundleAndBulk: FC<BundleAndBulkProps> = ({
  product,
  itemQuantityCallback,
  itemsQuantityState,
  handleQuantityUpdate,
  productQuantity,
  setTab,
  bundleTypeTab,
  reset,
}) => {
  const { translate } = useTranslate()
  const { priceInfo } = product
  const maxQuantity = product.quantity
  const { query, asPath, ...router } = useRouter()
  const { bundleIncludedProducts } = priceInfo
  const onlyBulk = bundleIncludedProducts?.length === 0

  const bundleIncludedProductsIsStock = useMemo(
    () => bundleIncludedProducts?.filter(({ quantity }, index) => !!quantity || index === 0),
    [bundleIncludedProducts]
  )

  const [unUsedProducts, setUnUsedProducts] = useState<Product[]>([])

  useEffect(() => {
    setUnUsedProducts(bundleIncludedProductsIsStock?.filter((item) => item.id !== product.id) || [])
  }, [setUnUsedProducts, bundleIncludedProductsIsStock, product, bundleTypeTab])

  const bundleQuantity =
    (bundleIncludedProductsIsStock?.length ?? 1) < priceInfo.bundleQuantity
      ? bundleIncludedProductsIsStock?.length ?? 1
      : priceInfo.bundleQuantity
  const initialProduct = range(0, bundleQuantity).reduce((acc, val) => {
    return { ...acc, [val]: val === 0 ? { product, quantity: 1 } : {} }
  }, {})
  const [selectedMap, setSelectedMap] = useState<Record<number, SelectedProductValue>>({
    ...initialProduct,
  })
  useEffect(() => {
    setSelectedMap({ ...initialProduct })
  }, [asPath])
  // useEffect(() => {
  //   if (!bundleIncludedProducts?.length) return
  //
  //   const newState = bundleIncludedProducts.reduce(
  //     (acc, val) => ({
  //       ...acc,
  //       ...(itemsQuantityState[val.sku] && {
  //         [val.sku]: {
  //           product: val,
  //           quantity: itemsQuantityState[val.sku],
  //         },
  //       }),
  //     }),
  //     {} as Record<string, SelectedProductValue>
  //   )
  //
  //   const skusWithoutCurrent = Object.keys(newState).filter((key) => key !== product.sku)
  //
  //   const otherPart = skusWithoutCurrent
  //     .map((elem, index) => ({ sku: elem, number: index }))
  //     .reduce((acc, val) => ({ ...acc, [val.number + 1]: newState[val.sku] }), {})
  //
  //   // setSelectedMap((prevState) => {
  //   //   const mainElement = prevState[0]
  //   //   if (!mainElement) return {}
  //   //   mainElement.quantity = newState[mainElement.product.sku]?.quantity || 0
  //   //   return { [0]: mainElement, ...otherPart }
  //   // })
  // }, [setSelectedMap, itemsQuantityState, bundleIncludedProducts, product])

  const remProduct = useCallback(
    (place: number) =>
      setSelectedMap((prevState) => {
        const copyState = { ...prevState }
        copyState[place] = null
        return copyState
        // const theItem = prevState[place]
        // if (theItem) itemQuantityCallback(theItem.product.sku, 0)
        // return { ...prevState, [place]: null }
      }),
    [setSelectedMap, itemQuantityCallback]
  )
  const { bundleLabel, bundleType } = priceInfo
  const infoNode = bundleLabel ? (
    <div className={clsx(s.readLeft, 'gap-4 py-[16px] px-6 flex flex-row items-center bg-seafoam')}>
      <CwsIcon icon="price-tag" size="24px" />
      <CwsP size="small" hideSpacing>
        {translate(bundleLabel)}
      </CwsP>
    </div>
  ) : (
    ''
  )

  if (isGloballyPricelist() && onlyBulk)
    return (
      <SingleTabContent
        priceInfo={priceInfo}
        sku={product.sku}
        maxQuantity={maxQuantity}
        handleQuantityUpdate={handleQuantityUpdate}
        productQuantity={productQuantity}
        setTab={setTab}
        setSelectedMap={setSelectedMap}
        reset={reset}
      />
    )
  if ((bundleIncludedProductsIsStock?.length ?? 0) <= 1 && bundleType) {
    return (
      <>
        <SingleTabContent
          priceInfo={priceInfo}
          sku={product.sku}
          maxQuantity={maxQuantity}
          handleQuantityUpdate={handleQuantityUpdate}
          productQuantity={productQuantity}
          setTab={setTab}
          setSelectedMap={setSelectedMap}
          reset={reset}
        />
      </>
    )
  }
  return (
    <>
      <CwsTab mode="fit-content">
        <CwsTabItem label={translate('w2.product.detail.tub.title.bundle') ?? 'Bundle'} id="bundle" />
        <CwsTabItem label={translate('w2.product.detail.tub.title.single') ?? 'Single'} id="single" />
      </CwsTab>
      <CwsTabContent>
        <CwsTabContentItem id="bundle">
          {bundleIncludedProductsIsStock?.length ? (
            <div className={clsx(s.section)}>
              {infoNode}
              <div className={s.parameter}>
                <BundleOptions
                  itemQuantityCallback={itemQuantityCallback}
                  quantityState={itemsQuantityState}
                  bundleQuantity={priceInfo.bundleQuantity}
                  remProduct={remProduct}
                  selectedMap={selectedMap}
                  setSelectedMap={setSelectedMap}
                  unUsedProducts={unUsedProducts}
                  setUnUsedProducts={setUnUsedProducts}
                  setTab={setTab}
                />
              </div>
            </div>
          ) : null}
        </CwsTabContentItem>
        <CwsTabContentItem id="single">
          <SingleTabContent
            priceInfo={priceInfo}
            sku={product.sku}
            maxQuantity={maxQuantity}
            handleQuantityUpdate={handleQuantityUpdate}
            productQuantity={productQuantity}
            setTab={setTab}
            setSelectedMap={setSelectedMap}
            reset={reset}
          />
        </CwsTabContentItem>
      </CwsTabContent>
    </>
  )
}

export default BundleAndBulk
