export const KK_POST_ENDPOINT = process.env.KK_ENDPOINT
export const GATEWAY_HOST = process.env.GATEWAY_HOST
export const KK_HOST = process.env.KK_HOST
export const KONAKART_HOST = process.env.KONAKART_HOST
export const RECSYS_HOST = process.env.RECSYS_HOST
export const ECW_API_HOST = process.env.ECW_API_HOST
export const ECW_API_KEY = process.env.ECW_API_KEY
export const KK_STORE = process.env.KK_STORE
export const ORDER_RETURN_EMAIL = process.env.ORDER_RETURN_EMAIL
export const FEED_BACK_EMAIL = process.env.FEED_BACK_EMAIL
