import * as R from 'ramda'
import type { Order } from '@model/order/Order'
import type { OrderTotal } from '@model/order/OrderTotal'

export const orderTotalsHasGiftCoupon = R.find(R.propEq('className', 'ot_gift_coupons'))

const withGiftCouponsModule = R.propEq('className', 'ot_gift_coupons')
const withTotalModule = R.propEq('className', 'ot_total')

type Predicate = (ot: OrderTotal) => boolean

// @ts-ignore
const getOTValue = (predicate: Predicate) => R.compose(R.prop('value'), R.head, R.filter(predicate))
// @ts-ignore
const getOTTitle = (predicate: Predicate) => R.compose(R.prop('title'), R.head, R.filter(predicate))

export const getCouponsFromTitle = (title = '') => {
  if (!title) return ''

  return title.substring(title.indexOf('=') + 1, title.indexOf(':')).trim()
}

export const getGiftCouponsAppliedTotal = ({ orderTotals = [] } = {}) => {
  const giftCardsTotal = getOTValue(withGiftCouponsModule)(orderTotals) as number
  const total = getOTValue(withTotalModule)(orderTotals) as number

  const difference = total - giftCardsTotal

  return difference <= 0 ? total : giftCardsTotal
}

export const getCouponsFromGiftCardsModuleTitle = ({ orderTotals = [] }: Order) => {
  const giftCardsTotal = getOTTitle(withGiftCouponsModule)(orderTotals) as string
  return getCouponsFromTitle(giftCardsTotal)
}

export const getTotalValue = ({ orderTotals = [] }: Order) => getOTValue(withTotalModule)(orderTotals)

export const getDifference = ({ orderTotals = [] }: Order) => {
  const giftCardsTotal = getOTValue(withGiftCouponsModule)(orderTotals) as number
  const total = getOTValue(withTotalModule)(orderTotals) as number

  const difference = total - giftCardsTotal

  return difference < 0 ? 0 : difference
}

export const isTotalCoveredByGiftCoupons = (order: Order) => {
  const { orderTotals } = order
  if (orderTotalsHasGiftCoupon(orderTotals)) {
    return !(getDifference(order) > 0)
  } else {
    return false
  }
}

export const isPartiallyCoveredByGiftCoupons = (order: Order) => {
  const { orderTotals } = order
  return orderTotalsHasGiftCoupon(orderTotals)
}
