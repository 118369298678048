import type { FC } from 'react'
import { useEffect, useMemo, useState } from 'react'
import { RadioButton } from '@ui/components'
import useSWRImmutable from 'swr/immutable'
import type { EstoOption, ScheduleType } from '@api/checkout/esto/esto-utils'
import * as R from 'ramda'
import { useCartState } from '@components/cart/State/state'
import clsx from 'clsx'
import { useTranslate } from '@hooks/useTranslation'
import { CwsP } from '@components/cws'

const levels: Record<ScheduleType, number> = {
  REGULAR: parseFloat(process.env.NEXT_PUBLIC_ESTO_REGULAR_LEVEL || '0'),
  ESTO_X: parseFloat(process.env.NEXT_PUBLIC_ESTO_ESTO_X_LEVEL || '0'),
  PAY_LATER: parseFloat(process.env.NEXT_PUBLIC_ESTO_PAY_LATER_LEVEL || '0'),
}

export const EstoOptions: FC = () => {
  const {
    dispatch,
    state: { order },
  } = useCartState()
  const { data: options } = useSWRImmutable<EstoOption[]>('/api/payment/esto/methods')
  const [selectedOption, setSelectedEstoOption] = useState<EstoOption | null>()

  const { translate } = useTranslate()
  const orderTotal = useMemo(() => order?.totalIncTax, [order])

  useEffect(() => {
    if (options?.length && orderTotal) {
      const firstEnabledOption = R.compose(
        R.head,
        R.filter((item: EstoOption) => orderTotal >= levels[item.schedule_type])
      )(options)

      if (firstEnabledOption) {
        setSelectedEstoOption(firstEnabledOption as EstoOption)
        dispatch({
          type: 'setPaymentTypeDetails',
          payload: (firstEnabledOption as EstoOption).key,
        })
        dispatch({ type: 'setPaymentMethod', payload: 'esto' })
      }
    }
  }, [options, setSelectedEstoOption, orderTotal, dispatch])

  if (!orderTotal) return null

  return (
    <div className="flex flex-col gap-y-6 lg:gap-y-6 w-full">
      {options &&
        options.map((item) => {
          const disabled = !(orderTotal >= levels[item.schedule_type])
          return (
            <div key={item.key}>
              <RadioButton
                label={item.name}
                checked={selectedOption?.key === item.key}
                value={item.name}
                onClick={() => {
                  setSelectedEstoOption(item)
                  dispatch({
                    type: 'setPaymentTypeDetails',
                    payload: item.key,
                  })
                  dispatch({ type: 'setPaymentMethod', payload: 'esto' })
                }}
                labelClassName={clsx('w-full', disabled && 'opacity-50')}
                disabled={disabled}
              >
                {translate(`esto.${item.key}.lbl`)}
                {/*<Image src={item.logo_url} width="40" height="30" alt={item.name} />*/}
              </RadioButton>
              <CwsP
                className={disabled && 'opacity-50'}
                dangerouslySetInnerHTML={{ __html: translate(`esto.${item.key}.info`) }}
              ></CwsP>
            </div>
          )
        })}
    </div>
  )
}

export default EstoOptions
