import { FC, SVGProps } from 'react'
import { Color } from '@ui/system'
import clsx from 'clsx'

export interface InfoProps extends Omit<SVGProps<SVGSVGElement>, 'color'> {
  color?: Color | undefined
}

export const Info: FC<InfoProps> = (props) => {
  const { color = '#6ECBD9', className, ...restProps } = props

  return (
    <svg width="18" height="19" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 18.265a9 9 0 1 0 0-18 9 9 0 0 0 0 18zm-1.146-5.646a1.146 1.146 0 1 0 2.292 0V9.075a1.146 1.146 0 1 0-2.292 0v3.544zM9 4.765a1.146 1.146 0 1 1 0 2.291 1.146 1.146 0 0 1 0-2.291z"
        fill={color}
      />
    </svg>
  )
}

export default Info
