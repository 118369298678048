import { createContext, FC, PropsWithChildren, useContext, useReducer } from 'react'
import { BreadCrumbsContextProps, breadCrumbsReducer } from '@components/BreadCrumbs/State/reducer'

const Context = createContext<BreadCrumbsContextProps>({} as BreadCrumbsContextProps)
type BreadCrumbsProviderProps = PropsWithChildren
const BreadCrumbsProvider: FC<BreadCrumbsProviderProps> = ({ children }) => {
  const value = {
    breadcrumbs: [],
  }
  const [state, dispatch] = useReducer(breadCrumbsReducer, value)

  const exposed = {
    state,
    dispatch,
  }
  return <Context.Provider value={exposed}>{children}</Context.Provider>
}
export const useBreadCrumbsState = () => useContext(Context)
export default BreadCrumbsProvider
