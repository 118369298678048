import type { NextPageContext } from 'next'

const REDIRECT_STATUS = 302

export const handleRedirects = (ctx: NextPageContext): void => {
  if (!ctx.req || !ctx.res) return

  const shoppingUrl = new URL('https://shopping.tallink.com')
  const pricelistUrl = new URL('https://shopping-onboard.tallink.com')
  const { origin } = shoppingUrl
  const { origin: pricelistOrigin } = pricelistUrl
  const { req, res } = ctx
  const { headers, url } = req

  const hostname = headers.host

  if (hostname?.includes('pood.tallink.ee') && url?.includes('tagastused')) {
    res.writeHead(REDIRECT_STATUS, { Location: `${origin}/support/returns` })
    res.end()
    return
  } else if (hostname?.includes('verkkokauppa.tallink.fi') && url?.includes('palautus')) {
    res.writeHead(REDIRECT_STATUS, { Location: `${origin}/fi/support/returns` })
    res.end()
    return
  } else if (hostname?.includes('webshop.tallink.se') && url?.includes('returer')) {
    res.writeHead(REDIRECT_STATUS, { Location: `${origin}/sv/support/returns` })
    res.end()
    return
  } else if (hostname?.includes('eveikals.tallink.lv') && url?.includes('atgriesana')) {
    res.writeHead(REDIRECT_STATUS, { Location: `${origin}/lv/support/returns` })
    res.end()
    return
  } else if (hostname?.includes('eparduotuve.tallink.lt') && url?.includes('grazinimas')) {
    res.writeHead(REDIRECT_STATUS, { Location: `${origin}/lt/support/returns` })
    res.end()
    return
  } else if (hostname?.includes('shop.tallinksilja.ru') && url?.includes('returns')) {
    res.writeHead(REDIRECT_STATUS, { Location: `${origin}/ru/support/returns` })
    res.end()
    return
  }

  if (
    hostname?.includes('webshop.tallink.se') ||
    hostname?.includes('shopping.tallink.se') ||
    hostname?.includes('webbutik.tallink.se')
  ) {
    res.writeHead(REDIRECT_STATUS, { Location: `${origin}/sv/` })
    res.end()
    return
  }

  if (hostname?.includes('shop.tallinksilja.ru')) {
    res.writeHead(REDIRECT_STATUS, { Location: `${origin}/ru/` })
    res.end()
    return
  }

  if (hostname?.includes('shopping.tallink.ee') || hostname?.includes('pood.tallink.ee')) {
    res.writeHead(REDIRECT_STATUS, { Location: `${origin}/` })
    res.end()
    return
  }
  if (hostname?.includes('shopping.tallink.fi') || hostname?.includes('verkkokauppa.tallink.fi')) {
    res.writeHead(REDIRECT_STATUS, { Location: `${origin}/fi/` })
    res.end()
    return
  }
  if (hostname?.includes('shopping.tallink.lv') || hostname?.includes('eveikals.tallink.lv')) {
    res.writeHead(REDIRECT_STATUS, { Location: `${origin}/lv/` })
    res.end()
    return
  }
  if (hostname?.includes('shopping.tallink.lt') || hostname?.includes('eparduotuve.tallink.lt')) {
    res.writeHead(REDIRECT_STATUS, { Location: `${origin}/lt/` })
    res.end()
    return
  }
  if (hostname?.includes('shopping.tallinksilja.no')) {
    res.writeHead(REDIRECT_STATUS, { Location: `${pricelistOrigin}/en/` })
    res.end()
    return
  }
  if (hostname?.includes('shopping.tallinksilja.ru')) {
    res.writeHead(REDIRECT_STATUS, { Location: `${pricelistOrigin}/ru/` })
    res.end()
    return
  }
}
