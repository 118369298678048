import type { FC } from 'react'
import moduleStyles from '@components/product/ProductViewParameters/ProductViewParameters.module.scss'
import { CwsP } from '@components/cws'
import { useTranslate } from '@hooks/useTranslation'

interface OnBoardAvailabilityProps {
  shipsListString: string
}

export const OnBoardAvailability: FC<OnBoardAvailabilityProps> = ({ shipsListString }) => {
  const { translate } = useTranslate()
  const theList = shipsListString
    .split(',')
    .map((s) => s.toLocaleLowerCase())
    .map((ferryId) => {
      const [name = ''] = translate(`route.${ferryId}.lbl`).split(' (')
      return name || ferryId
    })
    .join(', ')

  return (
    <>
      <hr />
      <div className={moduleStyles.section}>
        <div>
          <CwsP size="small">{translate('product.detail.available.ferries.title')}</CwsP>
          <CwsP weight="500">{theList}</CwsP>
        </div>
      </div>
    </>
  )
}

export default OnBoardAvailability
