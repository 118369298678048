import { useRouter } from 'next/router'
import type { CountryName, Locale } from '@model/locales'
import { KK_LANG_IDS } from '@model/locales'
import useSWRImmutable from 'swr/immutable'
import axios from 'axios'
import type { CategoryTip } from '@model/category/CategoryTip'
import type { Footer } from '@model/content/content'
import type { TagGroup } from '@model/misc/TagGroup'
import type { CurrenciesInfo } from '@hooks/useCurrency'
import type { Promotion } from '@model/misc/Promotion'
import type { Country } from '@model/order/Order'
import type { CatalogFromContent } from '@api/content'

type InitialData = {
  categories: CategoryTip[]
  footer: Footer
  tagGroups: TagGroup[]
  currencies: CurrenciesInfo[]
  topNavigationItems: Record<string, string>
  allPromotions: Promotion[]
  deliveryInfo: string
  allCountries: Country[]
  defaultDeliveryAddrIds: Record<CountryName, number>
  catalogs: CatalogFromContent[]
}

export const useInitialData = (ssoToken?: string): InitialData => {
  const {
    locale,
    query: { catalogId = '' },
  } = useRouter()

  const languageId = KK_LANG_IDS[locale as Locale]
  const key = ssoToken
    ? `/api/initialData-${languageId}-${catalogId}-${ssoToken}`
    : `/api/initialData-${languageId}-${catalogId}`

  const { data: initialData } = useSWRImmutable(key, async () => {
    const { data: initialData } = await axios.post('/api/initial-data', {
      languageId,
      ...(catalogId && { catalogId }),
    })

    return initialData
  })

  const categories: CategoryTip[] = initialData?.menuItems || []
  const currencies: CurrenciesInfo[] = initialData?.currencies || []
  const topNavigationItems = initialData?.topNavigation || {}
  const allPromotions = initialData?.allPromotions || []
  const catalogs: CatalogFromContent[] = initialData?.catalogs || []

  if (initialData && initialData.footerContent && topNavigationItems) {
    const footer: Footer = initialData.footerContent[locale as Locale] as Footer

    return {
      categories,
      footer,
      tagGroups: initialData?.tagGroups || [],
      currencies,
      topNavigationItems,
      allPromotions,
      deliveryInfo: initialData.deliveryInfo,
      allCountries: initialData.allCountries || [],
      defaultDeliveryAddrIds: initialData.defaultDeliveryAddrIds,
      catalogs,
    }
  }

  return {
    categories,
    currencies,
    tagGroups: initialData?.tagGroups || [],
    defaultDeliveryAddrIds: {
      Estonia: 0,
      Lithuania: 0,
      Latvia: 0,
      Sweden: 0,
      Finland: 0,
    },
    catalogs: [],
    footer: {
      bottomLinks: [],
      rows: [],
      columns: [],
    },
    topNavigationItems: {},
    allPromotions,
    deliveryInfo: '',
    allCountries: initialData?.allCountries || [],
  }
}
