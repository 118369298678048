import type { Dispatch } from 'react'
import type { CartStep } from '@components/cart/Stepper/Stepper'
import type { Order } from '@model/order/Order'
import * as R from 'ramda'
import type { DeliveryContactData } from '@components/cart/delivery/CantactData/ContactData'
import type { DeliveryMethodWithQuote } from '@api/ecw/models/DeliveryMethod'
import type { BusinessClientData } from '@components/cart/delivery/CantactData/BusinessClientData'
import type { GiftCoupon } from '@model/order/Coupon'
import type { PickupPoint } from '@api/ecw/models/PickupPoint'
import type { CountryName } from '@model/locales'
import type { AdyenPaymentMethod } from '@api/checkout/adyen'
import type { DeliveryMethod } from '@api/ecw/models/DeliveryMethod'
import type { HomeDeliveryData } from '@components/cart/delivery/DeliveryMain/HomeDelivery'
import type { BankPaymentResponse } from '@api/checkout/models'
import type { Product } from '@model/product'
import type { ProtoTypeVariant } from '@components/cart/delivery/DeliveryMethods/DeliveryPrototype'
import { defaultProtoType } from '@components/cart/delivery/DeliveryMethods/DeliveryPrototype'
import { emptyHomeDeliveryData } from '@components/cart/delivery/DeliveryMain/HomeDelivery'
import type { CustomerAddress } from '@api/addressCustomer'

export type PaymentMethodType = 'dibs' | 'klarna' | 'esto' | 'banks' | 'giftCoupons'
export type CouponsData = Pick<GiftCoupon, 'coupon' | 'balance'>

export interface CartState {
  currentStep: CartStep
  completedSteps: CartStep[]
  order: Order | null
  bookingNumber?: string
  deliveryContactData: DeliveryContactData
  giftRecipientContactData: DeliveryContactData
  businessClientData?: BusinessClientData
  selectedDeliveryPoint: null | PickupPoint
  coupons: CouponsData[]
  discountCoupons: string[]
  paymentMethod: PaymentMethodType
  paymentTypeDetails: string | null
  deliveryCountry: CountryName
  deliveryMethodsByCountry: Record<CountryName, null | DeliveryMethodWithQuote>
  storedPaymentMethod: AdyenPaymentMethod | null
  deliveryMethods: DeliveryMethod[]
  homeDeliveryAddress: HomeDeliveryData | null
  bankPaymentResponse: BankPaymentResponse | null
  redeemPoints: number
  outOfStockProducts: string[]
  giftWrapperProduct?: Product
  deliveryVariant: ProtoTypeVariant
  orderSaveError: string | null
  selectedPickUpPointId: string
  tempCustomerAddress: CustomerAddress | null
  isGift: boolean
}
export interface SetAll {
  type: 'setAll'
  payload: CartState
}
export interface SetIsGift {
  type: 'setIsGift'
  payload: boolean
}
export interface SetStep {
  type: 'setStep'
  payload: CartStep
}

export interface SetSelectedPickUpPointId {
  type: 'selectPickUpPointId'
  payload: string
}

export interface SetOutOfStockProducts {
  type: 'setOutOfStockProducts'
  payload: string[]
}

export interface SetDeliveryCountry {
  type: 'setDeliveryCountry'
  payload: CountryName
}

export interface SetPaymentTypeDetails {
  type: 'setPaymentTypeDetails'
  payload: string
}

export interface CompleteStep {
  type: 'completeStep'
  payload: CartStep
}

export interface SetContactData {
  type: 'fillContactData'
  payload: DeliveryContactData
}

export interface UpdateOrder {
  type: 'updateOrder'
  payload: Order | null
}

export interface SelectCarrierCodeForCountry {
  type: 'selectDeliveryMethodForCountry'
  payload: { country: CountryName; method: null | DeliveryMethodWithQuote }
}

export interface SelectDeliveryPoint {
  type: 'setSelectDeliveryPoint'
  payload: null | PickupPoint
}

export interface SetBookingNumber {
  type: 'setBookingNumber'
  payload: string | undefined
}

export interface SetGiftRecipientContactData {
  type: 'fillGiftRecipientData'
  payload: DeliveryContactData
}

export interface SetBusinessClientData {
  type: 'fillBusinessClientData'
  payload: BusinessClientData
}

export interface AddCoupon {
  type: 'addCoupon'
  payload: Pick<GiftCoupon, 'coupon' | 'balance'>
}

export interface RemoveCoupon {
  type: 'remCoupon'
  payload: string
}

export interface AddDiscountCoupon {
  type: 'addDiscountCoupon'
  payload: string
}

export interface RemoveDiscountCoupon {
  type: 'remDiscountCoupon'
  payload: string
}

export interface SetPaymentMethod {
  type: 'setPaymentMethod'
  payload: PaymentMethodType
}

export interface SetStoredAdyenMethod {
  type: 'setStoredAdyenMethod'
  payload: AdyenPaymentMethod | null
}

export interface SetDeliveryMethods {
  type: 'setDeliveryMethods'
  payload: DeliveryMethod[]
}

export interface SetHomeDeliveryData {
  type: 'setHomeDeliveryData'
  payload: HomeDeliveryData | null
}

export interface SetBankPaymentResponse {
  type: 'setBankPaymentResponse'
  payload: BankPaymentResponse | null
}

export interface SetRedeemPoints {
  type: 'setRedeemPoints'
  payload: number
}

export interface SetDeliveryVariant {
  type: 'setDeliveryVariant'
  payload: ProtoTypeVariant
}

export interface SetOrderSaveError {
  type: 'setOrderSaveError'
  payload: string | null
}

export interface SetTempAddress {
  type: 'setTempAddress'
  payload: CustomerAddress | null
}

export type Action =
  | SetStep
  | CompleteStep
  | SetContactData
  | UpdateOrder
  | SetBookingNumber
  | AddCoupon
  | SetGiftRecipientContactData
  | SetBusinessClientData
  | RemoveCoupon
  | AddDiscountCoupon
  | RemoveDiscountCoupon
  | SelectDeliveryPoint
  | SetPaymentMethod
  | SelectCarrierCodeForCountry
  | SetDeliveryCountry
  | SetStoredAdyenMethod
  | SetDeliveryMethods
  | SetHomeDeliveryData
  | SetPaymentTypeDetails
  | SetBankPaymentResponse
  | SetRedeemPoints
  | SetOutOfStockProducts
  | SetAll
  | SetDeliveryVariant
  | SetOrderSaveError
  | SetSelectedPickUpPointId
  | SetTempAddress
  | SetIsGift

// type ActionType = Action['type']

export interface CartStateContextProps {
  state: CartState
  dispatch: Dispatch<Action>
}

const rejectCouponsByCode = (code: string) => R.reject<CouponsData>(({ coupon }) => coupon === code)
const rejectStringCoupons = (code: string) => R.reject<string>((coupon) => coupon === code)

export const cartStateReducer = (state: CartState, action: Action): CartState => {
  switch (action.type) {
    case 'setStep': {
      return { ...state, currentStep: action.payload }
    }
    case 'completeStep': {
      return { ...state, completedSteps: R.uniq([...state.completedSteps, action.payload]) }
    }
    case 'fillContactData': {
      return { ...state, deliveryContactData: action.payload }
    }
    case 'fillGiftRecipientData': {
      return { ...state, giftRecipientContactData: action.payload }
    }
    case 'updateOrder': {
      return { ...state, order: action.payload }
    }
    case 'selectPickUpPointId': {
      return { ...state, selectedPickUpPointId: action.payload }
    }
    case 'setSelectDeliveryPoint': {
      return { ...state, selectedDeliveryPoint: action.payload }
    }
    case 'fillBusinessClientData': {
      return { ...state, businessClientData: action.payload }
    }
    case 'setBookingNumber': {
      return { ...state, bookingNumber: action.payload }
    }
    case 'addCoupon': {
      return { ...state, coupons: R.uniq([...state.coupons, action.payload]) }
    }
    case 'remCoupon': {
      const couponsWithoutPayload = rejectCouponsByCode(action.payload)(state.coupons)
      return { ...state, coupons: couponsWithoutPayload }
    }
    case 'addDiscountCoupon': {
      return { ...state, discountCoupons: R.uniq([...state.discountCoupons, action.payload]) }
    }
    case 'remDiscountCoupon': {
      const couponsWithoutPayload = rejectStringCoupons(action.payload)(state.discountCoupons)
      return { ...state, discountCoupons: couponsWithoutPayload }
    }
    case 'setPaymentMethod': {
      return { ...state, paymentMethod: action.payload }
    }
    case 'setOutOfStockProducts': {
      return { ...state, outOfStockProducts: action.payload }
    }
    case 'setPaymentTypeDetails': {
      return { ...state, paymentTypeDetails: action.payload }
    }
    case 'setHomeDeliveryData': {
      return { ...state, homeDeliveryAddress: action.payload }
    }
    case 'setIsGift': {
      return { ...state, isGift: action.payload }
    }
    case 'setDeliveryCountry': {
      return {
        ...state,
        deliveryCountry: action.payload,
        deliveryVariant: defaultProtoType[action.payload],
        selectedDeliveryPoint: null,
        homeDeliveryAddress: emptyHomeDeliveryData,
      }
    }
    case 'setStoredAdyenMethod': {
      return { ...state, storedPaymentMethod: action.payload }
    }
    case 'setBankPaymentResponse': {
      return { ...state, bankPaymentResponse: action.payload }
    }
    case 'setDeliveryMethods': {
      return { ...state, deliveryMethods: action.payload }
    }
    case 'setRedeemPoints': {
      return { ...state, redeemPoints: action.payload }
    }
    case 'setDeliveryVariant': {
      return { ...state, deliveryVariant: action.payload }
    }
    case 'setAll': {
      return action.payload
    }
    case 'setOrderSaveError': {
      return { ...state, orderSaveError: action.payload }
    }
    case 'setTempAddress': {
      return { ...state, tempCustomerAddress: action.payload }
    }
    case 'selectDeliveryMethodForCountry': {
      return {
        ...state,
        deliveryMethodsByCountry: {
          ...state.deliveryMethodsByCountry,
          [action.payload.country]: action.payload.method,
        },
      }
    }
    default:
      return { ...state }
  }
}
