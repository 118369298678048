import type { ChangeEvent, Dispatch, FC, SetStateAction } from 'react'
import { useState } from 'react'
import {
  CwsGrid,
  CwsGridRow,
  CwsGridCol,
  CwsInput,
  CwsModal,
  CwsH3,
  CwsH4,
  CwsP,
  CwsModalBody,
  CwsModalFooter,
  CwsButton,
  CwsCheckbox,
  CwsSpan,
  CwsSelection,
  CwsSelectionItem,
  CwsIcon,
} from '@components/cws'
// @ts-ignore
import { modal } from 'cms-web-styleguide/dist/utils/utils'
import EmailIllustration2 from '@components/icons/EmailIllustration2'
import DateInput from '../../../ui/components/DateInput/DateInput'
import axios from 'axios'
import { useRouter } from 'next/router'
import type { Locale } from '@model/locales'
import { KK_LANG_IDS } from '@model/locales'
import { useTranslate } from '@hooks/useTranslation'
import { useGoogleRecaptcha } from '@ui/hooks/useGoogleRecaptcha'

interface FormSubscriptionProps {
  visible?: boolean
  setVisible: Dispatch<SetStateAction<boolean>>
  emailAddress: string
  setEmailValue: Dispatch<SetStateAction<string>>
}

export const FormSubscription: FC<FormSubscriptionProps> = ({ emailAddress, visible, setVisible, setEmailValue }) => {
  const [modalSuccessVisible, setSuccessModalVisible] = useState<boolean>(false)
  const [date, setDate] = useState<string>('')
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [gender, setGender] = useState<string>('')
  const { translate } = useTranslate()
  const { locale } = useRouter()
  const languageId = KK_LANG_IDS[locale as Locale]
  const { executeGoogleReCaptcha } = useGoogleRecaptcha()
  const getDate = (v: string) => {
    setDate(v)
  }
  const handleSubmit = async () => {
    try {
      const token = await executeGoogleReCaptcha()

      const { data } = await axios({
        method: 'POST',
        url: '/api/newsletter/subscribe',
        data: {
          firstName: firstName,
          lastName: lastName,
          birthDate: date,
          emailAddr: emailAddress,
          languageId,
          custom4: locale,
          custom5: locale,
          newsletter: 1,
          recaptchaResponse: token,
        },
      })
      if (data.emailAddr) {
        modal.addEventModalShow('success-modal')
        setSuccessModalVisible(true)
        window.dataLayer.push({
          event: 'subscribed_newsletter',
        })
        setEmailValue('')
        setFirstName('')
        setLastName('')
        setDate('')
        setVisible(false)
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      {!!emailAddress && (
        <CwsModal width="fixed" id="form-modal" hideCloseButton visible={visible} onClose={() => setVisible(false)}>
          <CwsModalBody>
            <CwsGrid width="fluid">
              <CwsGridRow>
                <CwsGridCol col={12} className="flex flex-col rounded-2xl items-center  bg-seafoam cws-p-xl">
                  <EmailIllustration2 />
                  <CwsH3 textAlign="center">{translate('subscription.form.header.title')}</CwsH3>
                  <CwsP textAlign="center">
                    {translate('subscription.form.header.description.one')}&ensp;
                    <CwsSpan weight={500}>{emailAddress}.</CwsSpan>
                  </CwsP>
                  <CwsP textAlign="center">{translate('subscription.form.header.description.two')}</CwsP>
                </CwsGridCol>
                <CwsGridCol col={12} className="cws-px-m">
                  <CwsH3>{translate('subscription.form.body.title')}</CwsH3>
                  <CwsP>{translate('subscription.form.body.description')}</CwsP>
                </CwsGridCol>
                <CwsGrid>
                  <CwsGridRow>
                    <CwsGridCol xs={12} md={6}>
                      <CwsInput
                        label={translate('subscription.form.input.firstName')}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
                      />
                    </CwsGridCol>
                    <CwsGridCol xs={12} md={6}>
                      <CwsInput
                        label={translate('subscription.form.input.lastName')}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
                      />
                    </CwsGridCol>
                    <CwsGridCol xs={12} md={6}>
                      <DateInput label={translate('subscription.form.input.birth')} getDate={getDate} />
                    </CwsGridCol>
                    <CwsGridCol col={12}>
                      <CwsP size="small">{translate('subscription.form.input.gender')}</CwsP>
                      <CwsSelection
                        onChange={(value: string) => {
                          setGender(value)
                        }}
                      >
                        <CwsSelectionItem
                          checked
                          label={translate('subscription.form.input.gender.family')}
                          value="Female"
                        />
                        <CwsSelectionItem label={translate('subscription.form.input.gender.male')} value="Male" />
                      </CwsSelection>
                    </CwsGridCol>
                    <CwsGridCol col={12}>
                      <CwsCheckbox>{translate('subscription.form.input.checkbox.label')}</CwsCheckbox>
                    </CwsGridCol>
                  </CwsGridRow>
                </CwsGrid>
              </CwsGridRow>
            </CwsGrid>
          </CwsModalBody>
          <CwsModalFooter>
            <CwsGrid width="fluid">
              <CwsGridRow>
                <CwsGridCol>
                  <CwsButton
                    onClick={() => {
                      handleSubmit().then()
                    }}
                  >
                    {translate('subscription.form.button.ok')}
                  </CwsButton>
                </CwsGridCol>
                <CwsGridCol className="flex justify-end">
                  <CwsButton
                    onClick={() => {
                      setVisible(false)
                      setEmailValue('')
                    }}
                    variant="empty"
                  >
                    {translate('subscription.form.button.cancel')}
                  </CwsButton>
                </CwsGridCol>
              </CwsGridRow>
            </CwsGrid>
          </CwsModalFooter>
        </CwsModal>
      )}
      <CwsModal
        visible={modalSuccessVisible}
        id="success-modal"
        onClose={() => {
          setSuccessModalVisible(false)
        }}
      >
        <CwsModalBody>
          <CwsH4 className="flex">
            <CwsIcon className="cws-mr-tiny" icon="check" size="24px" color="action" wrapper />
            {translate('subscription.success.message.title')}
          </CwsH4>
          <CwsP>{translate('subscription.success.message.description')}</CwsP>
        </CwsModalBody>
      </CwsModal>
    </>
  )
}
