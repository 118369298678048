import { CwsImg } from '@components/cws'
import { FC } from 'react'
interface EStoreIconProps {
  locale?: string
}
const EStoreIcon: FC<EStoreIconProps> = ({ locale }) => {
  if (!locale) return null
  const variants = ['en', 'ru', 'et'].includes(locale) ? locale : 'en'
  return <CwsImg width="120" height="120" alt="secure_shopping" src={`/secure_shopping-${variants}.svg`} />
}

export default EStoreIcon
