import { Typography } from '@ui/components'
import clsx from 'clsx'
import type {
  ChangeEvent,
  ChangeEventHandler,
  DetailedHTMLProps,
  HTMLAttributes,
  InputHTMLAttributes,
  ReactNode,
} from 'react'
import { forwardRef, useCallback } from 'react'
import s from './Input.module.scss'

type HTMLProps<TElement extends HTMLElement> = DetailedHTMLProps<HTMLAttributes<TElement>, TElement>
type HTMLInputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
type WithoutClassName<T extends HTMLProps<any>> = Omit<T, 'className'>

export type InputChangeHandler = (newValue: string, event: ChangeEvent<HTMLInputElement>) => void

/**
 * Input's props inherit HTML Input Element props (html attributes)
 */
export interface InputProps extends Omit<HTMLInputProps, 'value' | 'onChange'> {
  label?: string
  boxShadow?: boolean

  value?: string
  onChange?: InputChangeHandler

  containerClassName?: string
  wrapperClassName?: string

  containerProps?: WithoutClassName<HTMLProps<HTMLDivElement>>
  wrapperProps?: WithoutClassName<HTMLProps<HTMLDivElement>>

  startAdornment?: ReactNode
  endAdornment?: ReactNode
  optional?: boolean
}

/**
 *
 * @param props Props
 * @returns UI's Input component
 */
const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    className,
    label,
    children,
    startAdornment,
    endAdornment,
    boxShadow = true,
    containerClassName,
    wrapperClassName,
    containerProps,
    wrapperProps,
    value,
    onChange,
    optional = false,
    ...restProps
  } = props

  const handleInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      onChange?.(event?.target?.value, event)
    },
    [onChange]
  )
  return (
    <div className={clsx('flex', containerClassName ? containerClassName : 'flex-col')} {...(containerProps || {})}>
      <div className={clsx('flex justify-between w-full', label ? 'flex' : 'hidden')}>
        {label && (
          <Typography as="span" variant="body" className={s.label}>
            {label}
          </Typography>
        )}
        {optional && (
          <Typography as="span" variant="body" className={clsx(s.label, 'text-bermuda')}>
            {'Optional'}
          </Typography>
        )}
      </div>
      <div
        className={clsx(s['input-wrapper'], boxShadow && s['box-shadow'], wrapperClassName)}
        {...(wrapperProps || {})}
      >
        {startAdornment && <div className="flex">{startAdornment}</div>}
        <input
          ref={ref}
          className={clsx(
            s.input,
            'flex-1-auto w-full focus:outline-none bg-transparent',
            'flex-1-auto font-medium text-base leading-5 h-5 w-full px-4 my-2.5 focus:outline-none bg-transparent',
            className
          )}
          spellCheck={false}
          onChange={handleInputChange}
          value={value}
          {...restProps}
        />
        {endAdornment && <div className="flex">{endAdornment}</div>}
      </div>
      {children}
    </div>
  )
})

Input.displayName = 'Input'

export default Input
