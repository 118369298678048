import type { AxiosPromise } from 'axios'
import axios from 'axios'
import type { Product } from '@model/product'
import type { ProductReviewResult } from '@model/product/ProductReviewResult.js'
import { parseJsonOrEmptyList } from '@utils/json'
import { getData, setData } from '@api/redis'
import type { KKError } from '@model/konakart/KKError'
import type { LanguageId } from '@model/locales'
import type { Promotion } from '@model/misc/Promotion'
import { prop } from 'ramda'
import { makeAxiosConfig } from '@utils/axios-utils'
import { KK_STORE } from '@api/apiConfig'
import type { Catalog } from '@model/pricelist/Catalog'

export const getProductBySku = async (sku: string, languageId: LanguageId, catalogId?: Catalog): Promise<Product> => {
  const cCatalog = KK_STORE === 'store1' ? `-${catalogId ?? ''}` : ''
  const cKey = `getProductBySku-${languageId}-${sku}-${KK_STORE}${cCatalog}`
  const cached = await getData<Product>(cKey)

  if (cached) {
    return cached
  }

  const getProductBody = {
    f: 'getProductPerSku',
    s: KK_STORE,
    languageId,
    sku,
  }

  // console.log('---------')
  // console.log(JSON.stringify(getProductBody, null, 2))

  const config = makeAxiosConfig(getProductBody)

  const { data } = await (axios(config) as AxiosPromise<{ r: Product } | KKError>)
  if ('r' in data) {
    await setData(cKey, data.r)
    return data.r
  } else {
    if (data.m) throw new Error(data.m)
    else {
      throw new Error(`Nothing is found by getProductBySku for sku "${sku}" `)
    }
  }
}

export const getProductBySkuWithOptions = async (sku: string, languageId: LanguageId, catalogId?: Catalog): Promise<Product> => {
  const cCatalog = KK_STORE === 'store1' ? `-${catalogId ?? ''}` : ''
  const cKey = `getProductBySkuWithOptions-${languageId}-${sku}-${KK_STORE}${cCatalog}`
  const cached = await getData<Product>(cKey)

  if (cached) {
    return cached
  }

  const getProductBody = {
    f: 'getProductPerSkuWithOptions',
    s: KK_STORE,
    languageId,
    sku,
    options: {
      useExternalPrice: true,
      catalogId: catalogId || 'shop',
    },
  }

  const config = makeAxiosConfig(getProductBody)

  const { data } = await (axios(config) as AxiosPromise<{ r: Product } | KKError>)
  if ('r' in data) {
    await setData(cKey, data.r)
    return data.r
  } else {
    if (data.m) throw new Error(data.m)
    else {
      throw new Error(`Nothing is found by getProductBySkuWithOptions for sku "${sku}" `)
    }
  }
}

export const getProductByUrl = async (url: string, languageId: LanguageId, catalogId: Catalog): Promise<Product> => {
  const cCatalog = KK_STORE === 'store1' ? `-${catalogId}` : ''
  const cKey = `getProductByUrl-${languageId}-${url}-${KK_STORE}${cCatalog}`
  const cached = await getData<Product>(cKey)

  if (cached) {
    return cached
  }

  const getProductBody = {
    f: 'custom',
    s: KK_STORE,
    languageId: `${languageId}`,
    sessionId: null,
    input1: 'getProductByUrl',
    input2: JSON.stringify({ url, languageId, route: catalogId, sessionId: null }),
  }

  // console.log('---------')
  // console.log(JSON.stringify(getProductBody, null, 2))

  const config = makeAxiosConfig(getProductBody)

  const { data } = await (axios(config) as AxiosPromise<{ r: string } | KKError>)
  if ('r' in data) {
    const parsedResult = JSON.parse(data.r) as Product
    await setData(cKey, parsedResult)
    return parsedResult
  } else {
    if (data.m) throw new Error(data.m)
    else {
      throw new Error(`Nothing is found by getProductByUrl for URL "${url}" `)
    }
  }
}

export const fillPromotionResults = async (
  products: Product[],
  languageId: LanguageId,
  promotions: Promotion[]
): Promise<Product> => {
  const cKey = `getPromotionsPerProducts-${languageId}-${KK_STORE}-${products.map(prop('id')).join(',')}`
  const cached = await getData<Product>(cKey)

  if (cached) {
    return cached
  }

  const config = makeAxiosConfig({
    f: 'getPromotionsPerProducts',
    s: KK_STORE,
    languageId,
    products,
    promotions,
  })

  const { data } = await (axios(config) as AxiosPromise<{ r: Product[] } | KKError>)
  if ('r' in data) {
    const [product] = data.r
    await setData(cKey, product)
    return product
  } else {
    throw new Error(data.m)
  }
}

export const getProductReviews = async (
  sku: string,
  languageId: LanguageId,
  options?: any
): Promise<ProductReviewResult> => {
  const cKey = `getProductReviews-${sku}-${languageId}-${options?.orderBy}`
  const cached = await getData<ProductReviewResult>(cKey)

  if (cached) {
    return cached
  }

  const body = {
    f: 'getReviews',
    languageId,
    search: { ratingRule: 6, rating: 1, returnAverageRating: true, returnRatingFacets: false },
    dataDesc: {
      orderBy: options?.orderBy || 'ORDER_BY_DATE_ADDED_DESCENDING',
      limit: options?.limit || 60,
      offset: 0,
      custom1Int: `${languageId}`,
      custom2: sku,
    },
  }

  // console.log('---------')
  // console.log(JSON.stringify(body, null, 2))

  const { data } = await (axios(makeAxiosConfig(body)) as AxiosPromise<{ r: ProductReviewResult } | KKError>)
  if ('r' in data) {
    await setData(cKey, data.r)
    return data.r
  } else {
    throw new Error(data.m)
  }
}

export const getProductRecentlyViewed = async (
  customerId: number,
  languageId: number,
  sessionId: string | undefined,
  catalogId?: Catalog
): Promise<Product[]> => {
  const body = {
    f: 'custom',
    s: KK_STORE,
    languageId: languageId,
    input1: 'fetchRecentlyViewedProductsArray',
    input2: JSON.stringify({
      useExternalPrice: true,
      useExternalQuantity: false,
      catalogId: catalogId || 'shop',
      customerId: customerId,
      languageId,
      sessionId: sessionId || null,
    }),
  }
  const config = makeAxiosConfig(body)

  // console.log('---------')
  // console.log(JSON.stringify(body, null, 2))

  try {
    const { data } = await (axios(config) as AxiosPromise<{ r: string }>)
    return parseJsonOrEmptyList(data.r)
  } catch (e) {
    return [] as Product[]
  }
}

export const getProductById = async (
  productId: number,
  sessionId: string | undefined,
  languageId: LanguageId
): Promise<Product> => {
  const cKey = `getProductWithOptions-${productId}-${KK_STORE}-${sessionId || ''}-${languageId}`
  const cached = await getData<Product>(cKey)

  if (cached) {
    return cached
  }

  const config = makeAxiosConfig({
    f: 'getProductWithOptions',
    s: KK_STORE,
    languageId,
    sessionId,
    productId,
    options: { catalogId: 'shop' }, // todo: the catalog
  })

  const { data } = await (axios(config) as AxiosPromise<{ r: Product } | KKError>)
  if ('r' in data) {
    await setData(cKey, data.r)
    return data.r
  } else {
    throw new Error(data.m)
  }
}

export interface ProductImagesResponse {
  imgNames: string[]
}

export const getProductImagesById = async (productId: number): Promise<ProductImagesResponse> => {
  const config = makeAxiosConfig({ f: 'getProductImages', s: KK_STORE, options: { productId } })

  const { data } = await (axios(config) as AxiosPromise<{ r: ProductImagesResponse } | KKError>)
  if ('r' in data) {
    return data.r
  } else {
    throw new Error(data.m)
  }
}
