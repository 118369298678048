import type { LanguageId } from '@model/locales'
import axios from 'axios'
import type { ItemToOperate, UpdateBasketItem } from '@model/cart/CartItem'
import { CartItem } from '@model/cart/CartItem'

export const cartItemsFetcher = (languageId: LanguageId, availableCOPoints?: number) => async () => {
  const { data } = await axios.post(`/api/cart/items`, {
    languageId,
  })
  if (!availableCOPoints) return data
  return data?.map((item: CartItem) => ({
    ...item,
    product: { ...item.product, custom7: `${+item.product.custom7 * availableCOPoints}` },
  }))
}

export const cartItemsAdder = (languageId: LanguageId, item: ItemToOperate) => async () => {
  const { data } = await axios.post(`/api/cart/add`, {
    languageId,
    item,
  })
  return data
}

export const cartItemsUpdater = (languageId: LanguageId, item: UpdateBasketItem) => async () => {
  const { data } = await axios.post(`/api/cart/update`, {
    languageId,
    item,
  })
  return data
}

export const cartItemsRemover = (languageId: LanguageId, item: UpdateBasketItem) => async () => {
  const { data } = await axios.post(`/api/cart/remove`, {
    languageId,
    item,
  })
  return data
}
