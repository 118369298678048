import clsx from 'clsx'
import { Children, FC, ReactNode, useCallback } from 'react'

export interface IconSwitchSource {
  icon: ReactNode
  active?: boolean
  onClick?: () => any
}

export interface IconSwitchProps {
  icons: IconSwitchSource[]
}

const IconSwitch: FC<IconSwitchProps> = (props) => {
  const { icons } = props

  const renderIconButton = useCallback((icon: IconSwitchSource, index: number) => {
    const { active, icon: iconChildren, onClick } = icon
    return (
      <div
        key={`icon-switch-icon-${index}`}
        className={clsx(
          'flex place-items-center h-7 w-7',
          'place-content-center',
          'cursor-pointer hover:opacity-75 fill-current',
          active ? 'bg-midnight text-white' : 'text-midnight'
        )}
        onClick={onClick}
      >
        {iconChildren}
      </div>
    )
  }, [])

  return <div className="flex flex-row border border-midnight">{icons.map(renderIconButton)}</div>
}

export default IconSwitch
