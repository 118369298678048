import slugify from 'slugify'
import * as R from 'ramda'

export const SEPARATOR = '-'
export const SEARCH_SEPARATOR = ' '
const skuPackSeparator = '+'

const sslugify = (s: string) => slugify(s, { lower: true, trim: true, replacement: SEPARATOR })
export const prepareSearchTerm = (s: string) => slugify(s, { lower: true, trim: true, replacement: SEARCH_SEPARATOR })

const packIdentifier = (id: string | number): string => {
  if (typeof id === 'string') {
    return id.replaceAll(SEPARATOR, skuPackSeparator)
  }
  return `${id}`
}

const unPackIdentifier = (str: string): string => {
  if (str.includes(skuPackSeparator)) {
    return str.replaceAll(skuPackSeparator, SEPARATOR)
  }
  return str
}

export const getSlug = (theThing: string, thingId: string | number): string => {
  const withSlashes = theThing.split(SEPARATOR)

  if (withSlashes.length) {
    const slugs = withSlashes.map(sslugify)
    return `${slugs.join(SEPARATOR)}${SEPARATOR}${packIdentifier(thingId)}`
  }

  return `${sslugify(theThing)}${SEPARATOR}${packIdentifier(thingId)}`
}

export const unSlug = (slug: string): string => {
  const app = R.compose(unPackIdentifier, R.last, R.split(SEPARATOR)) as (s: string) => string
  return app(slug)
}
