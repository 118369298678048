export const localStorageHelper = {
  get(key: string) {
    if (typeof window !== 'undefined' && 'localStorage' in window) {
      const stored = localStorage.getItem(key)
      return stored == null ? undefined : JSON.parse(stored)
    }
    return null
  },
  set<T>(key: string, value: T) {
    if (typeof window !== 'undefined' && 'localStorage' in window) {
      localStorage.setItem(key, JSON.stringify(value))
    }
  },
  remove(key: string) {
    if (typeof window !== 'undefined' && 'localStorage' in window) {
      localStorage.removeItem(key)
    }
  },
}

export const sessionStorageHelper = {
  get(key: string) {
    if (typeof window !== 'undefined' && 'sessionStorage' in window) {
      const stored = sessionStorage.getItem(key)
      return stored == null ? undefined : JSON.parse(stored)
    }
    return null
  },
  set<T>(key: string, value: T) {
    if (typeof window !== 'undefined' && 'sessionStorage' in window) {
      sessionStorage.setItem(key, JSON.stringify(value))
    }
  },
  remove(key: string) {
    if (typeof window !== 'undefined' && 'sessionStorage' in window) {
      sessionStorage.removeItem(key)
    }
  },
}
