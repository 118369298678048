import { CwsGrid, CwsGridCol, CwsGridRow, CwsIcon, CwsP, CwsSelect, CwsSelectOption, CwsSpan } from '@components/cws'
import type { KeyLabelOption } from '@ui/components/Selector/Selector'
import type { Dispatch, FC, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import { useTranslate } from '@hooks/useTranslation'
import type { OrderKey } from '@model/product/ProductSearchResult'
import { PerPage } from '@components/product/Parameter/PerPage'
import { localStorageHelper } from '@utils/localStorageHelper'
const SORTING_STORY = 'SORTING_STORY'
export interface ParameterProps {
  label: string
  itemsPerPageValue?: string
  setItemsPerPageValue?: Dispatch<SetStateAction<string>>
  options: KeyLabelOption[]
  setIsSidePanelOpen?: Dispatch<SetStateAction<boolean>>
  isSidePanelOpen?: boolean
  handleSelect(arg0: string): void
  isCategoryPage?: boolean
  filterButtonHandler?: Dispatch<SetStateAction<'filters' | 'sort' | null>>
}

const Parameter: FC<ParameterProps> = (props) => {
  const {
    label,
    options,
    handleSelect,
    setIsSidePanelOpen,
    itemsPerPageValue,
    setItemsPerPageValue,
    isSidePanelOpen,
    isCategoryPage,
    filterButtonHandler,
  } = props
  const valueStory = localStorageHelper.get(SORTING_STORY)
  const initialValue = valueStory || (options.length && options[0].key) || 'none'
  const [value, setValue] = useState<OrderKey>(initialValue as OrderKey)
  useEffect(() => {
    if (value) {
      localStorageHelper.set(SORTING_STORY, value)
      handleSelect(value)
    }
  }, [value])

  const { translate } = useTranslate()
  return (
    <CwsGrid width="fluid">
      <CwsGridRow verticalAlign="center" justifyContent="center">
        <CwsGridCol col={'auto'} lg={isSidePanelOpen ? 3 : 6} sm={3} md={3}>
          {setIsSidePanelOpen && isCategoryPage && (
            <div
              className="flex gap-2
                             items-center cursor-pointer rounded border-2
                             border-dark-grey p-2 px-4  bg-blend-color-burn w-fit  mt-7 device360:p-2.5 tablet:p-2 minDevice:px-2"
              onClick={() => setIsSidePanelOpen((prevState) => !prevState)}
            >
              <CwsIcon size="16px" icon="funnel" onClick={filterButtonHandler} />
              <CwsSpan color="midnight" className="minDevice:hidden tablet:block">
                {isSidePanelOpen
                  ? translate('w2.products.page.filter.hide')
                  : translate('w2.products.page.filter.show')}
              </CwsSpan>
            </div>
          )}
        </CwsGridCol>
        <CwsGridCol lg={isSidePanelOpen ? 9 : 6} sm={9} md={9}>
          <CwsGrid width="fluid">
            <CwsGridRow justifyContent="right">
              <CwsGridCol col={7} xs={6} sm={7}>
                {itemsPerPageValue && setItemsPerPageValue && (
                  <div className="flex flex-col items-end">
                    <div className="device360:min-w-full tablet:min-w-fit max-w-fit">
                      <CwsP size="small">
                        {translate('w2.category.count.items')} / {translate('w2.category.count.page')} :
                      </CwsP>
                      <PerPage {...{ setItemsPerPageValue, itemsPerPageValue }} />
                    </div>
                  </div>
                )}
              </CwsGridCol>
              <CwsGridCol col={5} xs={6} sm={5}>
                <CwsSelect value={value} onChange={setValue} id="category-items-sort" label={`${label}: `}>
                  {options.map(({ label, translationKey, key }) => (
                    <CwsSelectOption key={key} value={key} title={translationKey ? translate(translationKey) : label} />
                  ))}
                </CwsSelect>
              </CwsGridCol>
            </CwsGridRow>
          </CwsGrid>
        </CwsGridCol>
      </CwsGridRow>
    </CwsGrid>
  )
}

export default Parameter
