import type { Color } from '@ui/system'
import clsx from 'clsx'
import type { DetailedHTMLProps, HTMLAttributes, PropsWithChildren } from 'react'
import { createElement, useMemo } from 'react'
import styles from './Typography.module.scss'

/**
 * Helper type, constructs html detailed props of T type from props map
 */
type ElementHTMLProps<T extends keyof TypographyElementPropsMap> = DetailedHTMLProps<
  HTMLAttributes<TypographyElementPropsMap[T]>,
  TypographyElementPropsMap[T]
>

/**
 * UI Typography component "as" type map helper
 */
interface TypographyElementPropsMap {
  p: HTMLParagraphElement
  span: HTMLSpanElement
  pre: HTMLPreElement
  h1: HTMLHeadingElement
  h2: HTMLHeadingElement
  h3: HTMLHeadingElement
  h4: HTMLHeadingElement
}

/**
 * UI Typography component variant
 */
export type TypographyVariant =
  | 'body'
  | 'heading'
  | 'label'
  | 'heading1'
  | 'heading2'
  | 'heading3'
  | 'body-mobile'
  | 'heading4'

/**
 * UI Typography component props
 */
export interface TypographyProps<T extends keyof TypographyElementPropsMap> extends Omit<ElementHTMLProps<T>, 'color'> {
  as?: T
  variant?: TypographyVariant
  color?: Color
}

/**
 * Default vartiant for element tag name map
 */
const defaultVariantForElement: { [key in keyof TypographyElementPropsMap]?: TypographyVariant } = {
  h1: 'heading1',
  h2: 'heading2',
  h3: 'heading3',
  h4: 'heading4',
  span: 'body',
  p: 'body',
}

/**
 * UI Typography
 * @param props Props
 * @returns UI Typography component
 */
const Typography = <T extends keyof TypographyElementPropsMap = 'p'>(props: PropsWithChildren<TypographyProps<T>>) => {
  const { className, color = 'midnight' } = props

  const elementTag = props.as || 'p'
  const variant = props.variant || defaultVariantForElement[elementTag] || 'body'

  const composedClassName = useMemo(() => {
    const variantClass = (styles as any)[variant]
    const overrideClass = className || ''

    return clsx(variantClass, overrideClass, color && `text-${color}`)
  }, [variant, className, color])

  return createElement(elementTag, {
    ...(props as ElementHTMLProps<T>),
    className: composedClassName,
    as: undefined,
    variant: undefined,
  })
}

export default Typography
