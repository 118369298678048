import type { AlertOptions } from '@context/alert'
import { CwsA, CwsAlert, CwsGrid, CwsGridCol, CwsGridRow, CwsP } from '@components/cws'
import { useTranslate } from '@hooks/useTranslation'

interface AppAlertProps {
  options?: AlertOptions | null
  onHideAlert?: () => any
}

export const AppAlert = ({ options, onHideAlert }: AppAlertProps) => {
  const { translate } = useTranslate()
  if (!Boolean(options)) {
    return null
  }

  const { text, error } = options ?? {}

  const errorText = !error || error === true ? '' : (error as string)

  return (
    <CwsAlert border type={Boolean(error) ? 'error' : 'success'}>
      <CwsGrid>
        <CwsGridRow noGutter verticalAlign="center">
          <CwsGridCol className="cws-bb cws-bb-0-sm cws-br-sm cws-b-style-dashed cws-b-color-midnight cws-b-opacity-25">
            <CwsGridRow noGutter verticalAlign="center">
              <CwsGridCol className="cws-pb-12 cws-pb-0-sm cws-pr-24-sm">
                <CwsP hideSpacing size="normal" weight="500">
                  {`${text}`}
                </CwsP>
                {Boolean(errorText) && (
                  <CwsP hideSpacing size="small">
                    {`${errorText}`}
                  </CwsP>
                )}
              </CwsGridCol>
            </CwsGridRow>
          </CwsGridCol>
          <CwsGridCol className="cws-mt-12 cws-mt-0-sm cws-ml-24-sm" col="12" sm="auto">
            <CwsGridRow justifyContent="center" noGutter verticalAlign="center">
              <CwsGridCol className="cws-ml-24-sm" col="auto">
                <CwsA
                  // data-alert-id="alert-primary"
                  hideArrow
                  // inline
                  size="small"
                  // href=""
                  onClick={() => {
                    onHideAlert?.()
                  }}
                >
                  {translate('w2.toast.message.dismiss')}
                </CwsA>
              </CwsGridCol>
            </CwsGridRow>
          </CwsGridCol>
        </CwsGridRow>
      </CwsGrid>
    </CwsAlert>
  )
}
