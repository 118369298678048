import type { FC, ReactNode } from 'react'
import Link from 'next/link'
import { Typography } from '@ui/components'

const ProductViewShare: FC = () => {
  const renderItem = (social: Social) => {
    const { name, href, icon } = social

    return (
      <li>
        <Link
          href={href}
          passHref
          className="list-none flex flex-row space-x-sm items-center">
          {icon}
          <Typography as="span" variant="body">
            <span className="font-medium">{name}</span>
          </Typography>
        </Link>
      </li>
    )
  }

  return <ul className="flex flex-row space-x-xl">{SOCIALS.map(renderItem)}</ul>
}

export default ProductViewShare

export interface Social {
  name: string
  href: string
  icon: ReactNode
}

export const SOCIALS: Social[] = [
  { name: 'Facebook', href: '#', icon: <></> },
  { name: 'Email', href: '#', icon: <></> },
  { name: 'Print', href: '#', icon: <></> },
]
