import { FC } from 'react'

export const Plus: FC = () => {
  return (
    <svg width="10" height="11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.583.265a.417.417 0 0 0-.416.416v3.75H.417A.417.417 0 0 0 0 4.848v.833c0 .23.187.417.417.417h3.75v3.75c0 .23.186.417.416.417h.834c.23 0 .416-.187.416-.417v-3.75h3.75c.23 0 .417-.187.417-.417v-.833a.417.417 0 0 0-.417-.417h-3.75V.681a.417.417 0 0 0-.416-.416h-.834z"
          fill="#004152"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="translate(0 .265)" d="M0 0h10v10H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Plus
