import { FC, memo } from 'react'
import { Typography } from '@ui/components'
import RatingStars from '../Rating/RatingStars'
import { ProductReview as Review } from '@model/product'
import { getFormattedDate } from '@utils/date'
import { useRouter } from 'next/router'

export interface ProductReviewProps {
  review: Review
}

const ProductReview: FC<ProductReviewProps> = (props) => {
  const { review } = props
  const { dateAdded, rating, reviewText: thoughts, custom1: username } = review
  const { locale } = useRouter()
  return (
    <div className="flex flex-col flex-1-auto space-y-lg">
      <p className="text-base font-normal text-midnight">{`${username} | ${getFormattedDate(
        dateAdded,
        locale ?? 'et'
      )}`}</p>
      <RatingStars filledCount={rating} count={5} />
      <p className="text-base font-normal text-midnight">{thoughts}</p>
    </div>
  )
}

export default memo(ProductReview)
