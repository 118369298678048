import { ChangeEvent, FC, KeyboardEvent, useEffect, useState } from 'react'
import s from './DateInput.module.scss'
import { CwsDivider, CwsP } from '@components/cws'
const getLastDay = function (y: number, m: number) {
  return new Date(y, m, 0).getDate()
}
export const excludeCharsInNumberInputHandler = (e: KeyboardEvent<HTMLInputElement>) => {
  let invalidChars = ['-', '+', 'e']

  if (invalidChars.includes(e.key)) {
    e.preventDefault()
  }
}
interface DateInputProps {
  getDate: (date: string) => void
  label?: string
}
const DateInput: FC<DateInputProps> = ({ getDate, label }) => {
  const [year, setYear] = useState('')
  const [month, setMonth] = useState('')
  const [day, setDay] = useState('')

  useEffect(() => {
    if (year.length && month.length && day.length) {
      const currentDay = +day >= 10 ? +day : +day + 1
      const date = new Date(`${year}-${month}-${currentDay}`)
      getDate(date.toISOString())
    }
  }, [year, month, day])

  const changeYear = (e: ChangeEvent<HTMLInputElement>) => {
    setDay('')
    setMonth('')
    if ((+e.target.value < 1900 || +e.target.value > new Date().getFullYear()) && e.target.value.length === 4)
      setYear('1900')
    else setYear(e.target.value)
  }
  const changeMonth = (e: ChangeEvent<HTMLInputElement>) => {
    setDay('')
    if (e.target.value.length > 2 || !year.length) {
      return false
    }
    if ((+e.target.value < 1 || +e.target.value > 12) && e.target.value.length) setMonth('1')
    else setMonth(e.target.value)
  }
  const changeDay = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 2 || !year.length || !month.length) {
      return
    }
    const maxDay = getLastDay(+year, +month)

    if ((+e.target.value < 1 || +e.target.value > maxDay) && e.target.value.length) setDay('1')
    else setDay(e.target.value)
  }
  return (
    <>
      {label && <CwsP size="small">{label}</CwsP>}
      <div className={s.dateInput}>
        <input
          maxLength={4}
          placeholder="yyyy"
          value={year}
          pattern="/^-?\d+\.?\d*$/"
          type="number"
          onKeyDown={excludeCharsInNumberInputHandler}
          onChange={changeYear}
        />
        <CwsDivider orientation="vertical" />
        <input
          maxLength={2}
          placeholder="mm"
          value={month}
          pattern="/^-?\d+\.?\d*$/"
          type="number"
          onKeyDown={excludeCharsInNumberInputHandler}
          onChange={changeMonth}
        />
        <CwsDivider orientation="vertical" />
        <input
          maxLength={2}
          placeholder="dd"
          value={day}
          pattern="/^-?\d+\.?\d*$/"
          type="number"
          onKeyDown={excludeCharsInNumberInputHandler}
          onChange={changeDay}
        />
      </div>
    </>
  )
}
export default DateInput
