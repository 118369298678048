import type { FC } from 'react'
import PropellerSvg from './assets/Propeller.svg'

const Loader: FC = () => {
  return (
    <div>
      <PropellerSvg width={32} height={32} />
    </div>
  )
}

export default Loader
