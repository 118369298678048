import clsx from 'clsx'
import type { DetailedHTMLProps, FC, InputHTMLAttributes } from 'react'
import s from './RadioButton.module.scss'

export interface RadioButtonProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label: string
  labelClassName?: string
}

const RadioButton: FC<RadioButtonProps> = (props) => {
  const { id, label, checked, className, onClick, children, labelClassName, disabled, ...restInputProps } = props

  return (
    <div className={clsx(s.container)} onClick={disabled ? undefined : onClick}>
      <input
        type="radio"
        id={id}
        defaultChecked={checked}
        className={clsx(s.radio, checked && s.checked, className, 'disabled:opacity-50')}
        disabled={disabled}
        {...restInputProps}
      />
      {/* {!!label && !children && ( */}
      <label className={clsx(s.label, labelClassName)} htmlFor={id}>
        {/* {children} */}
        {children ? children : label}
      </label>
      {/* )} */}
    </div>
  )
}

export default RadioButton
