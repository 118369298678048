import type { MouseEventHandler } from 'react'

export const clickSSOButton: MouseEventHandler<HTMLAnchorElement> = (event) => {
  try {
    event.preventDefault()
    event.stopPropagation()

    if (window.SSO) {
      window.SSO.open()
    }

    window.scrollTo({ top: 0, behavior: 'smooth' })
  } catch (e) {}
}
