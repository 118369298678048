import type { FC, PropsWithChildren } from 'react'
import { CwsTag } from '@components/cws'
import moduleStyles from './Badge.module.scss'

export interface BadgeProps {
  size?: 'sm'
  type?: 'regular'
}

const Badge: FC<PropsWithChildren<BadgeProps>> = (props) => {
  const { type, children } = props
  return (
    <CwsTag className="cws-mb-xs cws-mr-tiny" type={type}>
      {children}
    </CwsTag>
  )
}

export default Badge
