import type { RedisOptions } from 'ioredis'
import Redis from 'ioredis'
import type IORedis from 'ioredis'
import logger from '@utils/logging'

export const onBrowser = 'window' in global
export const expireTimeInSeconds = process.env.REDIS_EXPIRE_SECONDS || 600
export const expireLongerTimeInSeconds = process.env.REDIS_LONGER_EXPIRE_SECONDS || 3600

const connectOptions: RedisOptions = {
  port: parseFloat((process.env.REDIS_PORT as string) || '6379'), // Redis port
  host: process.env.REDIS_URL || 'dev-redis-cluster.fleet.zone',
  family: 4, // 4 (IPv4) or 6 (IPv6)
  password: process.env.REDIS_PASSWORD || 'GfRtA2kzsJEvGGpy',
  db: parseFloat((process.env.REDIS_DB as string) || '8'),
}

logger.debug(`Redis connection ${connectOptions.host}`)
// why? not to init redis while on browser
const redis: IORedis.Redis | null = onBrowser ? null : new Redis(connectOptions)

export const setData = (cKey: string, data: any, expireTime = expireTimeInSeconds): Promise<IORedis.Ok | null> => {
  if (!redis) throw new Error('redis is null')

  try {
    return redis.set(cKey, JSON.stringify(data, null, 0), 'ex', expireTime)
  } catch (e) {
    throw e
  }
}

export const getData = async <T>(cKey: string): Promise<T | null> => {
  if (!redis) throw new Error('redis is null')

  try {
    const cached = await redis.get(cKey)
    if (cached) return JSON.parse(cached) as T
    else return null
  } catch (e) {
    throw e
  }
}
