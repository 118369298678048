import { CwsCheckbox, CwsGridCol, CwsGridRow, CwsP } from '@components/cws'
import type { ChangeEvent, FC } from 'react'
import { useCallback, useMemo } from 'react'
import * as R from 'ramda'
import { useTranslate } from '@hooks/useTranslation'
import { getPriceValueWithCurrency } from '@utils/currency'
import { useInitialData } from '@hooks/useInitialData'
import { useCurrency } from '@hooks/useCurrency'
import { getFormattedPrice } from '@utils/price'
import { useCart } from '@hooks/useCart'
import type { CartItem } from '@model/cart/CartItem'
import type { Product } from '@model/product'

export const cartIncludesWsBox = R.find(R.propEq('sku', 'WSBOX'))

export const WrapAsGift: FC<{ giftWrapperProduct: Product }> = ({ giftWrapperProduct }) => {
  const { translate } = useTranslate()
  const { currency } = useCurrency()
  const { currencies } = useInitialData()

  const { addToCartWithSideEffects, removeItem, cart: cartItems = [] } = useCart('shopping cart')
  const isGiftBoxProductInBasket = useMemo(
    () => Array.isArray(cartItems) && Boolean(cartIncludesWsBox(cartItems)),
    [cartItems]
  )

  const handleAddRemove = useCallback(
    async (checked: boolean) => {
      if (checked && isGiftBoxProductInBasket) return
      if (!checked && isGiftBoxProductInBasket) {
        const theItem = cartIncludesWsBox(cartItems) as CartItem
        await removeItem(theItem)
      } else if (checked && !isGiftBoxProductInBasket) {
        await addToCartWithSideEffects({ productId: giftWrapperProduct.id, sku: giftWrapperProduct.sku, quantity: 1 })
      }
    },
    [addToCartWithSideEffects, removeItem, giftWrapperProduct, isGiftBoxProductInBasket, cartItems]
  )

  const price = getPriceValueWithCurrency(giftWrapperProduct?.priceExTax, currency, currencies)

  return (
    <>
      <CwsGridRow verticalAlign="center">
        <CwsGridCol col="12">
          <CwsP>{translate('w2.cart.giftbox.description')}</CwsP>
        </CwsGridCol>
      </CwsGridRow>
      <CwsGridRow verticalAlign="center">
        <CwsGridCol col="6" className="flex flex-row justify-start">
          <CwsCheckbox
            checked={isGiftBoxProductInBasket}
            className="my-4"
            id="wrap-as-gift-control"
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleAddRemove(e.target.checked)}
          >
            {translate('cart.wrap.gift.title')}
          </CwsCheckbox>
        </CwsGridCol>
        <CwsGridCol col="6" className="flex flex-row justify-end">
          <CwsP weight="500">{getFormattedPrice(price, currency)}</CwsP>
        </CwsGridCol>
      </CwsGridRow>
    </>
  )
}

export default WrapAsGift
