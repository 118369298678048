import type { FC } from 'react'
import { useMemo } from 'react'
import { CwsGridCol, CwsGridRow, CwsSpan } from '@components/cws'
import { useCartState } from '@components/cart/State/state'
import { RadioButton } from '@ui/components'
import type { KonakartCarrierCode } from '@model/order/ShippingQuote'
import type { DeliveryMethod, DeliveryMethodWithQuote } from '@api/ecw/models/DeliveryMethod'
import { useTranslate } from '@hooks/useTranslation'
import { useCurrency } from '@hooks/useCurrency'
import { getFormattedPrice } from '@utils/price'

interface CourierListProps {
  disabled?: boolean
  codesMap: Record<KonakartCarrierCode, DeliveryMethodWithQuote>
  deliveryMethodsForReturnOrder?: DeliveryMethod[]
}

export const CourierList: FC<CourierListProps> = ({ codesMap, deliveryMethodsForReturnOrder, disabled = false }) => {
  const {
    dispatch,
    state: { deliveryMethodsByCountry, deliveryCountry, deliveryMethods },
  } = useCartState()

  const { translate } = useTranslate()
  const { currency } = useCurrency()
  const selectedDeliveryMethod = useMemo(
    () => deliveryMethodsByCountry[deliveryCountry],
    [deliveryMethodsByCountry, deliveryCountry]
  )
  const couriers = useMemo(() => {
    const isParcelPoint = selectedDeliveryMethod?.parcelMachine
    const isExpress = selectedDeliveryMethod?.express

    return (deliveryMethodsForReturnOrder ?? deliveryMethods)
      .filter((dm) => dm.parcelMachine === isParcelPoint)
      .filter((dm) => dm.express === isExpress)
  }, [selectedDeliveryMethod, deliveryMethods, deliveryMethodsForReturnOrder])
  if (selectedDeliveryMethod?.express) return null

  return (
    <CwsGridRow noGutter className="w-full">
      <CwsGridCol>
        <ul className="flex flex-col gap-y-6 lg:gap-y-4 w-full">
          {couriers.map((dm) => (
            <li key={dm.konakartCarrierCode} className="flex justify-between">
              <RadioButton
                disabled={disabled}
                label={dm.konakartCarrierCode}
                checked={selectedDeliveryMethod?.konakartCarrierCode === dm.konakartCarrierCode}
                value={dm.konakartCarrierCode}
                onClick={() => {
                  dispatch({ type: 'selectPickUpPointId', payload: '0' })
                  dispatch({
                    type: 'selectDeliveryMethodForCountry',
                    payload: { country: deliveryCountry, method: codesMap[dm.konakartCarrierCode] },
                  })
                }}
              >
                {translate(`courier.name.${dm.konakartCarrierCode}`)}
              </RadioButton>
              {!deliveryMethodsForReturnOrder && codesMap[dm.konakartCarrierCode] && (
                <CwsSpan color="midnight" weight="500">
                  {codesMap[dm.konakartCarrierCode].quote?.cost
                    ? getFormattedPrice(codesMap[dm.konakartCarrierCode].quote.cost, currency)
                    : translate('basket.delivery.free')}
                </CwsSpan>
              )}
            </li>
          ))}
        </ul>
        <div className="flex flex-row min-h-[2px] min-w-[35px] lg:min-w-[106px] lg:mb-8 mx-3 lg:mx-2"></div>
      </CwsGridCol>
    </CwsGridRow>
  )
}

export default CourierList
