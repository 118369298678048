import clsx from 'clsx'
import type { DetailedHTMLProps, FC, HTMLAttributes } from 'react'
import CheckMark from './assets/CheckMark.svg'
import s from './Check.module.scss'
import { CwsSpan } from '@components/cws'

type HTMLInputProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export interface CheckProps extends HTMLInputProps {
  label?: string
  checked?: boolean
}

// should not be used
const Check: FC<CheckProps> = (props) => {
  const { label, children, className, checked = false, ...restInputProps } = props

  return (
    <div className="flex flex-row items-center space-x-2 font-medium text-base leading-5.5 cursor-pointer">
      <div className={clsx(s.box, className)} {...restInputProps}>
        {checked && <CheckMark />}
      </div>
      {label && (
        <CwsSpan weight="500" color="midnight">
          {label}
        </CwsSpan>
      )}
      {children}
    </div>
  )
}

export default Check
