import { FC, SVGProps } from 'react'
import { Color } from '@ui/system'
import clsx from 'clsx'

export interface ButtonArrowProps extends Omit<SVGProps<SVGSVGElement>, 'color'> {
  color?: Color | undefined
}

export const ButtonArrow: FC<ButtonArrowProps> = (props) => {
  const { color = 'action', className, ...restProps } = props

  return (
    <svg
      width="10"
      height="11"
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(`inline color-${color}`, className)}
      {...restProps}
    >
      <path
        d="M9.90031 5.68519C10.0332 5.55227 10.0332 5.36618 9.90031 5.23325L7.24183 2.57478C7.10891 2.44185 6.89623 2.44185 6.78989 2.57478L6.2582 3.10647C6.12527 3.21281 6.12527 3.42549 6.2582 3.55841L7.61402 4.8079L0.319017 4.8079C0.132924 4.8079 3.48617e-08 4.9674 2.78893e-08 5.12691L0 5.76495C-8.13439e-09 5.95104 0.132924 6.08396 0.319017 6.08396H7.61402L6.2582 7.36003C6.12527 7.49295 6.12527 7.70563 6.2582 7.81197L6.78989 8.34367C6.89623 8.47659 7.10891 8.47659 7.24183 8.34367L9.90031 5.68519Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ButtonArrow
