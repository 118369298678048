import { ButtonArrow } from '@ui/icons/button'
import { Chevron } from '@ui/icons/common'
import { usePaginationLogic } from '@utils/pagination'
import clsx from 'clsx'
import { DetailedHTMLProps, FC, LiHTMLAttributes, useMemo } from 'react'

export interface RoundPaginationProps {
  page: number
  totalPages: number
  onPageClick?: (page: number) => any
  buttonControls?: boolean
  /**
   * 5 by default
   */
  maxPagesInView?: number
}

const Li: FC<
  DetailedHTMLProps<LiHTMLAttributes<HTMLLIElement>, HTMLLIElement> & { isSelected?: boolean; disabled?: boolean }
> = (props) => {
  const { isSelected, className, disabled, ...elProps } = props

  return (
    <li
      className={clsx(
        'flex h-7 w-7 rounded-full items-center text-center text-sm font-medium justify-center select-none cursor-pointer transition-opacity',
        isSelected ? 'bg-midnight text-white' : 'text-midnight',
        disabled ? 'opacity-50 cursor-default' : 'hover:opacity-70',
        // isSelected ? 'bg-midnight text-white' : 'bg-green-100 text-midnight',
        className
      )}
      {...elProps}
    />
  )
}

export const RoundPagination: FC<RoundPaginationProps> = (props) => {
  const { page, totalPages, onPageClick, buttonControls = true, maxPagesInView = 5 } = props

  const currentPage = page

  const { pagesToRender, leftOverflow, rightOverflow } = usePaginationLogic(totalPages, page, maxPagesInView)
  return (
    <div>
      <ul className="flex flex-row items-center gap-x-2">
        {buttonControls && (
          <Li
            key="left-button-control"
            onClick={onPageClick?.bind(this, page === 1 ? 1 : page - 1)}
            className="mr-1.5"
            disabled={page === 1}
          >
            <Chevron direction="left" className="w-5 h-3.5" />
          </Li>
        )}
        {leftOverflow && (
          <Li key={'overflow-left'} onClick={onPageClick?.bind(this, 1)}>
            {`...`}
          </Li>
        )}
        {pagesToRender.map((page) => {
          const isSelected = currentPage === page
          return (
            <Li key={page} isSelected={isSelected} onClick={onPageClick?.bind(this, page)}>
              {page}
            </Li>
          )
        })}
        {rightOverflow && (
          <Li className="overflow-right" key={page} onClick={onPageClick?.bind(this, totalPages)}>
            {`...`}
          </Li>
        )}
        {buttonControls && (
          <Li
            key="right-button-control"
            onClick={onPageClick?.bind(this, page === totalPages ? page : page + 1)}
            className="ml-1.5"
            disabled={page === totalPages}
          >
            <Chevron direction="right" className="w-5 h-3.5" />
          </Li>
        )}
      </ul>
    </div>
  )
}

export default RoundPagination
