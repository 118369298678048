import { useMemo } from 'react'
import type { OrderProduct } from '@model/order/OrderProduct'
import * as R from 'ramda'

const calc = R.compose(
  R.sum,
  R.map((op: OrderProduct) => {
    const price = op.price0
    return op.quantity * (price || R.pathOr(0, ['product', 'priceExTax'])(op))
  })
)

export const useOrderNormalPricesSum = (orderProducts: OrderProduct[]): number => {
  return useMemo(() => calc(orderProducts), [orderProducts])
}
