import { FC, useCallback } from 'react'
import { Typography } from '@ui/components'
import s from './QuantitySpinner.module.scss'
import clsx from 'clsx'

export interface QuantitySpinnerProps {
  quantity: number
  isMaxQuantity?: boolean
  onChange?: (newQuantity: number) => any
}

const QuantitySpinner: FC<QuantitySpinnerProps> = (props) => {
  const { quantity, onChange, isMaxQuantity } = props

  const handleDecrement = useCallback(() => {
    if (quantity === 1) {
      return
    }

    onChange?.(quantity - 1)
  }, [quantity, onChange])

  const handleIncrement = useCallback(() => {
    if (isMaxQuantity) {
      return
    }

    onChange?.(quantity + 1)
  }, [quantity, onChange, isMaxQuantity])

  return (
    <div
      className="
            flex flex-row bg-midnight items-center
            border-midnight border overflow-hidden rounded-3xl
        "
    >
      <button className={clsx(s.button, quantity === 1 && s.disabled)} onClick={handleDecrement}>
        -
      </button>
      <Typography as="span" variant="body" color="white" className="mx-3 font-medium inline-block w-4 text-center">
        {quantity}
      </Typography>
      <button className={clsx(s.button, isMaxQuantity && s.disabled)} onClick={handleIncrement}>
        +
      </button>
    </div>
  )
}

export default QuantitySpinner
