import type { FC, PropsWithChildren, ReactNode } from 'react'
import { useCallback, useMemo, useRef, useState } from 'react'
import { Input } from '@ui/components'
import s from './Dropdown.module.scss'
import type { InputProps } from '../Input/Input'
import clsx from 'clsx'
import type { ClickAwayHandler } from '@ui/hooks/useClickAway'
import { useClickAway } from '@ui/hooks/useClickAway'

export interface BaseOption {
  key: string
  name: string
}

export interface DropdownProps<T extends { key: string; name: string } = BaseOption> extends InputProps {
  items?: T[]
  value?: string
  emptyValue?: string
  open?: boolean
  editable?: boolean
  // emptyState?: ReactNode
  DropdownMenuItemComponent?: FC<{ option: T }>
  ValueComponent?: FC<{ option: T }>

  inputClassName?: string
  adornmentIcon?: ReactNode
  adornmentIconClassName?: string
}

export const Dropdown = <T extends BaseOption>(props: DropdownProps<T>) => {
  const {
    items = [],
    ref,
    open = false,
    emptyValue = 'Choose ...',
    editable = false,
    DropdownMenuItemComponent,
    containerClassName,
    wrapperClassName,
    inputClassName,
    endAdornment,
    adornmentIcon,
    adornmentIconClassName,
    ...restInputProps
  } = props

  const [openState, setOpenState] = useState(open)

  const value = props.value ?? emptyValue

  const wrapperRef = useRef<HTMLDivElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)

  const handleClickAway = useCallback<ClickAwayHandler>((event) => {
    // We need to check if element is inside wrapper node, not container node
    // Because container node can contain aditional label or something other
    const isClickedOutsideContainer = wrapperRef.current?.contains(event.target as Node | null)

    if (!isClickedOutsideContainer) {
      setOpenState(false)
    }
  }, [])

  useClickAway(menuRef, handleClickAway)

  const downButtonNode = useMemo(() => {
    return (
      <div
        className={clsx(
          'flex flex-row items-center justify-center pr-4 text-midnight cursor-pointer',
          openState && 'scale-y-[-1]'
        )}
      >
        {adornmentIcon ?? (
          <svg
            width="16"
            height="17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={clsx(adornmentIconClassName)}
          >
            <path
              d="M.197 5.679a.667.667 0 0 1 0-.943l.943-.943c.26-.26.682-.26.943 0L8 9.711l5.918-5.918c.26-.26.683-.26.943 0l.943.943c.26.26.26.683 0 .943l-7.33 7.33a.665.665 0 0 1-.474.196.664.664 0 0 1-.472-.196L.198 5.68z"
              fill="currentColor"
            />
          </svg>
        )}
      </div>
    )
  }, [openState, adornmentIconClassName, adornmentIcon])

  const menuNode = useMemo(() => {
    const isEmpty = !items || items.length === 0

    const emptyStateNode = <EmptyState />

    const itemsNode = (
      <>
        {items.map((option) =>
          DropdownMenuItemComponent ? (
            <DropdownMenuItemComponent key={option.key} option={option} />
          ) : (
            <DropdownMenuItem
              key={option.key}
              onClick={() => {
                setOpenState(false)
              }}
            >
              {option.name}
            </DropdownMenuItem>
          )
        )}
      </>
    )

    return (
      <div
        ref={menuRef}
        className={clsx(
          'w-full bg-white h-auto absolute top-full mt-1 z-10 border-dark-grey border',
          s['dropdown-menu']
        )}
      >
        {isEmpty ? emptyStateNode : itemsNode}
      </div>
    )
  }, [items, DropdownMenuItemComponent, restInputProps])

  return (
    <Input
      {...restInputProps}
      ref={() => ref}
      endAdornment={endAdornment ?? downButtonNode}
      boxShadow={false}
      className={clsx(!editable && 'cursor-pointer', inputClassName)}
      wrapperClassName={clsx(s['outer-shadow'], 'relative', !editable && 'cursor-pointer', wrapperClassName)}
      containerClassName={clsx('relative', containerClassName)}
      value={value}
      readOnly={!editable}
      wrapperProps={{
        ref: wrapperRef,
        onClick: () => {
          setOpenState((prevState) => !prevState)
        },
      }}
    >
      {openState && menuNode}
    </Input>
  )
}

export default Dropdown

interface DropdownMenuItemProps extends PropsWithChildren {
  onClick?: () => void
}

const DropdownMenuItem: FC<DropdownMenuItemProps> = (props) => {
  const { children, onClick } = props

  return (
    <li
      className="
        list-none px-4 py-2.5 border-b border-medium-grey cursor-pointer hover:bg-medium-grey
        text-midnight font-medium text-base leading-5.5
    "
      onClick={onClick}
    >
      {children}
    </li>
  )
}

const EmptyState: FC = () => {
  return (
    <div className="px-4 py-2.5 font-medium text-base leading-5.5 text-center text-group-dark-gray">
      <span>No options</span>
    </div>
  )
}
