import { Typography } from '@ui/components'
import clsx from 'clsx'
import type {
  ChangeEvent,
  ChangeEventHandler,
  DetailedHTMLProps,
  HTMLAttributes,
  ReactNode,
  TextareaHTMLAttributes,
} from 'react'
import { forwardRef, useCallback } from 'react'

type HTMLProps<TElement extends HTMLElement> = DetailedHTMLProps<HTMLAttributes<TElement>, TElement>
type HTMLTextAreaProps = DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>
type WithoutClassName<T extends HTMLProps<any>> = Omit<T, 'className'>

export type InputTextAreaChangeHandler = (newValue: string, event: ChangeEvent<HTMLTextAreaElement>) => any

/**
 * Input's props inherit HTML Input Element props (html attributes)
 */
export interface InputTextAreaProps extends Omit<HTMLTextAreaProps, 'value' | 'onChange'> {
  label?: string
  boxShadow?: boolean

  value?: string
  onChange?: InputTextAreaChangeHandler

  containerClassName?: string
  wrapperClassName?: string

  containerProps?: WithoutClassName<HTMLProps<HTMLDivElement>>
  wrapperProps?: WithoutClassName<HTMLProps<HTMLDivElement>>

  startAdornment?: ReactNode
  endAdornment?: ReactNode
}

/**
 *
 * @param props Props
 * @returns UI's InputTextArea component
 */
export const InputTextArea = forwardRef<HTMLTextAreaElement, InputTextAreaProps>((props, ref) => {
  const {
    className,
    label,
    children,
    startAdornment,
    endAdornment,
    boxShadow = true,
    containerClassName,
    wrapperClassName,
    containerProps,
    wrapperProps,
    value,
    onChange,
    ...restProps
  } = props

  const handleInputChange = useCallback<ChangeEventHandler<HTMLTextAreaElement>>(
    (event) => {
      onChange?.(event?.target?.value, event)
    },
    [onChange]
  )

  return (
    <div className={clsx('flex', containerClassName ? containerClassName : 'flex-col')} {...(containerProps || {})}>
      {label && (
        <Typography as="p" variant="body" className={'mb-sm'}>
          {label}
        </Typography>
      )}
      <div
        className={clsx(
          // s['input-wrapper'],
          'flex flex-row flex-1-auto border border-dark-grey',
          boxShadow && 'shadow-[inset_0_2px_3px_0_rgba(0,0,0,0.2)]',
          wrapperClassName
        )}
        {...(wrapperProps || {})}
      >
        {startAdornment && <div className="flex">{startAdornment}</div>}
        <textarea
          ref={ref}
          className={clsx(
            // s.input,
            'placeholder:font-normal',
            'flex-1-auto w-full focus:outline-none bg-transparent',
            'flex-1-auto font-medium text-base leading-5 h-5 w-full px-4 my-2.5 focus:outline-none bg-transparent',
            className
          )}
          spellCheck={false}
          onChange={handleInputChange}
          value={value ?? ''}
          {...restProps}
        />
        {endAdornment && <div className="flex">{endAdornment}</div>}
      </div>
      {children}
    </div>
  )
})

InputTextArea.displayName = 'InputTextArea'

export default InputTextArea
