import sanitize from 'sanitize-html'

export const safeHtml = (html: string) =>
  sanitize(html, {
    allowedTags: ['b', 'i', 'em', 'strong', 'a', 'br', 'p', 'span'],
    allowedAttributes: {
      a: ['href'],
    },
    allowedIframeHostnames: ['www.youtube.com'],
  })
