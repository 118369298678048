import type { FC, HTMLAttributes, PropsWithChildren, Ref } from 'react'
import { forwardRef } from 'react'
import clsx from 'clsx'

import styles from './ProductGrid.module.scss'

export type ProductGridProps = HTMLAttributes<HTMLDivElement>

const ProductGrid: FC<PropsWithChildren<ProductGridProps>> = forwardRef((props, ref: Ref<HTMLDivElement>) => {
  const { className } = props

  return (
    <div className={clsx(styles.grid, className)} ref={ref}>
      {props.children}
    </div>
  )
})

ProductGrid.displayName = 'ProductGrid'

export default ProductGrid
