import { Dispatch } from 'react'

export type Breadcrumbs = { href: string; title: string }
export interface BreadCrumbsState {
  breadcrumbs: Breadcrumbs[]
}

export interface SetBreadcrumbs {
  type: 'setBreadcrumbs'
  payload: Breadcrumbs[]
}

export interface BreadCrumbsContextProps {
  state: BreadCrumbsState
  dispatch: Dispatch<Action>
}

export type Action = SetBreadcrumbs
export const breadCrumbsReducer = (state: BreadCrumbsState, action: Action): BreadCrumbsState => {
  switch (action.type) {
    case 'setBreadcrumbs': {
      return { ...state, breadcrumbs: action.payload }
    }
    default:
      return { ...state }
  }
}
