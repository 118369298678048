import { FC, HTMLAttributes, ReactNode } from 'react'
import { Spacing } from '@ui/system'
import clsx from 'clsx'

export interface SectionProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * @defaultValue Spacing.xl
   */
  space?: Spacing | string | number

  /**
   * @defaultValue true by default
   */
  horizontal?: boolean

  /**
   * Custom container's class names
   */
  children?: ReactNode
}

const Section: FC<SectionProps> = (props) => {
  const { horizontal = false, className, space = '4' } = props

  return (
    <div
      className={clsx(
        `flex border-group-grey`,
        horizontal ? `flex-row items-center border-l border-r px-${space}` : `flex-col border-b border-t py-${space}`,
        className
      )}
    >
      {props.children}
    </div>
  )
}

export default Section
