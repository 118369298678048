import type { FC, PropsWithChildren } from 'react'
import { useEffect, useState } from 'react'
import type { AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import axios from 'axios'
import { localStorageHelper } from '@utils/localStorageHelper'
import { GUEST_CUSTOMER_HEADER } from '@utils/axios-utils'

export const AxiosConfig: FC<PropsWithChildren> = ({ children }) => {
  const [guestCustomerId, setGuestCustomerId] = useState<string>(localStorageHelper.get(GUEST_CUSTOMER_HEADER))

  useEffect(() => {
    if (!guestCustomerId) {
      axios.get('/api/guest-customer').then((result: AxiosResponse<{ customerId: string }>) => {
        localStorageHelper.set(GUEST_CUSTOMER_HEADER, result.data.customerId)
        setGuestCustomerId(result.data.customerId)
      })
    }
  }, [setGuestCustomerId, guestCustomerId])

  useEffect(() => {
    const ejectId = axios.interceptors.request.use((request: InternalAxiosRequestConfig<any>) => {
      request.headers[GUEST_CUSTOMER_HEADER] = guestCustomerId
      return request
    })
    const ejectIdRes = axios.interceptors.response.use((response) => {
      return response
    })

    return () => {
      axios.interceptors.request.eject(ejectId)
      axios.interceptors.response.eject(ejectIdRes)
    }
  }, [guestCustomerId])

  return <>{children}</>
}

export default AxiosConfig
