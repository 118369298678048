import type { Dispatch } from 'react'
import { useEffect, useReducer, useRef } from 'react'
import { useRouter } from 'next/router'

export type ItemsQuantityState = Record<string, number>

const initState = {} as ItemsQuantityState

export type ItemsQuantityAction = {
  type: 'Inc' | 'Dec' | 'Rem' | 'Set' | 'ReSet'
  payload: { sku: string; value?: number }
}

const reducer: (s: ItemsQuantityState, a: ItemsQuantityAction) => ItemsQuantityState = (
  state: ItemsQuantityState = initState,
  action: ItemsQuantityAction
) => {
  switch (action.type) {
    case 'Inc': {
      return { ...state, [action.payload.sku]: (state[action.payload.sku] || 0) + 1 }
    }
    case 'Dec': {
      return { ...state, [action.payload.sku]: (state[action.payload.sku] || 1) - 1 }
    }
    case 'Rem': {
      return { ...state, [action.payload.sku]: 0 }
    }
    case 'Set': {
      return { ...state, [action.payload.sku]: action.payload.value || 0 }
    }
    case 'ReSet': {
      return { [action.payload.sku]: action.payload.value || 0 }
    }

    default:
      return { ...state }
  }
}

interface HookResult {
  itemsQuantityState: ItemsQuantityState
  dispatch: Dispatch<ItemsQuantityAction>
}

export const useMultipleItemsQuantity = (sku: string | undefined): HookResult => {
  const { query, asPath, ...router } = useRouter()
  const [state, dispatch] = useReducer(reducer, sku ? { [sku]: 1 } : {})
  const historyPath = useRef<string>('')
  useEffect(() => {
    if ((historyPath.current === asPath, typeof sku === 'string')) {
      dispatch({ type: 'ReSet', payload: { sku, value: 1 } })
    }
    historyPath.current = asPath
  }, [asPath])

  return {
    itemsQuantityState: state,
    dispatch,
  }
}

export const useMultipleSkuItemsQuantity = (skusWithQuantities: Record<string, number> | undefined): HookResult => {
  const [state, dispatch] = useReducer(reducer, skusWithQuantities || {})

  return {
    itemsQuantityState: state,
    dispatch,
  }
}
