import type { FC } from 'react'
import { useTranslate } from '@hooks/useTranslation'
import { CwsH4, CwsH2 } from '@components/cws'
export interface CategoryTitleProps {
  title: string
  itemsCount: number | null
}

export const CategoryTitle: FC<CategoryTitleProps> = (props) => {
  const { title, itemsCount } = props
  const { translate } = useTranslate()
  return (
    <div className="flex flex-row flex-wrap items-baseline gap-3">
      <CwsH2>{title}</CwsH2>
      {(itemsCount || itemsCount === 0) && (
        <>
          <CwsH4 weight="400" size="lead">{`${itemsCount} ${translate('wishlist.email.items')}`}</CwsH4>
        </>
      )}
    </div>
  )
}

export default CategoryTitle
