import { CwsA, CwsButton } from '@components/cws'
import moduleStyles from './FooterPanel.module.scss'
import type { FC } from 'react'

interface FooterPanelProps {
  previousStepTitle: string
  nextStepTitle: string
  previousStepHandler: () => void
  nextStepHandler: () => void
  nextStepBlocked?: boolean
}

export const FooterPanel: FC<FooterPanelProps> = ({
  previousStepTitle,
  nextStepTitle,
  previousStepHandler,
  nextStepHandler,
  nextStepBlocked = false,
}) => {
  return (
    <section className="bg-seafoam flex flex-row px-4 py-6 items-center justify-between">
      <CwsA className={moduleStyles.withBackArrow} hideArrow color="midnight" onClick={previousStepHandler}>
        {previousStepTitle}
      </CwsA>
      <CwsButton
        disabled={nextStepBlocked}
        onClick={nextStepHandler}
        type="button"
        variant="primary"
        width="auto"
        targetBlank
      >
        {nextStepTitle}
      </CwsButton>
    </section>
  )
}

export default FooterPanel
