import { FC } from 'react'
import RatingStars from './RatingStars'
import { CwsGrid, CwsGridRow } from '@components/cws'
export interface RatingProps {
  value: number
  maxValue?: number
  number?: number
  hideNumber?: boolean
}

const Rating: FC<RatingProps> = (props) => {
  const { value, maxValue = 5, hideNumber = false, number } = props
  return (
    // <CwsGrid className="cws-mb-s cws-mt-tiny">
    //   <CwsGridRow justifyContent="center" noGutter>
    <RatingStars filledCount={value} count={maxValue} />
    //   </CwsGridRow>
    // </CwsGrid>
  )
}

export default Rating
