import { FC, memo, PropsWithChildren, useMemo } from 'react'
import PageButton, { PageButtonProps } from './components/PageButton'
import s from './OldPagination.module.scss'

const PaginationButton = memo<PropsWithChildren<PageButtonProps>>((props) => {
  return (
    <div className={s['page-btn']}>
      <PageButton {...props} />
    </div>
  )
})

PaginationButton.displayName = 'PaginationButton'

export interface OldPaginationProps {
  current: number
  total: number
  showLimit?: number
  onPageClick: (page: number) => any
}

export const OldPagination: FC<OldPaginationProps> = (props) => {
  const { current, total, showLimit = 5, onPageClick } = props

  const previousPageButton = useMemo(() => <PaginationButton>{`<`}</PaginationButton>, [])
  const nextPageButton = useMemo(() => <PaginationButton>{`>`}</PaginationButton>, [])

  return (
    <div className={s.container}>
      {current > 1 && previousPageButton}

      {new Array(showLimit).fill(0).map((_, num) => (
        <PaginationButton key={num + 1} active={current === num + 1} onClick={onPageClick?.bind(this, num + 1)}>
          <p>{num + 1}</p>
        </PaginationButton>
      ))}
      <PaginationButton>...</PaginationButton>

      {current !== total - 1 && nextPageButton}
    </div>
  )
}

export default OldPagination
