import { useMemo } from 'react'

export function usePaginationLogic(pages: number, currentPage: number, viewLimit = 5) {
  const page = currentPage
  const totalPages = pages

  return useMemo(() => {
    const limit = viewLimit

    const leftOffset = page - 1
    const rightOffset = totalPages - page

    const leftCap = leftOffset > 2 ? 2 : leftOffset
    const rightCap = rightOffset > 2 ? 2 : rightOffset

    const startPage = page - leftCap - (2 - rightCap)
    const endPage = page + rightCap + 1 + (2 - leftCap)

    const leftOverflow = startPage > 1
    const rightOverflow = endPage <= totalPages

    if (leftOffset + rightOffset <= limit) {
      return {
        leftOffset,
        rightOffset,

        leftCap,
        rightCap,

        startPage,
        endPage,

        leftOverflow,
        rightOverflow,

        pagesToRender: new Array(totalPages).fill(null).map((item, index) => index + 1),
      }
    }

    return {
      leftOffset,
      rightOffset,

      leftCap,
      rightCap,

      startPage,
      endPage,

      leftOverflow,
      rightOverflow,

      pagesToRender: new Array(endPage - startPage).fill(null).map((item, index) => startPage + index),
    }
  }, [totalPages, page, viewLimit])
}
