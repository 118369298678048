import type { FC, HTMLAttributes, PropsWithChildren } from 'react'
import type { Color } from '@ui/system'
import clsx from 'clsx'
import { useMediaLess } from '@ui/hooks/useMediaLess'
import { MOBILE_BP } from '@app/constants'

export interface SideLineWrapProps extends HTMLAttributes<HTMLDivElement>, PropsWithChildren {
  color?: Color
  screen?: Screen
}

const SideLineWrap: FC<SideLineWrapProps> = (props) => {
  const { children, color = 'buoy', className } = props

  const isLaptop = !useMediaLess(MOBILE_BP)

  return (
    <div className="flex flex-row">
      {isLaptop && <div className={clsx(`flex-grow-0 flex-shrink-0 basis h-auto w-xs bg-${color}`, className)} />}
      <div className="flex-1-auto">{children}</div>
    </div>
  )
}

export default SideLineWrap
