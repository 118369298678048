import type { AxiosPromise } from 'axios'
import axios from 'axios'
import { compose, filter, pathOr, pick, prop, uniqBy } from 'ramda'
import type { FooterMap } from '@model/content/content'
import { parseJsonOrEmptyObject, parseJsonOrEmptyString } from '@utils/json'
import type { KKError } from '@model/konakart/KKError'
import { getData, setData } from '@api/redis'
import type { LanguageId } from '@model/locales'
import { isNowBetweenTwoDates } from '@utils/simple-helper'
import { makeAxiosConfig } from '@utils/axios-utils'
import { KK_STORE } from '@api/apiConfig'
import type { Catalog } from '@model/pricelist/Catalog'

export const STATIC_CONTENT_TYPES = {
  TOP_BANNER: 1,
  SUB_BANNER: 2,
  WELCOME_BANNER: 47,
  CATEGORY_BANNER: 3,
  CATEGORY_SUB_BANNER: 4,
  WISHLIST_FB_SHARE: 31,
  CONTACT_US: 32,
  REVIEW_DATA: 34,
  NEWSLETTER: 50,
  DELIVERY_INFO: 59,
  FAQ: 35,
  TERMS_AND_CONDITIONS: 37,
  PRODUCT_RETURNS: 39,
  SIZE_CHARTS: 60,
} as const

export type StaticContentType = keyof typeof STATIC_CONTENT_TYPES

export type KKContentItem = {
  id: number
  description: {
    content: string
    name1: string
    title: string
  }
  contentId: number
  dateFrom: number
  dateTo: number
  languageId: LanguageId
  name1?: string
  title?: string
  clickUrl?: string
  custom1?: string
  custom2?: string
  custom3?: string
  custom4?: string
}

export type ImagePageBanner = {
  title: string
  image: string
  clickUrl?: string
}

type ContentResult = {
  r: {
    contents: KKContentItem[]
  }
}

type ContentTypesResult = {
  r: ContentType[]
}

interface ContentType {
  contentTypeId: number
  languageId: LanguageId
  custom1: string
  custom2: string
}

export interface WelcomeNote {
  title: string
  content: string
}

export interface CatalogFromContent {
  title: string
  info: string
  sortOrder: number
  id: Catalog
}

const getContentStringFromContents = pathOr('', [0, 'description', 'content'])
const getContentDescriptionFromContents = pathOr({}, [0, 'description'])

const getFooterObject = compose(parseJsonOrEmptyString, getContentStringFromContents)
const getTopNavObject = compose(parseJsonOrEmptyObject, getContentStringFromContents)
const getDeliveryInfo = getContentStringFromContents

// @ts-ignore
const getTitleAndContent: (content: KKContentItem[]) => WelcomeNote = compose(
  pick(['title', 'content']),
  getContentDescriptionFromContents
)

const getCatalogsContentTransformer: (contentItems: KKContentItem[]) => CatalogFromContent[] = (contentItems) =>
  contentItems
    .map((item) => ({
      title: item.description.title,
      info: item.description.content,
      sortOrder: parseFloat(item.custom3 || '0'),
      id: item.clickUrl as Catalog,
    }))
    .sort((a, b) => a.sortOrder - b.sortOrder)

const filterTypes: (types: ContentType[]) => ContentType[] = compose(
  uniqBy(prop('contentTypeId')),
  filter((ct: ContentType) => typeof ct.custom1 !== 'undefined')
)

export const getContentTypes = async (languageId: LanguageId): Promise<ContentType[]> => {
  const cKey = `getContentTypes-${KK_STORE}-${languageId}`
  const cached = await getData<ContentType[]>(cKey)

  if (cached) {
    // return cached
  }

  const config = makeAxiosConfig({ f: 'getContentTypes', s: KK_STORE, languageId, sessionId: null })

  try {
    const { data } = await (axios(config) as AxiosPromise<ContentTypesResult | KKError>)

    if ('r' in data) {
      const { r } = data

      const filteredResult = filterTypes(r)

      await setData(cKey, filteredResult)
      return filteredResult
    } else {
      throw new Error(data.m)
    }
  } catch (e) {
    console.error('[content] Error loading content types ', e)
    return []
  }
}

export const getCategoryBanners = async (
  languageId: LanguageId,
  categoryId: number,
  catalogId?: string
): Promise<ImagePageBanner[]> => {
  const TYPE: StaticContentType = 'CATEGORY_BANNER'
  const cKey = `getCategoryBanners-${languageId}-${KK_STORE}-${categoryId}-${catalogId ?? ''}`
  const cached = await getData<ImagePageBanner[]>(cKey)

  const bannersCategoryType = STATIC_CONTENT_TYPES[TYPE]
  if (!bannersCategoryType) return []

  if (cached) {
    return cached
  }

  const isPriceList = KK_STORE === 'store1'
  const body = {
    f: 'getContents',
    s: KK_STORE,
    languageId,
    sessionId: null,
    search: {
      contentTypeId: bannersCategoryType,
      retrieveDescriptions: true,
      languageId,
      ...(catalogId && isPriceList ? { searchKey: catalogId, searchKeyRule: 3 } : {}),
    },
    dd: {},
  }

  const config = makeAxiosConfig(body)

  try {
    const { data } = await (axios(config) as AxiosPromise<ContentResult | KKError>)

    if ('r' in data) {
      const {
        r: { contents = [] },
      } = data

      const result: ImagePageBanner[] = contents
        .filter((content) => isNowBetweenTwoDates(content.dateFrom, content.dateTo))
        .filter((content) => content.custom2 && content.custom2 === `${categoryId}`)
        .sort((c1, c2) => (c1.custom3 || '').localeCompare(c2.custom3 || ''))
        .map((content) => ({
          title: content.description.title,
          image: content.description.name1,
          clickUrl: content.clickUrl,
        }))
      await setData(cKey, result)
      return result
    } else {
      throw new Error(data.m)
    }
  } catch (e) {
    console.error('[content] Error loading category banners ', e)
    return []
  }
}

const getContentFetcherBySearchKey =
  <T>(key: string, searchKey: string, transformer: (c: KKContentItem[]) => T) =>
  async (languageId = 5): Promise<T | ''> => {
    const cKey = `${key}-${KK_STORE}-${languageId}`
    const cached = await getData<T>(cKey)

    if (cached) {
      return cached
    }

    const body = {
      f: 'getContents',
      s: KK_STORE,
      languageId,
      search: {
        searchKey,
        retrieveDescriptions: true,
        languageId,
      },
      dd: {},
    }

    // console.log('---------')
    // console.log(JSON.stringify(body, null, 2))

    const config = makeAxiosConfig(body)

    try {
      const { data } = await (axios(config) as AxiosPromise<ContentResult | KKError>)

      if ('r' in data) {
        const {
          r: { contents = [] },
        } = data

        const result = transformer(contents)
        if (result) await setData(cKey, result)
        return result
      } else {
        throw new Error(data.m)
      }
    } catch (e) {
      console.error(`[content] Error while executing ${key}`, e)
      return ''
    }
  }

export const getFooterContents = getContentFetcherBySearchKey<FooterMap>(
  'getFooterContents',
  'footer-content',
  getFooterObject
)

export const getTopNavigationContents = getContentFetcherBySearchKey<Record<string, string>>(
  'getTopNavigationContents',
  'top-nav',
  getTopNavObject
)

export const getDeliveryInfoContents = getContentFetcherBySearchKey<string>(
  'getDeliveryInfoContents',
  'Delivery info',
  getDeliveryInfo
)

export const getCampaignPeriodContents = getContentFetcherBySearchKey<string>(
  'getCampaignPeriodContents',
  'campaign period',
  getDeliveryInfo
)

export const getWelcomeNoteContent = getContentFetcherBySearchKey<WelcomeNote>(
  'getWelcomeNoteContent',
  'welcome-note',
  getTitleAndContent
)

export const getCatalogs = getContentFetcherBySearchKey<CatalogFromContent[]>(
  'getCatalogs',
  'Welcome countries',
  getCatalogsContentTransformer
)

export const getSupportContents = async (languageId: LanguageId, type: StaticContentType): Promise<KKContentItem[]> => {
  const cKey = `getSupportContents-${languageId}-${STATIC_CONTENT_TYPES[type]}`
  const cached = await getData<KKContentItem[]>(cKey)

  const supportCategoryType = STATIC_CONTENT_TYPES[type]

  if (cached) {
    return cached
  }

  const body = {
    f: 'getContents',
    s: 'store2',
    languageId,
    sessionId: null,
    search: {
      contentTypeId: supportCategoryType,
      retrieveDescriptions: true,
      languageId,
      enabled: true,
    },
    dd: {},
    market: 'et',
  }

  const config = makeAxiosConfig(body)

  try {
    const { data } = await (axios(config) as AxiosPromise<ContentResult | KKError>)

    if ('r' in data) {
      await setData(cKey, data.r.contents)
      return data.r.contents
    } else {
      throw new Error(data.m)
    }
  } catch (e) {
    console.error('[content] Error loading support content', e)
    return []
  }
}
