import { VFC } from 'react'

const EmailIllustration2: VFC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="231" height="83" viewBox="0 0 231 83" fill="none">
      <path
        d="M110.042 34.09a2.047 2.047 0 0 1-2.085 0L81.876 18.686a.58.58 0 0 0-.876.5V42.58a4.403 4.403 0 0 0 4.403 4.403h47.194A4.403 4.403 0 0 0 137 42.58V19.187a.58.58 0 0 0-.876-.5l-26.082 15.402z"
        fill="#004152"
      />
      <path
        d="m110.158 29.262 26.54-15.674a.573.573 0 0 0 .284-.54 4.406 4.406 0 0 0-4.386-4.03H85.403a4.406 4.406 0 0 0-4.387 4.03.58.58 0 0 0 .284.54l26.542 15.674a2.276 2.276 0 0 0 2.316 0z"
        fill="#004152"
      />
      <rect x="120" y="30" width="34" height="34" rx="17" fill="#F5F8F7" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M137 62c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15zm-2.652-8.567c.244.244.64.244.884 0l1.326-1.326.002-.002 8.395-8.395a.625.625 0 0 0 0-.884l-1.326-1.325a.625.625 0 0 0-.884 0l-7.955 7.955-3.535-3.536a.625.625 0 0 0-.884 0l-1.326 1.326a.625.625 0 0 0 0 .884l5.303 5.303z"
        fill="#00BEA2"
      />
      <rect x="120" y="30" width="34" height="34" rx="17" stroke="#F5F8F7" strokeWidth="4" />
      <path
        d="m229.642 12.443-56.851 15.852 5.348 3.872m51.503-19.724-18.605 43.329-17.765-12.959m36.37-30.37-43.347 25.659m43.347-25.659-36.37 30.37m-11.053-7.716 4.076 3.005m0 0 2.878 17.667m4.099-12.956-4.099 12.956m0 0 6.733-7.005"
        stroke="#004152"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M178.402 59.351s-22.34 31.87-61.623 16.665C77.495 60.81 29.159 46.986 15 67"
        stroke="#004152"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="8.39 5.6"
      />
    </svg>
  )
}

export default EmailIllustration2
