import { useEffect, useState } from 'react'

export function useDebouncedValue<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])
  return debouncedValue
}

export function useDebounce<T>(value: T, callback: (value: T) => any, delay: number) {
  useEffect(() => {
    const handler = setTimeout(() => {
      callback?.(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, callback, delay])
}
