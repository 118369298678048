import * as yup from 'yup'
import { validations } from '@utils/order'

export const getTelephoneSchema = (pattern: RegExp = validations.telephone) =>
  yup.string().defined().matches(pattern, { message: 'Telephone is invalid' })

export const personalContactSchema = yup.object({
  firstName: yup.string().defined(),
  lastName: yup.string().defined(),
  email: yup.string().defined().email(),
  telephone: getTelephoneSchema(),
})

export interface PersonalContact extends yup.InferType<typeof personalContactSchema> {
  allFilled?: boolean
}
