import * as R from 'ramda'
import { isInDateRange } from '@utils/birth-data'

interface CampaignValidity {
  campaign_from: string
  campaign_to: string
}

export const isCampaignValid = (validityDataJson: string | null | undefined): boolean => {
  if (!validityDataJson) return false

  try {
    const validityData = JSON.parse(validityDataJson) as CampaignValidity | null
    if (!validityData || R.isEmpty(validityData)) return false

    const { campaign_from, campaign_to } = validityData
    return isInDateRange(campaign_from, campaign_to)
  } catch (e) {
    return false
  }
}
