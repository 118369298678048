import type { FC } from 'react'
import moduleStyles from './Separator.module.scss'
import clsx from 'clsx'

export const Separator: FC<{ isMobile: boolean | null }> = ({ isMobile }) => {
  return (
    <div className={isMobile ? moduleStyles.wrapperMobile : moduleStyles.wrapper}>
      <div className={clsx(moduleStyles.separator, moduleStyles.upper)}></div>
      <div className={clsx(moduleStyles.separator, moduleStyles.lower)}></div>
    </div>
  )
}

export default Separator
