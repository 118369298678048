import { useEffect, useState } from 'react'
export type UseMediaCAllBack = (matches: boolean) => void

export function useMedia(query: string, callback?: UseMediaCAllBack): boolean | null {
  const [matches, setMatches] = useState<boolean | null>(null)

  useEffect(() => {
    const mq = window.matchMedia(query)

    setMatches(mq.matches)

    const listener = (ev: MediaQueryListEvent) => {
      setMatches(ev.matches)
      callback?.(ev.matches)
    }

    mq.addEventListener('change', listener)

    return () => {
      mq.removeEventListener('change', listener)
    }
  }, [query, callback])

  return matches
}
