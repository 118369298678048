import type { FC, ReactNode } from 'react'
import Link from 'next/link'
import type { Link as LinkItemType } from '@model/content/content'
import { ButtonArrow } from '@ui/icons/button'

import { useInitialData } from '@hooks/useInitialData'
import {
  CwsA,
  CwsDivider,
  CwsGrid,
  CwsGridCol,
  CwsGridRow,
  CwsH3,
  CwsIcon,
  CwsP,
  CwsSection,
  CwsSpan,
  CwsAccordion,
  CwsAccordionItem,
  CwsAccordionItemHeader,
  CwsAccordionItemBody,
} from '@components/cws'
import { useMediaLess } from '@ui/hooks/useMediaLess'
import { MOBILE_BP } from '@app/constants'
import type { CCLocale, Locale } from '@model/locales'
import { CC_LOCALE } from '@model/locales'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import EStoreIcon from '@components/icons/EStore'
import { isGloballyPricelist } from '@utils/pricelist-utils/app-properties'

const Footer: FC = () => {
  const { footer } = useInitialData()
  const isMobile = useMediaLess(MOBILE_BP)
  const router = useRouter()

  const openCookiePolicy = useCallback(
    (e: MouseEvent) => {
      const locale: CCLocale = CC_LOCALE[router.locale as Locale]
      e.preventDefault()
      e.stopPropagation()
      window?.CWC?.render({
        env: 'e-store',
        link: true,
        locale,
      })
    },
    [router.locale]
  )

  if (!footer) return null
  const itemRender =
    (mobile = false) =>
    (item: LinkItemType) => {
      const theme = mobile ? '' : 'midnight'
      const align = mobile ? 'center' : 'left'

      if (item.label) {
        return <ValueWithLabel key={item.label} label={item.label} value={item.title} />
      }
      if (item.separator) {
        return mobile ? null : <CwsDivider color="twilight" spacing="xs" key="separator" variant="solid" />
      }
      if (item.external) {
        if (item.component) {
          return (
            <CwsA
              key={item.title + item.url}
              className="cws-mb-xs"
              theme={theme}
              withIcon
              href={item.url}
              textAlign={align}
              hideArrow
            >
              <CwsIcon className="cws-mr-tiny" icon={item.component.toLowerCase()} size="24px" />
              <CwsSpan weight="500">{item.title}</CwsSpan>
            </CwsA>
          )
        }

        return (
          <CwsA
            hideArrow
            key={item.title + item.url}
            href={item.url}
            className="cws-mb-xs"
            textAlign={align}
            theme={theme}
          >
            {item.title}
          </CwsA>
        )
      }

      if (item.component) {
        return (
          <CwsA
            key={item.title + item.url}
            textAlign={align}
            className="cws-mb-xs"
            theme={theme}
            withIcon
            href={item.url}
            hideArrow
          >
            <CwsIcon className="cws-mr-tiny" icon={item.component.toLowerCase()} size="24px" />
            <CwsSpan weight="500">{item.title}</CwsSpan>
          </CwsA>
        )
      }

      if (item.url === ':cookie-policy') {
        return (
          <span key={item.url + item.title} className="font-normal leading-5">
            <CwsA hideArrow className="cws-mb-xs" textAlign={align} theme={theme} onClick={openCookiePolicy}>
              {item.title}
            </CwsA>
          </span>
        )
      }

      return (
        <Link key={item.title + item.url} href={item.url || ''} passHref legacyBehavior>
          <CwsA hideArrow className="cws-mb-xs" textAlign={align} theme={theme}>
            {item.title}
          </CwsA>
        </Link>
      )
    }

  const desktopNode = (
    <CwsSection backgroundColor="midnight" className="cws-py-60">
      <CwsGrid>
        <CwsGridRow noGutterVertical>
          {footer.columns.map((column) => {
            return (
              <CwsGridCol col="3" key={column.header}>
                <CwsH3 className="cws-mb-s" color="white">
                  {column.header}
                </CwsH3>
                {column.links.map(itemRender(false))}
              </CwsGridCol>
            )
          })}
          {!isGloballyPricelist() && <EStoreIcon locale={router.locale} />}
        </CwsGridRow>
      </CwsGrid>
    </CwsSection>
  )

  const mobileNode = (
    <CwsSection backgroundColor="midnight">
      <CwsAccordion mode="footer">
        {footer.columns.map((column) => {
          return (
            <CwsAccordionItem id={column.header} key={column.header}>
              <CwsAccordionItemHeader label={column.header} />
              <CwsAccordionItemBody>{column.links.map(itemRender(true))}</CwsAccordionItemBody>
            </CwsAccordionItem>
          )
        })}
      </CwsAccordion>
      <CwsDivider color="twilight" />
      {!isGloballyPricelist() && (
        <CwsSection backgroundColor="midnight" className=" flex justify-center py-4">
          <EStoreIcon locale={router.locale} />
        </CwsSection>
      )}
    </CwsSection>
  )

  return (
    <CwsSection variant="footer">
      {isMobile ? mobileNode : desktopNode}
      <CwsSection backgroundColor="twilight" className="cws-py-xs">
        <CwsGrid>
          <CwsGridRow noGutterVertical>
            <CwsGridCol>
              <CwsP color="white" hideSpacing size="small" className="flex flex-col tablet:flex-row justify-between">
                <span className="font-normal leading-5 tablet:flex-footer-company">{`© AS Tallink Grupp ${new Date().getFullYear()}`}</span>
                {footer.bottomLinks.map((link) => {
                  if (link.url === ':cookie-policy') {
                    return (
                      <span key={link.url + link.title} className="font-normal leading-5">
                        <CwsA color="white" onClick={openCookiePolicy}>
                          {link.title}
                        </CwsA>
                      </span>
                    )
                  }

                  return (
                    <span key={link.url + link.title} className="font-normal leading-5">
                      <CwsA href={link.url} color="white">
                        {link.title}
                      </CwsA>
                    </span>
                  )
                })}
              </CwsP>
            </CwsGridCol>
          </CwsGridRow>
        </CwsGrid>
      </CwsSection>
    </CwsSection>
  )
}

export default Footer

interface SectionProps {
  title: string
  open?: boolean
  onClick?: () => void
}

interface LinkItemProps {
  href: string
  text: string
  startAdornment?: ReactNode
}

const LinkItem: FC<LinkItemProps> = (props) => {
  const { text, href, startAdornment } = props
  return (
    <Link passHref href={href} legacyBehavior>
      <>
        {startAdornment}
        <CwsA color="white">{text}</CwsA>
      </>
    </Link>
  )
}

interface ValueWithLabelProps {
  label: string
  value: string
  additionalLabel?: string
}

const ValueWithLabel: FC<ValueWithLabelProps> = (props) => {
  const { label, value, additionalLabel } = props
  return (
    <div className="laptop:text-white cws-mb-xs text-center laptop:text-left">
      <p className="text-sm leading-5 font-normals">{label}</p>
      <p className="text-base leading-5.5 font-medium mt-1">{value}</p>
      {additionalLabel && <p className="text-sm leading-5 text-dark-grey mt-1">{additionalLabel}</p>}
    </div>
  )
}
