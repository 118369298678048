import type { FC, Dispatch, SetStateAction } from 'react'
import s from '@components/product/ProductViewParameters/ProductViewParameters.module.scss'
import { PriceLine } from '@components/product'
import { CwsCounter, CwsGrid, CwsIcon, CwsP } from '@components/cws'
import { getFormattedPrice, getMaxQuantity } from '@utils/price'
import { Typography } from '@ui/components'
import { oncePerBasket } from '@utils/product-utils'
import { useTranslate } from '@hooks/useTranslation'
import { useCurrency } from '@hooks/useCurrency'
import clsx from 'clsx'
import { useEffect } from 'react'
import type { PriceInfo } from '@model/product/PriceInfo'
import type { BundleTypeTab } from '@components/product/ProductViewParameters/ProductViewParameters'
import { SelectedProductValue } from '@components/product/BundleOptions/BundleOptions'
import { range } from 'ramda'

interface SingleTabContentProps {
  priceInfo: PriceInfo
  productQuantity: number
  sku: string
  maxQuantity: number
  handleQuantityUpdate: (value: number) => void
  setTab: Dispatch<SetStateAction<BundleTypeTab>>
  setSelectedMap: Dispatch<SetStateAction<Record<number, SelectedProductValue>>>
  reset: () => void
}

export const SingleTabContent: FC<SingleTabContentProps> = ({
  priceInfo,
  handleQuantityUpdate,
  productQuantity,
  sku,
  maxQuantity,
  setTab,
  setSelectedMap,
  reset,
}) => {
  const { translate } = useTranslate()
  const { currency } = useCurrency()

  const { bundleQuantity, bundleSavePercent, bundlePricePerItem, bundlePrice, bundleLabel } = priceInfo

  const infoNode = bundleLabel ? (
    <div className={clsx(s.readLeft, 'gap-4 py-[16px] px-6 flex flex-row items-center bg-seafoam')}>
      <CwsIcon icon="price-tag" size="24px" />
      <CwsP size="small" hideSpacing>
        {translate(bundleLabel)}
      </CwsP>
    </div>
  ) : null
  useEffect(() => {
    setTab('single')
    reset()
    setSelectedMap((prevState) => {
      const copyState = { ...prevState }
      return Object.keys(copyState).reduce((acc, val) => {
        return {
          ...acc,
          [val]: +val === 0 ? { product: prevState[+val]?.product, quantity: prevState[+val]?.quantity } : {},
        }
      }, {})
    })
  }, [])

  return (
    <>
      <div className={s.section}>
        <div>
          <CwsGrid className="cws-py-xs" width="fluid">
            <PriceLine
              valueColor="notice"
              label={translate('w2.bundle.price.for', { items: bundleQuantity })}
              value={bundlePrice}
            />
          </CwsGrid>
          <div className="flex justify-end">
            <CwsP color="notice" size="small" hideSpacing>
              {translate('w2.bundle.notice.info', {
                price: getFormattedPrice(bundlePricePerItem, currency),
                percent: bundleSavePercent,
              })}
            </CwsP>
          </div>
        </div>
      </div>
      <hr />
      <div className={s.section}>
        {infoNode}
        {/*<div className={clsx(s.readLeft, 'gap-4 py-[16px] px-6 flex flex-row items-center bg-seafoam')}>*/}
        {/*  <CwsIcon icon="price-tag" size="24px" />*/}
        {/*  <CwsP size="small" hideSpacing>*/}
        {/*    {translate('product.tile.bundle.products', { bundle: bundleQuantity })}*/}
        {/*  </CwsP>*/}
        {/*</div>*/}
        <div className={s.parameter}>
          <Typography>{translate('product.detail.quantity.title')}:</Typography>
          <CwsCounter
            insert
            onChange={(value: number) => {
              return handleQuantityUpdate(value)
            }}
            value={productQuantity}
            max={oncePerBasket(sku) ? 1 : getMaxQuantity(maxQuantity)}
            min={1}
          />
        </div>
      </div>
    </>
  )
}

export default SingleTabContent
